import { useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useOasisBackend } from "../../hooks/useOasisBackend";
import DataSpace from "../pages/DataSpace";
import useChat, { FunctionDefinition } from "../../hooks/useChat";
import TypewriterStreaming from "../../components/ui/typewriter-streaming";
import styled from "@emotion/styled";
import { IconBubble, IconCards, IconSend } from "@tabler/icons-react";
import ApplicationNavbar from "../../components/ApplicationNavbar";
import { css, keyframes } from '@emotion/react';
import DatasetModal from "../../components/DatasetModal";

// const functions = {
//     filter_person: {
//         url: "https://api.oasis.com/filter_person",
//     } 
// }

const ChatFontContainer = styled.div`
    font-family: "Quicksand", sans-serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
    color: #fff;
`

// Define the keyframes for shrinking and expanding
const shrinkToCorner = keyframes`
  0% {
    width: 100vw;
    height: 100vh;
    transform: translate(0, 0);
    opacity: 1;
  }
  75% {
    width: 0vw;
    height: 0vh;
    transform: translate(95vw, 95vh);
    padding: 0px;
    display: none;
  }
  100% {
    width: 0;
    height: 0;
    transform: translate(100vw, 100vh);
    opacity: 0;
    display: none;
    padding: 0px;
  }
`;

const expandFromCorner = keyframes`
  0% {
    width: 0;
    height: 0;
    transform: translate(100vw, 100vh);
    opacity: 0;
    display: none;
    padding: 0px;
  }
  25% {
    width: 10vw;
    height: 10vh;
    transform: translate(calc(100vw - 10vw), calc(100vh - 10vh));
    opacity: 1;
    display: block;
    padding: 0px;
  }
  100% {
    width: 100vw;
    height: 100vh;
    transform: translate(0, 0);
    opacity: 1;
  }
`;

// Define the interface for props to type the component
interface AnimatedDivProps {
  isShrinking: boolean;
  hasAnimation: boolean;
}

// Create the styled div with conditional animations based on props
const AnimatedDiv = styled.div<AnimatedDivProps>`
  background-color: lightblue;
  z-index: 1;
  position: absolute;
  ${({ isShrinking, hasAnimation }) =>
    hasAnimation
      ? isShrinking
        ? css`animation: ${shrinkToCorner} 2s forwards;`
        : css`animation: ${expandFromCorner} 2s forwards;`
      : css`
          width: 100vw;
          height: 100vh;
          transform: translate(0, 0);
          opacity: 1;
        `}
`;

const Chat = () => {
    const db = useOasisBackend()
    const [searchParams,] = useSearchParams();
    const chatId = searchParams.get("chatId");
    const [content, setContent] = useState<string>("");
    const [calledFunctions, setCalledFunctions] = useState<number[]>([]);
    const [dataSpaceArguments, setDataSpaceArguments] = useState<any>({});
    const bottomOfChatContainer = useRef<HTMLDivElement | null>(null);
    const [isShrunk, setIsShrunk] = useState(false);
    const [hasAnimation, setHasAnimation] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const functionCaller = (functionDefinition: {name: string, arguments: string}) => {
        // console.log("")
        if(functionDefinition.name.includes("filter_")) {
            // Parse the arguments key
            const args = JSON.parse(functionDefinition.arguments)
            console.log("Function call in function caller", functionDefinition.name, args)

            
            const newArgs = Object.keys(args).reduce((acc, key) => {
                if(!["xAxis", "computedAxis", "yAxis", "idealXAxisCenter", "idealYAxisCenter"].includes(key)) {
                    (acc as any)[key] = args[key]
                }
                return acc
            }, {}) as any

            setDataSpaceArguments((dataSpaceArguments: any) => ({
                ...dataSpaceArguments,
                xAxis: args.xAxis,
                computedAxis: args.computedAxis,
                yAxis: args.yAxis,
                idealXAxisCenter: args.idealXAxisCenter,
                idealYAxisCenter: args.idealYAxisCenter,
                dimensions: Object.keys(newArgs.dimensions),
                database: functionDefinition.name.replace("filter_", "")
            }))

            console.log("Function Calling fromd db, ", functionDefinition)
            console.log(newArgs)

            db.endpoints.llmFunctionCaller.llmFunctionCallerCreate({
                function_name: functionDefinition.name,
                arguments: newArgs
            }).then((response) => {
                console.log("Function call response", response)
                setDataSpaceArguments((dataSpaceArguments: any) => ({
                    ...dataSpaceArguments,
                    dataPoints: response.data
                }))
                if((response.data as any).length > 0){
                    setIsShrunk(true)
                    setHasAnimation(true)
                }
            }
            )
        }
    }

    const { messages, animatedText, sendMessage, currentMessageKey } = useChat(chatId, functionCaller);

    useEffect(() => {
        if(messages.length > 0) {
            const lastMessage = messages[messages.length - 1]
            if(lastMessage.role === "assistant") {
                const functionCall:FunctionDefinition|undefined = lastMessage.function_call
                if(functionCall?.name?.includes("filter_")) {
                    const functionName = functionCall.name
                    if(!calledFunctions.includes(parseInt(functionName.replace("filter_", "")))) {
                        setCalledFunctions(prevCalledFunctions => [...prevCalledFunctions, parseInt(functionName.replace("filter_", ""))])
                        functionCaller({name: functionName, arguments: functionCall?.arguments})
                    }
                }
            }
        }
    }, [messages])

    const submitContent = () => {
        sendMessage(content)
        setContent("")
    }


    // Useeffect to scroll down as animatedText grows using proper react
    const scrollDown = () => {
        if(bottomOfChatContainer.current) {
            bottomOfChatContainer.current.scrollIntoView({behavior: "smooth"})
        }
    }


    return(
        <div className="overflow-hidden w-[100vw] h-[100vh]">
            <ApplicationNavbar className="text-white" openMenuClassName="bg-[#000b1e]" />
            <AnimatedDiv hasAnimation ={hasAnimation} isShrinking={isShrunk} className="flex flex-col  justify-between relative h-[100vh] w-[100vw] pt-[5vh] px-16 overflow-hidden"
                    style={
                        {
                            background: "linear-gradient(to right, #000b1e, #0e1c3f)"
                        }
                    }>
                    
                    <div className="h-[5vh] z-10 top-0 w-[100vh] bg-transparent shadow-2xl shadow-[#0e1c3f]"></div>
                    <ChatFontContainer className="h-[80vh] w-full p-4 md:p-16 overflow-y-scroll">
                        {
                            messages.map((message, index) => {
                                return <div key={index} className={`flex flex-row ${message.role === "assistant" ? "justify-start" : "justify-end"} items-center w-full`}>
                                    <div className={`flex flex-col py-4 rounded-md text-white`}>
                                        {message.content === animatedText ? <></> : message.content}
                                    </div>
                                </div>
                            })
                        }
                        {animatedText != "" && <TypewriterStreaming currentMessageKey = {currentMessageKey} animatedText={animatedText} animationCallback={scrollDown} />}
                        <div className="mb-[-20px]" ref={bottomOfChatContainer}></div>
                    </ChatFontContainer>

                    <div className="flex flex-row h-[15vh] w-full h-fit md:pb-4 justify-center items-center self-end">
                        <input 
                            className="w-11/12 h-[48p] bg-transparent text-white text-lg outline-none" 
                            type="text" 
                            value={content} 
                            onChange={(e) => setContent(e.target.value)} placeholder="Type a message" 
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  submitContent();
                                }
                            }}
                        />
                        <button className="text-white inline" onClick={submitContent}><IconSend /></button>
                    </div>
            </AnimatedDiv>
            
    {dataSpaceArguments.dataPoints?.length && <div className="absolute z-0 h-[100vh] w-[100vw]">
        <DataSpace {...dataSpaceArguments} />
    </div>}
    {
        dataSpaceArguments.dataPoints?.length && 
        <div className="absolute bottom-12 right-8 z-[100]">
            <button className="bg-white rounded-full p-4" onClick={() => {
                setIsShrunk(!isShrunk)
                setHasAnimation(true)
            }}><IconBubble className="h-8 w-8"/></button>
        </div>
    }

    {
        <div className="absolute bottom-40 right-8 z-[100]">
            <button className="bg-white rounded-full p-4" onClick={() => {
                setIsOpen(true)
            }}><IconCards className="h-8 w-8"/></button>
        </div>
    }
    <DatasetModal isOpen={isOpen} setIsOpen={setIsOpen} dataSpaceArguments={dataSpaceArguments} setDataSpaceArguments = {setDataSpaceArguments}/>


    </div>
    
    )
}


export default Chat;