// show the content of the datum in a card. the content is a json object, which whould be nicely formatted and handle nested objects well. The card should only show a preview and on click expand.

import React, { useState } from 'react';
import { Datum } from '../../../api/OasisBackendApi';

interface DatumCardProps {
  datum: Datum;
  properties: string[];
  rank: number;
  isCorrect?: boolean;
}

export const DatumCard: React.FC<DatumCardProps> = ({ datum, properties, rank, isCorrect }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const renderValue = (value: any): string => {
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value, null, 2);
    }
    return String(value);
  };

  const getNestedValue = (obj: any, path: string) => {
    const keys = path.split('.');
    let current = obj;
    
    for (const key of keys) {
      if (current === undefined || current === null) return undefined;
      current = current[key];
    }
    
    return current;
  };

  const renderContent = () => {
    const keysToRender = properties?.length ? properties : Object.keys(datum.content);
    
    return (
      <>
        <span className="text-gray-500">#{rank + 1}</span><br></br>
        {keysToRender.map((key) => (
          <div key={key} className="mb-2">
            <span className="font-semibold">{key}: </span>
            <span className="font-mono">{renderValue(getNestedValue(datum.content, key))}</span>
          </div>
        ))}
      </>
    );
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div 
      className={`${isCorrect ? 'bg-green-200' : 'bg-white'} shadow-md rounded-lg p-4 cursor-pointer hover:shadow-lg transition-shadow`}
      onClick={toggleExpand}
    >
      <div className={`${isExpanded ? '' : 'max-h-32 overflow-hidden'}`}>
        <div className="whitespace-pre-wrap break-words">
          {renderContent()}
        </div>
      </div>
      {!isExpanded && (
        <div className="text-center text-gray-500 mt-2">
          Click to expand
        </div>
      )}
    </div>
  );
};