import React from 'react';
import Navbar from './Navbar';  // Import the Navbar
import { useLocation, matchPath } from 'react-router-dom';


const Layout: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const location = useLocation();
  
  // Define routes where the Navbar should be hidden
  const noNavbarRoutes = ['/fill-form/:formId'];

  // Function to check if current path matches any no-navbar routes
  const hideNavbar = noNavbarRoutes.some((route) => matchPath(route, location.pathname));


  return (
    <>
      {/* Conditionally render the Navbar */}
      {!hideNavbar && <Navbar />}
      <main className="bg-white p-4 text-gray-600 h-screen w-screen overflow-auto pt-16 lg:pt-20">
        {children}  {/* Render the page's content */}
      </main>
    </>
  );
};

export default Layout;
