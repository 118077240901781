import { Label } from "./ui/label";
import { Input } from "./ui/input";
import { TextArea } from "./ui/text-area";
import Modal from 'react-modal';
import { useState } from "react";
import { useOasisBackend } from "../hooks/useOasisBackend";
import toast from 'react-hot-toast';
import { IconX } from "@tabler/icons-react";

interface ContactUsModalProps {
    modalIsOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

const ContactUsModal = ({ modalIsOpen, setIsOpen }: ContactUsModalProps) => {
    const [email, setEmail] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [company, setCompany] = useState<string>('');
    const [role, setRole] = useState<string>('');
    const db = useOasisBackend();

    const submitContactUs = async () => {
        if(!email){
            toast.error('Please enter your email address');
            return;
        }
        if(!message){
            toast.error('Please enter a message');
            return;
        }
        // Send the email and message to the backend
        const response = await db.endpoints.api.apiContactUsCreate({ email, message, name, company, role });
        if (response.status !== 201) {
            toast.error('Error Submitting Contact Us Form');
            return;
        }
        setIsOpen(false);
        toast('Your message has been submitted!');
    };

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setIsOpen(false)}
            style={{
                overlay: {
                    background: 'transparent',
                    zIndex: 100,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
                content: {
                    border: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 'fit-content',
                    height: 'auto',
                    inset: 'auto',
                    background: 'transparent',
                    zIndex: 1000,
                },
            }}
        >
            <div className="bg-white p-16 rounded-xl">
                <div>
                    <button onClick={() => setIsOpen(false)} className="absolute top-10 right-10">
                        <IconX />
                    </button>
                </div>
                <div className="text-center text-2xl font-bold">Contact Us</div>
                <div className="text-center text-sm">We would love to hear from you! Please leave us a message.</div>
                <div className="flex flex-col gap-4 p-6">
                    <Label>Email</Label>
                    <Input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Your email address"
                    />
                    <Label>Name</Label>
                    <Input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Your name"
                    />
                    <Label>Company</Label>
                    <Input
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        placeholder="Your company"
                    />
                    <Label>Role</Label>
                    <Input
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        placeholder="Your role"
                    />
                    <Label>Message</Label>
                    <TextArea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Your message"
                        rows={4}
                    />
                    <button
                        onClick={submitContactUs}
                        className="bg-pitchDeckTeal text-white font-bold px-6 py-2"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default ContactUsModal;
