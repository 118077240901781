import React from 'react';
import Navbar from './Navbar';  // Import the Navbar
import { useLocation, matchPath } from 'react-router-dom';

const Layout: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const location = useLocation();
  
  // Define routes where the Navbar should be hidden
  const noNavbarRoutes = ['/fill-form/:formId'];

  // Function to check if current path matches any no-navbar routes
  const hideNavbar = noNavbarRoutes.some((route) => matchPath(route, location.pathname));


  return (
    <>
      {/* Conditionally render the Navbar */}
      {!hideNavbar && <Navbar />}
      <main className="bg-gray-800 px-2 pt-16 text-gray-200 h-screen w-screen overflow-hidden absolute top-0 lg:pt-20 lg:px-4">
        {children}  {/* Render the page's content */}
      </main>
    </>
  );
};

export default Layout;
