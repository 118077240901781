import React, { useState } from 'react';
import { FullExperiment as FullExperimentType, Dataset as DatasetType, PossibleProcessingStatus, LLMJob } from '../../../api/OasisBackendApi';
import { IconDownload } from '@tabler/icons-react';
import { useOasisBackend } from '../../../hooks/useOasisBackend';

interface ExperimentCardProps {
  experiment: FullExperimentType;
  onCardClick: (experimentId: number) => void;
  className?: string;
}

const ExperimentCard: React.FC<ExperimentCardProps> = ({ experiment, onCardClick, className }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const db = useOasisBackend();

  const truncateDescription = (description: string, maxLength: number) => {
    if (description.length <= maxLength) return description;
    return description.slice(0, maxLength) + '...';
  };

  const exportExperiment = async (e:any) => {
    e.stopPropagation();
    const response = await db.endpoints.testsuite.testsuiteApiExportExperimentRetrieve(`${experiment.id}`);
  
    if (response.data) {
      // Convert the JSON object to a string for Blob compatibility
      const blob = new Blob([JSON.stringify(response.data)], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = url;
      link.download = `${experiment?.name || 'dataset'}.json`;
      link.click();
  
      // Clean up the URL object
      window.URL.revokeObjectURL(url);
    } else {
      console.error("No data returned from API");
    }
  };

  return (
    <div 
      key={experiment.id} 
      className={`bg-white shadow-md rounded-lg p-6 cursor-pointer hover:shadow-xl transition-shadow duration-200 ${className}`} 
      onClick={() => onCardClick(experiment.id)}
    >
        <h2 className="text-xl font-semibold mb-2">{experiment.name}</h2>
        <p className="text-gray-600 mb-2">
          {showFullDescription
            ? experiment.description.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))
            : truncateDescription(experiment.description, 200)}
          {experiment.description.length > 200 && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setShowFullDescription(!showFullDescription);
              }}
              className="ml-2 text-gray-400 hover:text-gray-800 hover:underline hover:cursor-pointer italic"
            >
              {showFullDescription ? 'show less' : 'show more'}
            </button>
          )}
        </p>
        <p className="text-sm text-gray-500 mb-2">Date: {experiment.created_at}</p>
        <p className="text-sm text-gray-500 mb-2">Class: {experiment.experiment_class}</p>
        <p className="text-sm text-gray-500 mb-2">
        Input: {experiment.input_datasets.map((dataset, index) => (
            <span 
            key={index} 
            className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 cursor-pointer"
            >
            {dataset.name}
            </span>
        ))}
        </p>
        <p className="text-sm text-gray-500 mb-2">
        Output: {experiment.operations[experiment.operations.length - 1]?.output_dataset && (
            <span 
            className={`inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 cursor-pointer`}
    
            >
            {experiment.operations[experiment.operations.length - 1]?.output_dataset?.name ?? 'No Output Dataset'}
            </span>
        )}
        </p>
        <p className="text-sm text-gray-500 mb-2"><strong>Operations</strong></p>
        <p className="text-sm mb-2">
        <span className={`px-2 py-1 rounded-full text-xs font-semibold
            ${experiment.status === 'COMPLETED' ? 'bg-green-200 text-green-800' :
            experiment.status === 'RUNNING' ? 'bg-orange-200 text-orange-800' :
            experiment.status === 'PENDING' ? 'bg-yellow-200 text-yellow-800' :
            experiment.status === 'FAILED' ? 'bg-red-200 text-red-800' :
            'bg-gray-200 text-gray-800'}`}>
            {experiment.status} ({experiment.operations.filter(op => op.status && op.status === 'COMPLETED').length}/{experiment.operations.length})
        </span>
        </p>
        {experiment.experiment_class === "LLMExperiment" && (
        <>
            <p className="text-sm text-gray-500 mb-2"><strong>LLM Jobs</strong></p>
            <p className="text-sm mb-2">
            <span className={`px-2 py-1 rounded-full text-xs font-semibold
            ${experiment.status === 'COMPLETED' ? 'bg-green-200 text-green-800' :
            experiment.status === 'RUNNING' ? 'bg-orange-200 text-orange-800' :
            experiment.status === 'PENDING' ? 'bg-yellow-200 text-yellow-800' :
            experiment.status === 'FAILED' ? 'bg-red-200 text-red-800' :
            'bg-gray-200 text-gray-800'}`}>
            {experiment.status} <>({experiment.operations.reduce((acc, op) => acc + ((op.llm_jobs as any)?.filter((job: LLMJob) => job.status === PossibleProcessingStatus.COMPLETED).length || 0), 0)}/
            {experiment.operations.reduce((acc, op) => acc + (op.llm_jobs?.length || 0), 0)})</>
        </span>
            </p>
        </>
        )}
        <button onClick={exportExperiment} className="flex items-center m-8 hover:bg-gray-200 rounded-md p-2">
            <IconDownload className="mr-1" /> Export Data
        </button>
    </div>
  );
};

export default ExperimentCard;
