import React, { useState, useEffect } from 'react';
import { IconX } from "@tabler/icons-react";
import Fuse, { FuseResult } from 'fuse.js'; // Import Fuse and FuseResult

// Add new interfaces for sorting
interface SortConfig {
  property: string;
  direction: 'asc' | 'desc';
}

interface SearchbarProps<T> {
  items: T[];
  searchableProperties: string[]; // Allow string paths for nested properties
  onFilterChange: (filteredItems: T[]) => void;
}

function getNestedValue(obj: any, path: string): any {
  return path.split('.').reduce((acc, part) => {
    if (Array.isArray(acc)) {
      // If the current part is an array, map over it and return the array of values
      return acc.map(item => item[part]);
    }
    return acc && acc[part];
  }, obj);
}

function Searchbar<T>({ items, searchableProperties, onFilterChange }: SearchbarProps<T>) {
  const [searchTerm, setSearchTerm] = useState('');
  const [generalSearch, setGeneralSearch] = useState('');
  const [selectedProperty, setSelectedProperty] = useState<keyof T | ''>('');
  const [filters, setFilters] = useState<
    Array<{ property: keyof T; value?: string; min?: number; max?: number }>
    >([]);

  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [rangeFilter, setRangeFilter] = useState<{ min: number; max: number }>({ min: 0, max: Infinity });

  const [noResults, setNoResults] = useState(false);

  const [sortConfig, setSortConfig] = useState<SortConfig | null>(null);

  useEffect(() => {
    let filteredItems = items;

    if (generalSearch) {
      const fuse = new Fuse(items, {
        keys: searchableProperties,
        threshold: 0.3, 
        ignoreLocation: true,
      });
      filteredItems = fuse.search(generalSearch).map((result: FuseResult<T>) => result.item);
    }

    filteredItems = filteredItems.filter((item) =>
      filters.every((filter) => {
        const value = getNestedValue(item, filter.property as string);

        if (filter.min !== undefined && filter.max !== undefined) {
          // Numerical range filter
          return (
            typeof value === 'number' &&
            value >= filter.min! &&
            value <= filter.max!
          );
        } else if (filter.value !== undefined) {
          if (typeof value === 'boolean') {
            // Boolean filter logic
            return value.toString() === filter.value;
          }
          // String or other type filter
          return String(value)
            .toLowerCase()
            .includes(filter.value.toLowerCase());
        }
        return true; // No filter applied
      })
    );

    // Apply sorting
    if (sortConfig) {
      filteredItems.sort((a, b) => {
        const aValue = getNestedValue(a, sortConfig.property);
        const bValue = getNestedValue(b, sortConfig.property);

        if (aValue === bValue) return 0;
        
        const comparison = aValue < bValue ? -1 : 1;
        return sortConfig.direction === 'asc' ? comparison : -comparison;
      });
    }

    setNoResults(filteredItems.length === 0);
    onFilterChange(filteredItems);
  }, [filters, items, onFilterChange, generalSearch, searchableProperties, rangeFilter, sortConfig]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
        e.preventDefault(); // Prevent default behavior
        const searchInput = document.getElementById('general-search-input') as HTMLInputElement;
        if (searchInput) {
          searchInput.focus(); // Focus the search input
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (selectedProperty) {
      const newSuggestions = items
        .flatMap(item => {
          const nestedValue = getNestedValue(item, selectedProperty as string);
          // If nestedValue is an array, return it directly; otherwise, wrap it in an array
          return Array.isArray(nestedValue) ? nestedValue : [nestedValue];
        })
        .filter((val, index, self) => self.indexOf(val) === index) // Remove duplicates
        .filter(val => val.toLowerCase().includes(value.toLowerCase()));
      setSuggestions(newSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleGeneralSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setGeneralSearch(value);
  };

  const handlePropertyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedProperty(e.target.value as keyof T);
  };

  const handleRangeChange = (e: React.ChangeEvent<HTMLInputElement>, type: 'min' | 'max') => {
    const value = parseFloat(e.target.value);
    setRangeFilter((prev) => ({
      ...prev,
      [type]: isNaN(value) ? (type === 'min' ? 0 : Infinity) : value,
    }));
  };

  const addFilter = () => {
    if (selectedProperty) {
      const value = getNestedValue(items[0], selectedProperty as string);
      if (typeof value === 'number' && rangeFilter) {
        setFilters([
          ...filters,
          {
            property: selectedProperty,
            min: rangeFilter.min,
            max: rangeFilter.max,
          },
        ]);
        setRangeFilter({ min: 0, max: Infinity });
      } else if (typeof value === 'boolean') {
        // Add filter for boolean properties
        setFilters([
          ...filters,
          { property: selectedProperty, value: searchTerm || 'false' }, // Default to 'false'
        ]);
      } else if (searchTerm) {
        setFilters([
          ...filters,
          { property: selectedProperty, value: searchTerm },
        ]);
      }
      setSearchTerm('');
      setSelectedProperty('');
      setSuggestions([]); // Clear suggestions when a filter is added
    }
  };
  
  const removeFilter = (index: number) => {
    const newFilters = [...filters];
    newFilters.splice(index, 1);
    setFilters(newFilters);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      addFilter();
    }
  };

  const handleDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSearchTerm(e.target.value);
  };

  const renderInputField = () => {
    const value = getNestedValue(items[0], selectedProperty as string);

    if (Array.isArray(value)) {
      // Render dropdown for array properties
      return (
        <select
          value={searchTerm}
          onChange={handleDropdownChange}
          onKeyDown={handleKeyPress} // Use onKeyDown instead of onKeyPress
          className="w-full p-2 border rounded"
        >
          <option value="">Select value</option>
          {value.map((val, index) => (
            <option key={index} value={val}>
              {val}
            </option>
          ))}
        </select>
      );
    } else if (typeof value === 'number') {
      // Render range input for number properties
      return (
        <div className="flex mb-2">
          <input
            type="number"
            placeholder="Min"
            onChange={(e) => handleRangeChange(e, 'min')}
            onKeyDown={handleKeyPress} // Use onKeyDown instead of onKeyPress
            className="mr-2 p-2 border rounded"
          />
          <input
            type="number"
            placeholder="Max"
            onChange={(e) => handleRangeChange(e, 'max')}
            onKeyDown={handleKeyPress} // Use onKeyDown instead of onKeyPress
            className="p-2 border rounded"
          />
        </div>
      );
    } else if (typeof value === 'boolean') {
      // Render checkbox for boolean properties
      return (
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={searchTerm === 'true'}
            onChange={(e) => setSearchTerm(e.target.checked ? 'true' : 'false')}
            onKeyDown={handleKeyPress} // Use onKeyDown instead of onKeyPress
            className="mr-2"
          />
          <label>{String(selectedProperty)}</label>
        </div>
      );
    } else {
      // Render text input for other types
      return (
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          onKeyDown={handleKeyPress} // Use onKeyDown instead of onKeyPress
          placeholder="Add filter..."
          className="w-full p-2 border rounded"
        />
      );
    }
  };

  const handleSort = (property: string) => {
    setSortConfig((prevSort) => {
      if (!prevSort || prevSort.property !== property) {
        return { property, direction: 'asc' };
      }
      return { property, direction: prevSort.direction === 'asc' ? 'desc' : 'asc' };
    });
  };

  const toggleSortDirection = () => {
    setSortConfig((prevSort) => 
      prevSort ? { ...prevSort, direction: prevSort.direction === 'asc' ? 'desc' : 'asc' } : null
    );
  };

  return (
    <div className="mb-4">
      <div className="flex mb-2">
        <input
          id="general-search-input" // Add id for the input
          type="text"
          value={generalSearch}
          onChange={handleGeneralSearchChange}
          placeholder="Search all properties..."
          className="mr-2 p-2 border rounded flex-grow"
        />
      </div>
      <div className="flex mb-2">
        <select
          value={String(selectedProperty)} // Convert to string
          onChange={handlePropertyChange}
          className="mr-2 p-2 border rounded"
        >
          <option value="">Select property</option>
          {searchableProperties.map((prop) => (
            <option key={String(prop)} value={String(prop)}>
              {String(prop)}
            </option>
          ))}
        </select>
        <div className="relative flex-grow">
          {renderInputField()}
          {suggestions.length > 0 && (
            <ul className="absolute bg-white border rounded mt-1 max-h-40 overflow-y-auto w-full">
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setSearchTerm(suggestion);
                    setSuggestions([]);
                  }}
                  className="p-2 hover:bg-gray-200 cursor-pointer"
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
        </div>
        <button onClick={addFilter} className="ml-2 p-2 bg-slate-500 text-white rounded">
          Add Filter
        </button>
      </div>
      <div className="flex flex-wrap">
        {filters.map((filter, index) => (
          <div key={index} className="bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
            {String(filter.property)}:
            {filter.value ? filter.value : ` ${filter.min} - ${filter.max}`}
            <button onClick={() => removeFilter(index)} className="ml-2 text-gray-500 hover:text-gray-700">
              <IconX size={16} />
            </button>
          </div>
        ))}
      </div>
      {noResults && (
        <div className="text-slate-500 mt-2">
          No search results found.
        </div>
      )}
      <div className="flex items-center mt-2">
        <span className="mr-2">Sort by:</span>
        <select
          value={sortConfig?.property || ''}
          onChange={(e) => handleSort(e.target.value)}
          className="mr-2 p-2 border rounded"
        >
          <option value="">None</option>
          {searchableProperties.map((prop) => (
            <option key={String(prop)} value={String(prop)}>
              {String(prop)}
            </option>
          ))}
        </select>
        
        {sortConfig && (
          <>
            <button
              onClick={toggleSortDirection}
              className="px-3 py-1 bg-slate-500 text-white rounded mr-2"
            >
              {sortConfig.direction === 'asc' ? '↑ Ascending' : '↓ Descending'}
            </button>
            <button
              onClick={() => setSortConfig(null)}
              className="text-gray-500 hover:text-gray-700"
            >
              <IconX size={16} />
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default Searchbar;
