import { useState } from "react";
import { Dataset } from "../../api/OasisBackendApi";
import { useOasisBackend } from "../../hooks/useOasisBackend";

interface PythonSandboxProps {
    datasets: Dataset[];
}

const PythonSandbox = ({datasets}: PythonSandboxProps) => {
    const [pythonCode, setPythonCode] = useState<string>("")
    const [output, setOutput] = useState<string>("")
    const db = useOasisBackend()
    const run = async () => {
        const response = await db.endpoints.testsuite.testsuiteApiRunPythonSandboxForDatasetCreate({
            code: pythonCode,
            datasets: datasets.map((dataset) => dataset.id)
        })
        console.log(response)
        // setOutput(response['results'])
    }

    return(
        <div>
            Datasets Variables Available: 
            <ul>
                {datasets.map((dataset) => (
                    <li key={dataset.id}>
                        Variable Name: dataset_{dataset.id}, Dataset Name: {dataset.name}
                    </li>
                ))}
            </ul>
            <textarea className="w-full h-full" value={pythonCode} onChange={(e: any) => setPythonCode(e.target.value)}/>
            <button onClick={run} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Run
            </button>
        </div>
    )
}

export default PythonSandbox;