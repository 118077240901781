import { useEffect, useRef } from 'react';
import { AnimationControls } from 'framer-motion';

interface DragPosition {
  x: number;
  y: number;
}

const useArrowKeyMovement = (
  setCurrentDragPosition: React.Dispatch<React.SetStateAction<DragPosition>>,
  animationControls: AnimationControls,
  cellWidth: number,
  cellHeight: number,
  marginX: number,
  marginY: number
) => {
  const pressedKeysRef = useRef<Set<string>>(new Set());

  useEffect(() => {
    const step = 25; // Smaller step for smoother movement
    const constraints = {
      left: -marginX,
      top: -marginY,
      right: window.innerWidth / 2,
      bottom: window.innerHeight / 2,
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      const { key } = event;
      if (
        key === 'ArrowLeft' ||
        key === 'ArrowRight' ||
        key === 'ArrowUp' ||
        key === 'ArrowDown'
      ) {
        pressedKeysRef.current.add(key);
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      const { key } = event;
      if (
        key === 'ArrowLeft' ||
        key === 'ArrowRight' ||
        key === 'ArrowUp' ||
        key === 'ArrowDown'
      ) {
        pressedKeysRef.current.delete(key);
      }
    };

    let animationFrameId: number;

    const moveDataSpace = () => {
      const pressedKeys = pressedKeysRef.current;
      let dx = 0;
      let dy = 0;

      if (pressedKeys.has('ArrowLeft')) {
        dx += step;
      }
      if (pressedKeys.has('ArrowRight')) {
        dx -= step;
      }
      if (pressedKeys.has('ArrowUp')) {
        dy += step;
      }
      if (pressedKeys.has('ArrowDown')) {
        dy -= step;
      }

      if (dx !== 0 || dy !== 0) {
        setCurrentDragPosition((currentDragPosition) => {
          const rect = document.getElementById('data-space')?.getBoundingClientRect();
          if (!rect) return currentDragPosition;

          let newX = rect.left + dx;
          let newY = rect.top + dy;

          // Apply constraints
          newX = Math.max(constraints.left, Math.min(newX, constraints.right));
          newY = Math.max(constraints.top, Math.min(newY, constraints.bottom));

          const centerX = window.innerWidth / 2 - (newX + cellWidth / 2.0);
          const centerY = window.innerHeight / 2 - (newY + cellHeight / 2.0);
          const newDragPosition = { x: centerX, y: centerY };

          animationControls.set({ x: newX, y: newY });
          return newDragPosition;
        });
      }

      animationFrameId = requestAnimationFrame(moveDataSpace);
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    animationFrameId = requestAnimationFrame(moveDataSpace);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      cancelAnimationFrame(animationFrameId);
    };
  }, [
    setCurrentDragPosition,
    animationControls,
    cellWidth,
    cellHeight,
    marginX,
    marginY,
  ]);
};

export default useArrowKeyMovement;
