import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Form, useNavigate } from 'react-router-dom';
import { IconPlus } from "@tabler/icons-react";
import Modal from '../components/modal';
import Searchbar from '../components/Searchbar';
import { FullExperiment as ExperimentType, Dataset as DatasetType } from '../../api/OasisBackendApi';
import { useOasisBackend } from '../../hooks/useOasisBackend';
import CreateExperiment from '../components/forms/CreateExperiment';
import { FullPageLoader } from '../components/loader';
import ExperimentCard from '../components/Cards/ExperimentCard';

const Experiments: React.FC = () => {
  const navigate = useNavigate();
  const [experiments, setExperiments] = useState<ExperimentType[] | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDatasetId, setSelectedDatasetId] = useState<number | null>(null);
  const [filteredExperiments, setFilteredExperiments] = useState<ExperimentType[]>([]);
  const db = useOasisBackend();
  const [isCreateExperimentModalOpen, setIsCreateExperimentModalOpen] = useState(false);

  useEffect(() => {
    const fetchExperiments = async () => {
      try {
        const response = await db.endpoints.testsuite.testsuiteApiGetUserFullExperimentsRetrieve();
        setExperiments(response.data);
        setFilteredExperiments(response.data);
      } catch (error) {
        console.error('Error fetching experiments:', error);
      }
    };

    fetchExperiments();
  }, [db.endpoints.testsuite]);

  const handleExperimentCardClick = (experimentId: number) => {
    navigate(`/manage-experiment/${experimentId}`);
  };

  const handleDatasetClick = (datasetId: number) => {
    setSelectedDatasetId(datasetId);
    setIsModalOpen(true);
  };

  // Memoize the handleFilterChange function
  const handleFilterChange = useCallback((filteredItems: ExperimentType[]) => {
    setFilteredExperiments(filteredItems);
  }, []);

  const searchableProps = useMemo(
    () => ['name', 'description', 'created_at', 'experiment_class', 'status', 'input_datasets.name', 'output_dataset.name'],
    []
  );

  if (!experiments) {
    return <FullPageLoader />;  
  }

  return (
    <div className="h-full rounded bg-gray-100 p-8">
      <h1 className="text-4xl font-bold text-center mb-8">Experiments</h1>
      
      <Searchbar<ExperimentType>
        items={experiments}
        searchableProperties={searchableProps}
        onFilterChange={handleFilterChange}
      />

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div 
          className="bg-white shadow-md rounded-lg p-6 flex items-center justify-center cursor-pointer hover:shadow-xl transition-shadow duration-300"
          onClick={() => setIsCreateExperimentModalOpen(true)}
        >
          <IconPlus className="w-12 h-12 text-gray-400" />
        </div>

        {filteredExperiments.map((experiment) => (

          <ExperimentCard key={experiment.id} experiment={experiment} onCardClick={handleExperimentCardClick}/>
          
        ))}
        

      </div>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} size="large">
        {selectedDatasetId && (
          <iframe
            src={`/dataset/${selectedDatasetId}`}
            className="w-full h-full"
            title="Dataset Information"
          />
        )}
      </Modal>

      <Modal isOpen={isCreateExperimentModalOpen} onClose={() => setIsCreateExperimentModalOpen(false)} size="large">
        <CreateExperiment />
      </Modal>
    </div>
  );
};

export default Experiments;
