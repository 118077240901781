import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import FloatingTooltip from '../components/FloatingTooltip';
import OptionButton from '../components/OptionsButton';

interface Form {
  id: number;
  title: string;
  purpose: string;
  submissionCount: number;
}

const UserHomepage: React.FC = () => {
  const [forms, setForms] = useState<Form[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [hoveredButtonIndex, setHoveredButtonIndex] = useState<number | null>(null); // Track which button is hovered
  const resultsButtonRefs = useRef<(HTMLButtonElement | null)[]>([]); // Array of refs
  const [loading, setLoading] = useState(true);
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [submissionsLeft, setSubmissionsLeft] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchForms = async () => {
      try {
        const response = await axios.get('/get-user-forms/');
        setForms(response.data);
        setLoading(false);
      } catch (error) {
        setErrorMessage('Error fetching forms.');
        console.log('Error fetching forms:', error);
      }
    };
    fetchForms();
  }, []);

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      const response = await axios.get('/get-subscription-status/');
      setSubscriptionStatus(response.data.subscription_status);
      setSubmissionsLeft(response.data.submissions_left);
    };
    fetchSubscriptionStatus();
  }, []);

  const handleCreateNewForm = async () => {
    try { 
      const response = await axios.post('/create-form/');
      const { form_id } = response.data;
      navigate(`/edit-form/${form_id}`);  // Redirect to edit the newly created form
    } catch (error) {
      setErrorMessage('Error creating new form.');
      console.log('Error creating form:', error);
    }
  };

  const handleEditForm = (formId: number) => {
    navigate(`/edit-form/${formId}`);
  };

  const handleViewResults = (formId: number) => {
    navigate(`/view-results/${formId}`);
  };

  const handleShareForm = (formId: number) => {
    // Copy the form URL to the clipboard
    const formUrl = `${window.location.origin}/fill-form/${formId}`;
    navigator.clipboard.writeText(formUrl);
    alert('Form URL copied to clipboard!');
  };





  return (
    <div className="h-full bg-gray-700 p-8 rounded overflow-auto">
      {subscriptionStatus && <p className="text-white text-center mb-6">You are on a {subscriptionStatus.length > 0 ? subscriptionStatus : 'free'} plan. You have {submissionsLeft} submissions left this Month.</p>}
      <h1 className="text-3xl font-bold mb-6 text-center">Your Forms</h1>
      {errorMessage && <p className="text-red-500 text-center">{errorMessage}</p>}
      {loading ? (<div className='flex justify-center items-center h-full w-full'>
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                      </div>) : (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Render form cards */}
        {forms.map((form, index) => {

            // Define buttons dynamically for each form
            const buttons = [
              {
                label: 'Edit',
                onClick: (e: React.MouseEvent) => {
                  e.stopPropagation(); // Prevent propagation to the form card
                  handleEditForm(form.id);
                },
              },
              {
                label: 'Results',
                onClick: (e: React.MouseEvent) => {
                  e.stopPropagation(); // Prevent propagation to the form card
                  handleViewResults(form.id);
                },
              },
              {
                label: 'Share',
                onClick: (e: React.MouseEvent) => {
                  e.stopPropagation(); // Prevent propagation to the form card
                  handleShareForm(form.id);
                },
              },
            ];
          return (
          <div
            key={form.id}
            onClick={() => handleEditForm(form.id)}
            className="bg-gradient-to-tr from-slate-900 cursor-pointer to-slate-800 opacity-80 hover:opacity-100 shadow-md rounded-lg p-5 flex flex-col justify-between"
          >
            <div>
              <h2 className="text-xl font-semibold m-5">{form.title}</h2>
              <p className="text-gray-400 text-sm m-5">
                {form.purpose.length > 100 ? `${form.purpose.slice(0, 100)}...` : form.purpose}
              </p>
              <div className="flex justify-between items-center">
                <button
                  className="text-gray-500 text-sm m-5 hover:text-white hover:cursor-pointer hover:underline"
                  ref={(el) => (resultsButtonRefs.current[index] = el)} // Assign individual ref to each button
                  onMouseEnter={() => setHoveredButtonIndex(index)} // Track hover index
                  onMouseLeave={() => setHoveredButtonIndex(null)}  // Reset hover index
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent propagation to the form card
                    handleViewResults(form.id);
                  }}
                >
                  
                  {form.submissionCount} Submissions
                  </button>
                  <OptionButton buttons={buttons} className="text-gray-700" />
              </div>
            </div>
            {/* Render FloatingTooltip conditionally for the hovered button */}
            {hoveredButtonIndex === index && (
              <FloatingTooltip
                message="view results"
                targetRef={{ current: resultsButtonRefs.current[index] }}
              />
            )}
          </div>
          );

        })} 

        {/* Add new form card */}
        <div onClick={handleCreateNewForm} className="bg-gradient-to-tr from-slate-500 to-transparent opacity-60 hover:opacity-100 hover:cursor-pointer shadow-md rounded-lg p-6 flex items-center justify-center">
            + Create New Form
        </div>

      </div> )}
    </div>
  );
};

export default UserHomepage;
