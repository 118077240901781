import React, { useState } from 'react';
import { FullStudy as FullStudyType } from '../../../api/OasisBackendApi';

interface StudyCardProps {
  study: FullStudyType;
  onCardClick: (studyId: number) => void;
  className?: string;
}

const StudyCard: React.FC<StudyCardProps> = ({ study, onCardClick, className }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);

  const truncateDescription = (description: string, maxLength: number) => {
    if (description.length <= maxLength) return description;
    return description.slice(0, maxLength) + '...';
  };

  return (
    <div 
      key={study.id} 
      className={`bg-white shadow-md rounded-lg p-6 cursor-pointer hover:shadow-xl transition-shadow duration-200 ${className}`} 
      onClick={() => onCardClick(study.id)}
    >
      <h2 className="text-xl font-semibold mb-2">{study.name}</h2>
      <p className="text-gray-600 mb-2">
          {showFullDescription
            ? study.description.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))
            : truncateDescription(study.description, 200)}
          {study.description.length > 200 && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setShowFullDescription(!showFullDescription);
              }}
              className="ml-2 text-gray-400 hover:text-gray-800 hover:underline hover:cursor-pointer italic"
            >
              {showFullDescription ? 'show less' : 'show more'}
            </button>
          )}
        </p>
      <p className="text-sm text-gray-500 mb-2">Date: {study.created_at}</p>

    </div>
  );
};

export default StudyCard;

