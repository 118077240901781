import React from 'react';

const Eyes: React.FC = () => {
    const loaderStyle: React.CSSProperties = {
        display: 'inline-flex',
        gap: '20px',
    };

    const left_eye_style: React.CSSProperties = {
        content: '""',
        height: '40px',
        aspectRatio: '1',
        borderRadius: '50%',
        background: 'radial-gradient(farthest-side,#000 95%,#0000) 35% 35%/12px 12px no-repeat #fff',
        animation: 'l5 3s infinite',
    };

    const right_eye_style: React.CSSProperties = {
        content: '""',
        height: '40px',
        aspectRatio: '1',
        borderRadius: '50%',
        background: 'radial-gradient(farthest-side,#000 95%,#0000) 35% 35%/12px 12px no-repeat #fff',
        animation: 'l5 3s infinite',
        transform: 'translateY(-2px)',
    };

    const keyframes = `
      @keyframes l5 {
        0%,11% {background-position:35% 35%}
        14%,36% {background-position:65% 35%}
        38%,61% {background-position:65% 65%}
        64%,86% {background-position:35% 65%}
        88%,100% {background-position:35% 35%}
      }
    `;

    // Inject keyframes dynamically using a <style> tag
    return (
        <>
            <style>
                {keyframes}
            </style>
            <div className="loader" style={loaderStyle}>
                <div style={left_eye_style}></div>
                <div style={right_eye_style}></div>
            </div>
        </>
    );
};

export default Eyes;
