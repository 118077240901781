import React, { createContext, useState, useContext } from 'react';

interface SaveStatusContextType {
  isSaved: boolean;
  setIsSaved: (saved: boolean) => void;
}

const SaveStatusContext = createContext<SaveStatusContextType | undefined>(undefined);

export const useSaveStatus = () => {
  const context = useContext(SaveStatusContext);
  if (!context) {
    throw new Error('useSaveStatus must be used within a SaveStatusProvider');
  }
  return context;
};

export const SaveStatusProvider:  React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [isSaved, setIsSaved] = useState(true);
  return (
    <SaveStatusContext.Provider value={{ isSaved, setIsSaved }}>
      {children}
    </SaveStatusContext.Provider>
  );
};
