import { LoginButton } from '../../components/Login';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../hooks/useUser';

const LoginPage: React.FC = () => {
  const user = useUser();
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(15);
  const [deleting, setDeleting] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentFile, setCurrentFile] = useState('');

  const files = [
    'C:/Windows/System32/kernel32.dll',
    'C:/Windows/System32/ntdll.dll',
    'C:/Windows/System32/user32.dll',
    'C:/Windows/System32/gdi32.dll',
    'C:/Windows/System32/shell32.dll',
    'C:/Windows/System32/advapi32.dll',
    'C:/Windows/System32/ws2_32.dll',
    'C:/Windows/System32/msvcrt.dll',
    'C:/Windows/System32/wininet.dll',
    'C:/Windows/System32/ole32.dll'
  ];

  useEffect(() => {
    if (user.state === 'LoggedIn') {
      navigate('/');
    }
  }, [user, navigate]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(timer);
          setDeleting(true);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (deleting) {
      const progressTimer = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(progressTimer);
            return 100;
          }
          const nextFileIndex = Math.floor((prevProgress + 10) / 10);
          setCurrentFile(files[nextFileIndex] || '');
          return prevProgress + 10;
        });
      }, 1000);

      return () => clearInterval(progressTimer);
    }
  }, [deleting]);

  const handleCancel = () => {
    // Add logic to handle cancel action
    setCountdown(15); // Reset countdown or any other logic
    setDeleting(false);
    setProgress(0);
    setCurrentFile('');
  };

  return (
    <div className='flex flex-col justify-center items-center h-screen w-screen bg-black text-green-500 absolute top-0 left-0'>
      {!deleting ? (
        <>
          <h1 className='text-3xl font-mono'>Deleting all your system files in {countdown} seconds...</h1>
          <div
            onClick={handleCancel}
            className='mt-4 px-4 py-2 bg-red-600 text-white text-center rounded cursor-pointer relative'
          >
            Cancel
            <LoginButton className='opacity-0 absolute top-0 left-0 w-full h-full'/>
          </div>
          
        </>
      ) : (
        <>
          <h1 className='text-3xl font-mono'>Deleting files...</h1>
          <div className='w-1/2 bg-gray-700 rounded-full h-4 mt-4'>
            <div className='bg-red-600 h-4 rounded-full' style={{ width: `${progress}%` }}></div>
          </div>
          <p className='mt-4'>{currentFile}</p>
          <div
            onClick={handleCancel}
            className='mt-4 px-4 py-2 bg-red-600 text-white text-center rounded cursor-pointer relative'
          >
            Cancel
            <LoginButton className='opacity-0 absolute top-0 left-0 w-full h-full'/>
          </div>
        </>
      )}
    </div>
  );
};

export default LoginPage;