import { useSearchParams } from "react-router-dom";
import { SignupForm } from "../../components/SignupButton";
import { useEffect, useState } from "react";
import { LoginForm } from "../../components/Login";
import { useOasisBackend } from "../../hooks/useOasisBackend";
import { useUser } from "../../hooks/useUser";



const OAuthSignIn = () => {
    // Get the state and redirect url from the query parameters using react router
    const [searchParams, ] = useSearchParams();
    const state = searchParams.get('state');
    const redirect = searchParams.get('redirect_uri');
    const [loggingIn, setLoggingIn] = useState<boolean>(false);
    const userApi = useUser();
    const db = useOasisBackend()

    useEffect(() => {
        if (userApi.state === "LoggedIn") {
            redirectWithCode()
        }
    }, [userApi.state])

    const redirectWithCode = async () => {
        const authorizationCodeResponse = await db.endpoints.generateAuthCode.generateAuthCodeCreate()
        const code = authorizationCodeResponse.data.code
        //@ts-ignore
        window.location = `${redirect}?code=${code}&state=${state}`;
    }

    return(
        <div className="flex flex-col justify-center text-center w-full gap-4 pt-12">
            
            <h1 className="text-lg">Authenticate with Thoughtful Oasis For OpenAI Custom GPT</h1>
            {loggingIn ? <div>Don't have an account? <button onClick={() => setLoggingIn(false)}  className="shadow-[0_0_0_3px_#000000_inset] px-6 py-2 bg-transparent border border-black text-black rounded-lg font-bold transform hover:-translate-y-1 transition duration-400">Sign Up</button></div> :
            <div> Already have an account? <button onClick={() => setLoggingIn(true)}  className="shadow-[0_0_0_3px_#000000_inset] px-6 py-2 bg-transparent border border-black text-black rounded-lg font-bold transform hover:-translate-y-1 transition duration-400">Log In</button></div>}

            {loggingIn ? 
            <LoginForm successCallback = {redirectWithCode} /> :
            <SignupForm successCallback = {redirectWithCode} /> }
        </div>
    )

}

export default OAuthSignIn;