import { useState, useEffect, useMemo, useRef } from 'react';
import { DatumCard } from './Cards/DatumCard';
import { Dataset } from '../../api/OasisBackendApi';
import { useOasisBackend } from '../../hooks/useOasisBackend'
import AutocompleteDropdown from './AutocompleteDropdown';

interface SearchResultsProps {
  searchResults: {
    id: number;
    query: string;
    dataset: Dataset;
    created_at: string;
    results: {
      query: string;
      oasis?: {
        protocol: number[][];
        euclidean: number[][];
        manhattan: number[][];
      };
      keyword?: number[][];
      semantic?: number[][];
    };
    account?: number | null;
    params?: {
      correct_datum?: number;
    };
  };
}

export function SearchResults({ searchResults }: SearchResultsProps) {
  console.log('SearchResults received:', searchResults);
  const db = useOasisBackend();
  const [datumCache, setDatumCache] = useState<Record<string, any>>({});
  const [selectedProperties, setSelectedProperties] = useState<string[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [filterText, setFilterText] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  // Flatten nested results into searchType -> datumIds format
  const flattenedResults = useMemo(() => {
    console.log('Flattening results:', searchResults.results);
    const results: Record<string, number[][]> = {};
    
    if (searchResults.results.oasis) {
      Object.entries(searchResults.results.oasis).forEach(([type, ids]) => {
        results[`oasis_${type}`] = Array.isArray(ids[0]) && Array.isArray(ids[0][0]) 
          ? (ids as unknown as number[][][])[0] 
          : ids;
      });
    }
    if (searchResults.results.keyword) {
      results.keyword = Array.isArray(searchResults.results.keyword[0]) && Array.isArray(searchResults.results.keyword[0][0]) 
        ? (searchResults.results.keyword as unknown as number[][][])[0] 
        : searchResults.results.keyword;
    }
    if (searchResults.results.semantic) {
      results.semantic = Array.isArray(searchResults.results.semantic[0]) && Array.isArray(searchResults.results.semantic[0][0]) 
        ? (searchResults.results.semantic as unknown as number[][][])[0] 
        : searchResults.results.semantic;
    }
    
    console.log('Flattened results:', results);
    return results; 
  }, [searchResults]);

  // Track which search types are selected
  const [selectedTypes, setSelectedTypes] = useState<Set<string>>(
    new Set(Object.keys(flattenedResults))
  );

  // Fetch datum details
  useEffect(() => {
    const fetchDatums = async () => {
      for (const [_, datumGroups] of Object.entries(flattenedResults)) {
        for (const group of datumGroups) {
          for (const id of group) {
            if (!datumCache[id]) {
              try {
                const response = await db.endpoints.testsuite.testsuiteApiGetDatumRetrieve(id.toString());
                setDatumCache(prev => ({ ...prev, [id]: response.data }));
              } catch (error) {
                console.error(`Failed to fetch datum ${id}:`, error);
              }
            }
          }
        }
      }
    };

    fetchDatums();
  }, [searchResults.dataset.id, flattenedResults]);

  const toggleSearchType = (searchType: string) => {
    const newSelected = new Set(selectedTypes);
    if (newSelected.has(searchType)) {
      newSelected.delete(searchType);
    } else {
      newSelected.add(searchType);
    }
    setSelectedTypes(newSelected);
  };

  // Extract available properties from first datum
  const availableProperties = useMemo(() => {
    const properties = new Set<string>();
    const firstDatum = Object.values(datumCache)[0];
    
    console.log('Extracting properties from first datum:', firstDatum);
    
    if (firstDatum?.content) {
      const extractProperties = (obj: any, prefix = '') => {
        // Add the current prefix as a property if it exists
        if (prefix) {
          properties.add(prefix);
        }
        
        Object.entries(obj).forEach(([key, value]) => {
          const fullKey = prefix ? `${prefix}.${key}` : key;
          properties.add(fullKey);
          
          // Handle nested objects, but not arrays or null values
          if (value && typeof value === 'object' && !Array.isArray(value)) {
            extractProperties(value, fullKey);
          }
        });
      };
      
      try {
        extractProperties(firstDatum.content);
        console.log('Extracted properties:', Array.from(properties));
      } catch (error) {
        console.error('Error extracting properties:', error);
      }
    } else {
      console.log('No content found in first datum');
    }
    
    return Array.from(properties).sort();
  }, [datumCache]);

  // Add property selection handlers
  const handlePropertySelect = (property: string) => {
    setSelectedProperties(prev => {
      if (!prev.includes(property)) {
        return [...prev, property];
      }
      return prev;
    });
    setFilterText('');
  };

  const handleRemoveProperty = (property: string) => {
    setSelectedProperties(prev => prev.filter(p => p !== property));
  };

  return (
    <div className="space-y-4">

      {/* Query */}
      <div className="mb-4 bg-gray-100 p-4 rounded-lg">
        <p className="italic">{searchResults.query}</p>
      </div>
      {/* Property selection */}
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Display Properties
        </label>
        <div className="flex items-center w-full">
          <input
            ref={inputRef}
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={filterText}
            onChange={(e) => {
              setFilterText(e.target.value);
              setShowDropdown(true);
            }}
            onFocus={() => {
              setShowDropdown(true);
              if (inputRef.current) {
                const rect = inputRef.current.getBoundingClientRect();
                setDropdownPosition({ top: rect.bottom, left: rect.left });
              }
            }}
            onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
            placeholder="Search properties..."
          />
        </div>
        <div className="flex flex-wrap mt-2">
          {selectedProperties.map((property, index) => (
            <span key={index} className="bg-blue-100 text-blue-800 px-2 py-1 rounded mr-2 mb-2">
              <button
                className="mr-2 text-blue-600 hover:text-blue-800"
                onClick={() => handleRemoveProperty(property)}
              >
                ×
              </button>
              {property}
            </span>
          ))}
        </div>
      </div>

      {/* Search type toggles */}
      <div className="flex gap-4 flex-wrap">
        {Object.keys(flattenedResults).map((searchType) => (
          <label key={searchType} className="flex items-center gap-2">
            <input 
              type="checkbox"
              checked={selectedTypes.has(searchType)}
              onChange={() => toggleSearchType(searchType)}
            />
            <span>{searchType}</span>
          </label>
        ))}
      </div>

      {/* Results containers */}
      <div className="flex gap-4 overflow-x-auto">
        {Object.entries(flattenedResults).map(([searchType, datumGroups]) => {
          if (!selectedTypes.has(searchType)) return null;

          return (
            <div 
              key={searchType}
              className="flex-1 min-w-[400px] m-2 bg-gray-100 rounded-lg p-4"
            >
              <h3 className="font-bold mb-2">{searchType}</h3>
              <div className="space-y-2 max-h-[80vh] overflow-y-auto">
                {datumGroups.map((group, groupIndex) => (
                  <div key={groupIndex} className="space-y-2">
                    {group.map((id) => (
                      datumCache[id] ? (
                        <DatumCard 
                          key={id}
                          datum={datumCache[id]}
                          properties={selectedProperties}
                          rank={groupIndex}
                          isCorrect={searchResults.params?.correct_datum === id}
                        />
                      ) : (
                        <div key={id} className="animate-pulse h-20 bg-gray-200 rounded" />
                      )
                    ))}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>

      {showDropdown && (
        <AutocompleteDropdown
          options={availableProperties}
          onSelect={handlePropertySelect}
          position={dropdownPosition}
          filterText={filterText}
        />
      )}
    </div>
  );
}