
import React from 'react';
import Plot from 'react-plotly.js';
import { BarChartProps } from './ChartInterfaces';

const BarChart: React.FC<BarChartProps> = ({ data, title = 'Bar Chart', xAxisLabel, yAxisLabel }) => {
  return (
    <Plot
      data={[
        {
          x: data.categories,
          y: data.values,
          type: 'bar',
          marker: { color: 'rgba(55, 83, 109, 0.7)' },
        },
      ]}
      layout={{
        title,
        font: { size: 16 },
        xaxis: { title: xAxisLabel },
        yaxis: { title: yAxisLabel },
        margin: { t: 50, b: 50, l: 60, r: 10 },
      }}
      config={{responsive: true}}
      className="w-full h-full"
    />
  );
};

export default BarChart;