import React, { useState, useRef } from 'react';
import { useOasisBackend } from '../../../hooks/useOasisBackend';
import { Dataset as DatasetType } from '../../../api/OasisBackendApi';
import SubmitButton from '../SubmitButton';
import AutocompleteDropdown from '../AutocompleteDropdown';

interface DuplicateDatasetProps {
  dataset: DatasetType;
  onDatasetDuplicated: (newDataset: DatasetType) => void;
  onCancel: () => void;
}

const DuplicateDataset: React.FC<DuplicateDatasetProps> = ({ dataset, onDatasetDuplicated, onCancel }) => {
  const db = useOasisBackend();
  const [name, setName] = useState(`${dataset.name} (copy)`);
  const [description, setDescription] = useState(dataset.description);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [filterText, setFilterText] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const availableColumns = dataset.sample_datum?.content ? Object.keys(dataset.sample_datum.content) : [];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const columnsToSend = selectedColumns.length > 0 ? selectedColumns : availableColumns;
      const response = await db.endpoints.testsuite.testsuiteApiDuplicateDatasetCreate(
        dataset.id.toString(),
        {
          name,
          description,
          columns: columnsToSend
        }
      );
      onDatasetDuplicated(response.data);
    } catch (error) {
      console.error('Error duplicating dataset:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4" onClick={(e) => e.stopPropagation()}>
      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Dataset Name
        </label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Description
        </label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Columns to Include
        </label>
        <div>
          <div className="flex items-center w-full mb-2">
            <input
              type="checkbox"
              className="mr-2"
              checked={selectedColumns.length === availableColumns.length}
              onChange={(e) => {
                setSelectedColumns(e.target.checked ? [...availableColumns] : []);
              }}
            />
            <span className="text-sm font-medium">Select All</span>
          </div>
          <div className="flex items-center w-full">
            <input
              ref={inputRef}
              type="text"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={filterText}
              onChange={(e) => {
                setFilterText(e.target.value);
                setShowDropdown(true);
              }}
              onFocus={() => {
                setShowDropdown(true);
                if (inputRef.current) {
                  const rect = inputRef.current.getBoundingClientRect();
                  setDropdownPosition({ top: rect.bottom, left: rect.left });
                }
              }}
              onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
              placeholder="Search columns..."
            />
          </div>
          <div className="flex flex-wrap mt-2">
            {selectedColumns.map((column, index) => (
              <span key={index} className="bg-blue-100 text-blue-800 px-2 py-1 rounded mr-2 mb-2">
                {column}
                <button
                  type="button"
                  className="ml-2 text-blue-600 hover:text-blue-800"
                  onClick={() => {
                    setSelectedColumns(selectedColumns.filter(col => col !== column));
                  }}
                >
                  ×
                </button>
              </span>
            ))}
          </div>
        </div>
      </div>

      <div className="flex justify-end space-x-2">
        <button
          type="button"
          onClick={onCancel}
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Cancel
        </button>
        <SubmitButton
          isSubmitting={isSubmitting}
          submitText="Duplicate Dataset"
          loadingText="Duplicating..."
        />
      </div>

      {showDropdown && (
        <AutocompleteDropdown
          options={availableColumns.filter((col: string) => !selectedColumns.includes(col))}
          onSelect={(option) => {
            if (!selectedColumns.includes(option)) {
              setSelectedColumns([...selectedColumns, option]);
            }
            setFilterText('');
          }}
          position={dropdownPosition}
          filterText={filterText}
        />
      )}
    </form>
  );
};

export default DuplicateDataset;
