/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface APIWaitlist {
  /**
   * @format email
   * @maxLength 254
   */
  email: string;
  name: string;
  company: string;
  role: string;
}

/**
 * * `text` - Text
 * * `number` - Number
 * * `email` - Email
 * * `date` - Date
 * * `phone` - Phone
 */
export enum AllowedFormatEnum {
  Text = "text",
  Number = "number",
  Email = "email",
  Date = "date",
  Phone = "phone",
}

/**
 * * `DistributionAnalysis` - Distribution Analysis
 * * `CorrelationAnalysis` - Correlation Analysis
 * * `SearchAnalysis` - Search Analysis
 */
export enum AnalysisClassEnum {
  DistributionAnalysis = "DistributionAnalysis",
  CorrelationAnalysis = "CorrelationAnalysis",
  SearchAnalysis = "SearchAnalysis",
}

export interface AuthorizationCode {
  code: string;
}

/**
 * * `TEXT` - Text
 * * `ANALYSIS` - Analysis
 * * `BENCHMARK` - Benchmark
 */
export enum BlockTypeEnum {
  TEXT = "TEXT",
  ANALYSIS = "ANALYSIS",
  BENCHMARK = "BENCHMARK",
}

export interface CancelLLMJob {
  id: number;
}

export interface ChatGetNotFoundResponse {
  detail: string;
}

export interface ChatPostBadRequestResponse {
  detail: string;
}

export interface ChatPostRequest {
  conversation_id?: number;
  message: string;
}

export interface ContactUsResponse {
  /**
   * @format email
   * @maxLength 254
   */
  email: string;
  message: string;
  name: string;
  company: string;
  role: string;
}

export interface Conversation {
  id: number;
  /** @format date-time */
  created_at: string;
  account: number;
  published_to_web?: boolean;
  /** @maxLength 255 */
  title: string;
  messages: Message[];
}

export interface CopilotSuggestion {
  context: Record<string, any>;
  input: Record<string, any>;
}

export interface CreateExperimentFromTemplateOptional {
  search_results_dataset_id?: number;
  benchmark_dataset_id?: number;
}

export interface CreateExperimentFromTemplateResponse {
  optional: CreateExperimentFromTemplateOptional;
}

export interface DataProcessingRequest {
  dataset: string;
  ranking_algorithm: string;
  operation: string;
  dimensions?: Record<string, any>;
  query?: string;
}

export interface DataProcessingResponse {
  computedAxis: string;
  dataPoints: Record<string, any>[];
  dimensions: string[];
  database: string;
}

export interface DataSetConfigurationResponse {
  DATA_SETS: Record<string, Record<string, any>>;
  OPERATIONS: Record<string, Record<string, any>>;
}

export interface Dataset {
  id: number;
  data_count: string;
  parent_dataset: string;
  is_root: string;
  sample_datum: Datum;
  /** @maxLength 255 */
  name: string;
  /** @maxLength 255 */
  data_type: string;
  /** @maxLength 255 */
  data_source: string;
  description: string;
  /** @format date-time */
  created_at: string;
  meta_data?: any;
  account?: number | null;
  subsets: number[];
}

export interface DatasetUpdate {
  /** @maxLength 255 */
  name: string;
  description: string;
  /** @maxLength 255 */
  data_type: string;
  /** @maxLength 255 */
  data_source: string;
}

export interface Datum {
  id: number;
  content: any;
  meta_data?: any;
  dataset: number;
  llm_job?: number | null;
}

/**
 * * `single` - Single Deadline
 * * `multiple_cut_off` - Multiple Cut-Off
 * * `two_stage` - Two-Stage
 */
export enum DeadlineModelEnum {
  Single = "single",
  MultipleCutOff = "multiple_cut_off",
  TwoStage = "two_stage",
}

export interface EUFunctionDimensionRequest {
  params: Record<string, any>;
  query: string;
  wished_for_dimensions: Record<string, any>;
  dimensions_score: number;
  semantic_score: number;
  conversation_summary: string;
}

export interface EUFunctionDimensionResponse {
  response: Record<string, any>;
}

export interface EUFundingSource {
  id: number;
  /** @maxLength 255 */
  external_id: string;
  /** @maxLength 255 */
  title?: string | null;
  summary?: string | null;
  content?: string | null;
  /**
   * @format uri
   * @maxLength 1024
   */
  url: string;
  /** @maxLength 255 */
  identifier?: string | null;
  /** @maxLength 255 */
  topic_title?: string | null;
  /** @maxLength 255 */
  call_title?: string | null;
  description?: string | null;
  conditions?: string | null;
  /**
   * * `single` - Single Deadline
   * * `multiple_cut_off` - Multiple Cut-Off
   * * `two_stage` - Two-Stage
   */
  deadline_model: DeadlineModelEnum;
  /**
   * @format decimal
   * @pattern ^-?\d{0,12}(?:\.\d{0,2})?$
   */
  min_grant?: string | null;
  /**
   * @format decimal
   * @pattern ^-?\d{0,12}(?:\.\d{0,2})?$
   */
  max_grant?: string | null;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  number_of_grants?: number | null;
  deadlines?: string[];
  extra_information?: any;
  geographies?: any;
  /** @format date-time */
  latest_deadline?: string | null;
  ai_summary?: string | null;
  /** @format date-time */
  earliest_deadline?: string | null;
  ai_short_description?: string | null;
  /** @maxLength 255 */
  ai_max_funding_amount?: string | null;
  /** @maxLength 50 */
  ai_funding_amount_currency?: string | null;
  ai_locations?: any;
  /** @format double */
  sustainability?: number;
  /** @format double */
  innovation?: number;
  /** @format double */
  economic_scalability_and_impact?: number;
  /** @format double */
  interdisciplinary_collaboration?: number;
  /** @format double */
  technological_readiness?: number;
  /** @format double */
  impact?: number;
  /** @format double */
  feasibility_and_support?: number;
  /** @format double */
  collaboration?: number;
  /** @format double */
  support_and_training?: number;
  /** @format double */
  strategic_alignment?: number;
  /** @format double */
  financial_suitability?: number;
  /** @format double */
  efficiency_and_quality?: number;
  /** @format double */
  compliance_and_administration?: number;
  /** @format double */
  evaluation_and_feedback?: number;
  /** @format double */
  clarity_and_requirements?: number;
  /** @format double */
  financial_considerations_and_support?: number;
  /** @format double */
  digital_transformation_and_innovation?: number;
  /** @format double */
  sustainability_focus_and_innovation?: number;
  /** @format double */
  accountability?: number;
  /** @format double */
  relevance_to_environmental_studies?: number;
  /** @format double */
  focus_on_marine_pollution?: number;
  /** @format double */
  response_to_accidental_releases?: number;
  /** @format double */
  support_for_alternative_fuels?: number;
  /** @format double */
  rigorous_criteria_for_funding?: number;
  /** @format double */
  financials?: number;
  /** @format double */
  management?: number;
  /** @format double */
  eligibility?: number;
  /** @format double */
  objectives?: number;
  /** @format double */
  support?: number;
  /** @format double */
  alignment_with_goals?: number;
  /** @format double */
  geographical_focus?: number;
  /** @format double */
  relevance_to_sector?: number;
  /** @format double */
  financial_details?: number;
  /** @format double */
  deadlines_and_timing?: number;
  /** @format double */
  transparency?: number;
  /** @format double */
  resource_availability?: number;
  /** @format double */
  environmental_impact?: number;
  /** @format double */
  potential_impact?: number;
  /** @format double */
  reliability?: number;
  /** @format double */
  commitment?: number;
  /** @format double */
  organizational_capacity?: number;
  /** @format double */
  quality_of_measures?: number;
  /** @format double */
  monitoring_and_compliance?: number;
  /** @format double */
  non_discrimination_and_inclusivity?: number;
  /** @format double */
  ethical_volunteering_practices?: number;
  /** @format double */
  impact_orientation?: number;
  /** @format double */
  workforce_development?: number;
  /** @format double */
  competitive?: number;
  /** @format double */
  urban_partnership?: number;
  /** @format double */
  knowledge_sharing?: number;
  /** @format double */
  funder_clarity_and_transparency?: number;
  /** @format double */
  financial_support_details?: number;
}

export interface Experiment {
  id: number;
  operations: Operation[];
  input_datasets: string;
  output_dataset: string;
  /**
   * * `LLMExperiment` - LLM Experiment
   * * `SearchExperiment` - Search Experiment
   */
  experiment_class: ExperimentClassEnum;
  /** @maxLength 255 */
  name: string;
  description: string;
  /** @format date */
  created_at: string;
  /**
   * * `PENDING` - PENDING
   * * `RUNNING` - RUNNING
   * * `COMPLETED` - COMPLETED
   * * `FAILED` - FAILED
   */
  status?: ExperimentStatus;
  account?: number | null;
}

/**
 * * `LLMExperiment` - LLM Experiment
 * * `SearchExperiment` - Search Experiment
 */
export enum ExperimentClassEnum {
  LLMExperiment = "LLMExperiment",
  SearchExperiment = "SearchExperiment",
}

/**
 * * `PENDING` - PENDING
 * * `RUNNING` - RUNNING
 * * `COMPLETED` - COMPLETED
 * * `FAILED` - FAILED
 */
export enum ExperimentStatus {
  PENDING = "PENDING",
  RUNNING = "RUNNING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export interface ExportDataset {
  id: number;
  data: Datum[];
  /** @maxLength 255 */
  name: string;
  /** @maxLength 255 */
  data_type: string;
  /** @maxLength 255 */
  data_source: string;
  description: string;
  /** @format date-time */
  created_at: string;
  meta_data?: any;
  account?: number | null;
  subsets: number[];
}

export interface ExportExperiment {
  id: number;
  operations: ExportOperation[];
  status: string;
  /**
   * * `LLMExperiment` - LLM Experiment
   * * `SearchExperiment` - Search Experiment
   */
  experiment_class: ExperimentClassEnum;
  /** @maxLength 255 */
  name: string;
  description: string;
  /** @format date */
  created_at: string;
  account?: number | null;
}

export interface ExportOperation {
  id: number;
  input_dataset?: Dataset;
  output_dataset?: Dataset;
  system_prompt_template?: FullPromptTemplate;
  user_prompt_template?: FullPromptTemplate;
  /**
   * * `PENDING` - PENDING
   * * `RUNNING` - RUNNING
   * * `COMPLETED` - COMPLETED
   * * `BATCH_PROCESSING` - BATCH_PROCESSING
   * * `FAILED` - FAILED
   * * `CANCELLED` - CANCELLED
   */
  status?: PossibleProcessingStatus;
  /** @maxLength 255 */
  execution_class: string;
  description: string;
  name: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  started_at?: string | null;
  /** @format date-time */
  finished_at?: string | null;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  experiment_order?: number;
  model?: ModelEnum | NullEnum | null;
  can_run?: boolean;
  params?: any;
  experiment?: number | null;
  account?: number | null;
}

export interface Form {
  /** @format uuid */
  id: string;
  fields: FormField[];
  submissions: string;
  submissionCount: string;
  /** @maxLength 255 */
  title: string;
  purpose?: string;
  /** @format date-time */
  created_at: string;
  account: number;
}

export interface FormField {
  /** @format uuid */
  id: string;
  /** @maxLength 255 */
  name: string;
  description?: string;
  mandatory?: boolean;
  /**
   * * `text` - Text
   * * `number` - Number
   * * `email` - Email
   * * `date` - Date
   * * `phone` - Phone
   */
  allowed_format?: AllowedFormatEnum;
  disabled?: boolean;
  /** @format date-time */
  created_at: string;
  /** @format uuid */
  form: string;
}

export interface FullAnalysis {
  id: number;
  input_datasets?: Dataset[];
  config: any;
  search_collection: SearchCollection;
  /** @maxLength 255 */
  name: string;
  description: string;
  /**
   * * `DistributionAnalysis` - Distribution Analysis
   * * `CorrelationAnalysis` - Correlation Analysis
   * * `SearchAnalysis` - Search Analysis
   */
  analysis_class: AnalysisClassEnum;
  params?: any;
  outputs?: any;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  account?: number | null;
  study?: number | null;
}

export interface FullExperiment {
  id: number;
  operations: FullOperation[];
  input_datasets: Dataset[];
  output_dataset: string;
  /**
   * * `LLMExperiment` - LLM Experiment
   * * `SearchExperiment` - Search Experiment
   */
  experiment_class: ExperimentClassEnum;
  /** @maxLength 255 */
  name: string;
  description: string;
  /** @format date */
  created_at: string;
  /**
   * * `PENDING` - PENDING
   * * `RUNNING` - RUNNING
   * * `COMPLETED` - COMPLETED
   * * `FAILED` - FAILED
   */
  status?: ExperimentStatus;
  account?: number | null;
}

export interface FullOperation {
  id: number;
  input_dataset?: Dataset;
  output_dataset?: Dataset;
  system_prompt_template?: FullPromptTemplate;
  user_prompt_template?: FullPromptTemplate;
  allowed_params: any;
  mandatory_params: any;
  /** @format double */
  minimum_cost: number | null;
  llm_jobs: string;
  /**
   * * `PENDING` - PENDING
   * * `RUNNING` - RUNNING
   * * `COMPLETED` - COMPLETED
   * * `BATCH_PROCESSING` - BATCH_PROCESSING
   * * `FAILED` - FAILED
   * * `CANCELLED` - CANCELLED
   */
  status?: PossibleProcessingStatus;
  /** @maxLength 255 */
  execution_class: string;
  description: string;
  name: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  started_at?: string | null;
  /** @format date-time */
  finished_at?: string | null;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  experiment_order?: number;
  model?: ModelEnum | NullEnum | null;
  can_run?: boolean;
  params?: any;
  experiment?: number | null;
  account?: number | null;
}

export interface FullPromptTemplate {
  id: number;
  dataset?: Dataset;
  /** @maxLength 255 */
  name: string;
  description: string;
  prompt?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  type?: TypeEnum | NullEnum | null;
  /**
   * * `JSON` - JSON
   * * `STRING` - STRING
   */
  output_type?: OutputTypeEnum;
  account?: number | null;
}

export interface FullStudy {
  id: number;
  blocks: FullStudyBlock[];
  /** @maxLength 255 */
  name: string;
  description: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  account?: number | null;
  experiments: number[];
}

export interface FullStudyBlock {
  id: number;
  analysis?: FullAnalysis;
  experiment?: FullExperiment;
  /**
   * * `TEXT` - Text
   * * `ANALYSIS` - Analysis
   * * `BENCHMARK` - Benchmark
   */
  block_type: BlockTypeEnum;
  /**
   * @min 0
   * @max 2147483647
   */
  order: number;
  content?: string | null;
  study: number;
  account?: number | null;
}

export interface LLMFunctionCallerRequest {
  function_name: string;
  arguments: Record<string, any>;
}

export interface LLMFunctionCallerResponse {
  response: Record<string, any>;
}

export interface LLMJob {
  id: number;
  datum: Datum;
  messages: any;
  /**
   * * `PENDING` - PENDING
   * * `RUNNING` - RUNNING
   * * `COMPLETED` - COMPLETED
   * * `BATCH_PROCESSING` - BATCH_PROCESSING
   * * `FAILED` - FAILED
   * * `CANCELLED` - CANCELLED
   */
  status?: PossibleProcessingStatus;
  /** @format date-time */
  created_at: string;
  error_message: string;
  pass_through_data?: any;
  parameters?: any;
  operation: number;
  account?: number | null;
}

export interface Login {
  email: string;
  password: string;
}

export interface Message {
  id: number;
  conversation_id: number;
  message: any;
  /** @format date-time */
  created_at: string;
}

/**
 * * `gpt-4o` - GPT-4o
 * * `gpt-4o-mini` - GPT-4o-mini
 * * `gpt-4o-mini-batch` - GPT-4o-mini-batch
 * * `gpt-4o-batch` - GPT-4o-batch
 * * `meta-llama/llama-3.1-405b-instruct:free` - meta-llama/llama-3.1-405b-instruct:free
 */
export enum ModelEnum {
  Gpt4O = "gpt-4o",
  Gpt4OMini = "gpt-4o-mini",
  Gpt4OMiniBatch = "gpt-4o-mini-batch",
  Gpt4OBatch = "gpt-4o-batch",
  MetaLlamaLlama31405BInstructFree = "meta-llama/llama-3.1-405b-instruct:free",
}

export type NullEnum = null;

export interface Operation {
  id: number;
  input_dataset?: number | null;
  output_dataset?: number | null;
  system_prompt_template?: number | null;
  user_prompt_template?: number | null;
  llm_jobs: string;
  /**
   * * `PENDING` - PENDING
   * * `RUNNING` - RUNNING
   * * `COMPLETED` - COMPLETED
   * * `BATCH_PROCESSING` - BATCH_PROCESSING
   * * `FAILED` - FAILED
   * * `CANCELLED` - CANCELLED
   */
  status?: PossibleProcessingStatus;
  /** @maxLength 255 */
  execution_class: string;
  description: string;
  name: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  started_at?: string | null;
  /** @format date-time */
  finished_at?: string | null;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  experiment_order?: number;
  model?: ModelEnum | NullEnum | null;
  can_run?: boolean;
  params?: any;
  experiment?: number | null;
  account?: number | null;
}

/**
 * * `JSON` - JSON
 * * `STRING` - STRING
 */
export enum OutputTypeEnum {
  JSON = "JSON",
  STRING = "STRING",
}

/**
 * * `PENDING` - PENDING
 * * `RUNNING` - RUNNING
 * * `COMPLETED` - COMPLETED
 * * `BATCH_PROCESSING` - BATCH_PROCESSING
 * * `FAILED` - FAILED
 * * `CANCELLED` - CANCELLED
 */
export enum PossibleProcessingStatus {
  PENDING = "PENDING",
  RUNNING = "RUNNING",
  COMPLETED = "COMPLETED",
  BATCH_PROCESSING = "BATCH_PROCESSING",
  FAILED = "FAILED",
  CANCELLED = "CANCELLED",
}

export interface PromptTemplate {
  id: number;
  /** @maxLength 255 */
  name: string;
  description: string;
  prompt?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  type?: TypeEnum | NullEnum | null;
  /**
   * * `JSON` - JSON
   * * `STRING` - STRING
   */
  output_type?: OutputTypeEnum;
  dataset?: number | null;
  account?: number | null;
}

export interface PythonSandboxForDatasets {
  code: string;
  datasets: number[];
}

export interface RedownloadBatchLLMJob {
  id: number;
}

export interface RetryLLMJob {
  id: number;
}

export interface Search {
  query: string;
  dataset_id?: number;
}

export interface SearchCollection {
  id: number;
  search_results: SearchResult[];
  /** @maxLength 255 */
  name: string;
  description: string;
  /** @format date-time */
  created_at: string;
  params?: any;
  account?: number | null;
  analysis?: number | null;
}

export interface SearchResult {
  id: number;
  dataset?: Dataset;
  /** @maxLength 10000 */
  query: string;
  /** @format date-time */
  created_at: string;
  results: any;
  params?: any;
  account?: number | null;
  search_collection?: number | null;
}

export interface SignupError {
  error: Record<string, string[]>;
}

export interface StripeCheckout {
  slug: string;
}

export interface StripeCheckoutResponse {
  /** @format uri */
  stripe_checkout_url: string;
}

export interface StripePortal {
  /** @format uri */
  stripe_portal_url: string;
}

export interface Submission {
  /** @format uuid */
  id: string;
  fields: SubmissionField[];
  /** @format date-time */
  created_at: string;
  is_test?: boolean;
  is_submitted?: boolean;
  /** @format uuid */
  form: string;
}

export interface SubmissionField {
  /** @format uuid */
  id: string;
  value?: string;
  /** @format uuid */
  submission: string;
  /** @format uuid */
  form_field: string;
}

export interface SubsetCreation {
  dataset_id: number;
  name: string;
  description: string;
  size: number;
  sampling_strategy: string;
  query: string;
}

/**
 * * `SYSTEM` - SYSTEM
 * * `USER` - USER
 */
export enum TypeEnum {
  SYSTEM = "SYSTEM",
  USER = "USER",
}

export interface User {
  /** @maxLength 150 */
  first_name?: string;
  /** @maxLength 150 */
  last_name?: string;
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
}

export interface UserResponse {
  user: User;
}

export interface UserSignup {
  /** @maxLength 150 */
  first_name?: string;
  /** @maxLength 150 */
  last_name?: string;
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
  /** @maxLength 128 */
  password: string;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title No title
 * @version 0.0.0
 */
export class OasisBackendApi<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags api
     * @name ApiContactUsCreate
     * @request POST:/api/contact-us/
     * @secure
     */
    apiContactUsCreate: (data: ContactUsResponse, params: RequestParams = {}) =>
      this.request<ContactUsResponse, any>({
        path: `/api/contact-us/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ApiWaitlistCreate
     * @request POST:/api/waitlist/
     * @secure
     */
    apiWaitlistCreate: (data: APIWaitlist, params: RequestParams = {}) =>
      this.request<APIWaitlist, any>({
        path: `/api/waitlist/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  authorize = {
    /**
     * No description
     *
     * @tags authorize
     * @name AuthorizeRetrieve
     * @request GET:/authorize/
     * @secure
     */
    authorizeRetrieve: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/authorize/`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags authorize
     * @name AuthorizeCreate
     * @request POST:/authorize/
     * @secure
     */
    authorizeCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/authorize/`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
  conversation = {
    /**
     * No description
     *
     * @tags conversation
     * @name ConversationRetrieve
     * @request GET:/conversation/
     * @secure
     */
    conversationRetrieve: (
      query: {
        conversation_id: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<Conversation, ChatGetNotFoundResponse>({
        path: `/conversation/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags conversation
     * @name ConversationCreate
     * @request POST:/conversation/
     * @secure
     */
    conversationCreate: (data: ChatPostRequest, params: RequestParams = {}) =>
      this.request<Conversation, ChatPostBadRequestResponse>({
        path: `/conversation/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  csrfToken = {
    /**
     * No description
     *
     * @tags csrf_token
     * @name CsrfTokenCreate
     * @request POST:/csrf_token/
     * @secure
     */
    csrfTokenCreate: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/csrf_token/`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
  dataProcessing = {
    /**
     * No description
     *
     * @tags data-processing
     * @name DataProcessingCreate
     * @request POST:/data-processing/
     * @secure
     */
    dataProcessingCreate: (data: DataProcessingRequest, params: RequestParams = {}) =>
      this.request<DataProcessingResponse, any>({
        path: `/data-processing/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  dataSetConfiguration = {
    /**
     * @description This view returns the DATA_SET_CONFIGURATION for frontend configuration.
     *
     * @tags data-set-configuration
     * @name DataSetConfigurationRetrieve
     * @request GET:/data-set-configuration/
     * @secure
     */
    dataSetConfigurationRetrieve: (params: RequestParams = {}) =>
      this.request<DataSetConfigurationResponse, any>({
        path: `/data-set-configuration/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  euFundingSource = {
    /**
     * No description
     *
     * @tags eu-funding-source
     * @name EuFundingSourceRetrieve
     * @request GET:/eu-funding-source/{id}/
     * @secure
     */
    euFundingSourceRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<EUFundingSource, any>({
        path: `/eu-funding-source/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  euFundingHybridParallelSearch = {
    /**
     * No description
     *
     * @tags eu_funding_hybrid_parallel_search
     * @name EuFundingHybridParallelSearchCreate
     * @request POST:/eu_funding_hybrid_parallel_search/
     * @secure
     */
    euFundingHybridParallelSearchCreate: (data: EUFunctionDimensionRequest, params: RequestParams = {}) =>
      this.request<EUFunctionDimensionResponse, any>({
        path: `/eu_funding_hybrid_parallel_search/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  euFundingOasisSearch = {
    /**
     * No description
     *
     * @tags eu_funding_oasis_search
     * @name EuFundingOasisSearchCreate
     * @request POST:/eu_funding_oasis_search/
     * @secure
     */
    euFundingOasisSearchCreate: (data: EUFunctionDimensionRequest, params: RequestParams = {}) =>
      this.request<EUFunctionDimensionResponse, any>({
        path: `/eu_funding_oasis_search/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  euFundingTraditionalRag = {
    /**
     * No description
     *
     * @tags eu_funding_traditional_rag
     * @name EuFundingTraditionalRagRetrieve
     * @request GET:/eu_funding_traditional_rag/
     * @secure
     */
    euFundingTraditionalRagRetrieve: (params: RequestParams = {}) =>
      this.request<EUFundingSource, any>({
        path: `/eu_funding_traditional_rag/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  feistyforms = {
    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiCoPilotCreate
     * @request POST:/feistyforms/api/co-pilot/
     * @secure
     */
    feistyformsApiCoPilotCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/feistyforms/api/co-pilot/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiCoPilotFormattedCreate
     * @request POST:/feistyforms/api/co-pilot{format}
     * @secure
     */
    feistyformsApiCoPilotFormattedCreate: (format: ".json", params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/feistyforms/api/co-pilot${format}`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiConversationCreate
     * @request POST:/feistyforms/api/conversation/
     * @secure
     */
    feistyformsApiConversationCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/feistyforms/api/conversation/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiConversationFormattedCreate
     * @request POST:/feistyforms/api/conversation{format}
     * @secure
     */
    feistyformsApiConversationFormattedCreate: (format: ".json", params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/feistyforms/api/conversation${format}`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiCreateFormCreate
     * @request POST:/feistyforms/api/create-form/
     * @secure
     */
    feistyformsApiCreateFormCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/feistyforms/api/create-form/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiCreateFormFormattedCreate
     * @request POST:/feistyforms/api/create-form{format}
     * @secure
     */
    feistyformsApiCreateFormFormattedCreate: (format: ".json", params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/feistyforms/api/create-form${format}`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiEditFormCreate
     * @request POST:/feistyforms/api/edit-form/{form_id}/
     * @secure
     */
    feistyformsApiEditFormCreate: (formId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/feistyforms/api/edit-form/${formId}/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiEditFormFormattedCreate
     * @request POST:/feistyforms/api/edit-form/{form_id}{format}
     * @secure
     */
    feistyformsApiEditFormFormattedCreate: (formId: string, format: ".json", params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/feistyforms/api/edit-form/${formId}${format}`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiEnrichAllSubmissionsCreate
     * @request POST:/feistyforms/api/enrich-all-submissions/{form_id}/
     * @secure
     */
    feistyformsApiEnrichAllSubmissionsCreate: (
      formId: string,
      data: {
        form_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Form, any>({
        path: `/feistyforms/api/enrich-all-submissions/${formId}/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiEnrichAllSubmissionsFormattedCreate
     * @request POST:/feistyforms/api/enrich-all-submissions/{form_id}{format}
     * @secure
     */
    feistyformsApiEnrichAllSubmissionsFormattedCreate: (
      formId: string,
      format: ".json",
      data: {
        form_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Form, any>({
        path: `/feistyforms/api/enrich-all-submissions/${formId}${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiEnrichSubmissionCreate
     * @request POST:/feistyforms/api/enrich-submission/{submission_id}/
     * @secure
     */
    feistyformsApiEnrichSubmissionCreate: (
      submissionId: string,
      data: {
        submission_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Submission, any>({
        path: `/feistyforms/api/enrich-submission/${submissionId}/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiEnrichSubmissionFormattedCreate
     * @request POST:/feistyforms/api/enrich-submission/{submission_id}{format}
     * @secure
     */
    feistyformsApiEnrichSubmissionFormattedCreate: (
      format: ".json",
      submissionId: string,
      data: {
        submission_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Submission, any>({
        path: `/feistyforms/api/enrich-submission/${submissionId}${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiGetConsentRetrieve
     * @request GET:/feistyforms/api/get-consent/{form_id}/
     * @secure
     */
    feistyformsApiGetConsentRetrieve: (formId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/feistyforms/api/get-consent/${formId}/`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiGetConsentFormattedRetrieve
     * @request GET:/feistyforms/api/get-consent/{form_id}{format}
     * @secure
     */
    feistyformsApiGetConsentFormattedRetrieve: (formId: string, format: ".json", params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/feistyforms/api/get-consent/${formId}${format}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiGetFormTitleRetrieve
     * @request GET:/feistyforms/api/get-form-title/{form_id}/
     * @secure
     */
    feistyformsApiGetFormTitleRetrieve: (formId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/feistyforms/api/get-form-title/${formId}/`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiGetFormTitleFormattedRetrieve
     * @request GET:/feistyforms/api/get-form-title/{form_id}{format}
     * @secure
     */
    feistyformsApiGetFormTitleFormattedRetrieve: (formId: string, format: ".json", params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/feistyforms/api/get-form-title/${formId}${format}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiGetFormRetrieve
     * @request GET:/feistyforms/api/get-form/{form_id}/
     * @secure
     */
    feistyformsApiGetFormRetrieve: (formId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/feistyforms/api/get-form/${formId}/`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiGetFormFormattedRetrieve
     * @request GET:/feistyforms/api/get-form/{form_id}{format}
     * @secure
     */
    feistyformsApiGetFormFormattedRetrieve: (formId: string, format: ".json", params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/feistyforms/api/get-form/${formId}${format}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiGetSingleSubmissionRetrieve
     * @request GET:/feistyforms/api/get-single-submission/{conversation_id}/
     * @secure
     */
    feistyformsApiGetSingleSubmissionRetrieve: (conversationId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/feistyforms/api/get-single-submission/${conversationId}/`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiGetSingleSubmissionFormattedRetrieve
     * @request GET:/feistyforms/api/get-single-submission/{conversation_id}{format}
     * @secure
     */
    feistyformsApiGetSingleSubmissionFormattedRetrieve: (
      conversationId: string,
      format: ".json",
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/feistyforms/api/get-single-submission/${conversationId}${format}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiGetSubmissionConversationRetrieve
     * @request GET:/feistyforms/api/get-submission-conversation/{submission_id}/
     * @secure
     */
    feistyformsApiGetSubmissionConversationRetrieve: (submissionId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/feistyforms/api/get-submission-conversation/${submissionId}/`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiGetSubmissionConversationFormattedRetrieve
     * @request GET:/feistyforms/api/get-submission-conversation/{submission_id}{format}
     * @secure
     */
    feistyformsApiGetSubmissionConversationFormattedRetrieve: (
      format: ".json",
      submissionId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/feistyforms/api/get-submission-conversation/${submissionId}${format}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiGetSubscriptionStatusRetrieve
     * @request GET:/feistyforms/api/get-subscription-status/
     * @secure
     */
    feistyformsApiGetSubscriptionStatusRetrieve: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/feistyforms/api/get-subscription-status/`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiGetSubscriptionStatusFormattedRetrieve
     * @request GET:/feistyforms/api/get-subscription-status{format}
     * @secure
     */
    feistyformsApiGetSubscriptionStatusFormattedRetrieve: (format: ".json", params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/feistyforms/api/get-subscription-status${format}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiGetUserFormsRetrieve
     * @request GET:/feistyforms/api/get-user-forms/
     * @secure
     */
    feistyformsApiGetUserFormsRetrieve: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/feistyforms/api/get-user-forms/`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiGetUserFormsFormattedRetrieve
     * @request GET:/feistyforms/api/get-user-forms{format}
     * @secure
     */
    feistyformsApiGetUserFormsFormattedRetrieve: (format: ".json", params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/feistyforms/api/get-user-forms${format}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiRenderFormRetrieve
     * @request GET:/feistyforms/api/render-form/{form_id}/
     * @secure
     */
    feistyformsApiRenderFormRetrieve: (formId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/feistyforms/api/render-form/${formId}/`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiRenderFormFormattedRetrieve
     * @request GET:/feistyforms/api/render-form/{form_id}{format}
     * @secure
     */
    feistyformsApiRenderFormFormattedRetrieve: (formId: string, format: ".json", params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/feistyforms/api/render-form/${formId}${format}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiViewResultsRetrieve
     * @request GET:/feistyforms/api/view-results/{form_id}/
     * @secure
     */
    feistyformsApiViewResultsRetrieve: (formId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/feistyforms/api/view-results/${formId}/`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags feistyforms
     * @name FeistyformsApiViewResultsFormattedRetrieve
     * @request GET:/feistyforms/api/view-results/{form_id}{format}
     * @secure
     */
    feistyformsApiViewResultsFormattedRetrieve: (formId: string, format: ".json", params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/feistyforms/api/view-results/${formId}${format}`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  generateAuthCode = {
    /**
     * No description
     *
     * @tags generate_auth_code
     * @name GenerateAuthCodeCreate
     * @request POST:/generate_auth_code/
     * @secure
     */
    generateAuthCodeCreate: (params: RequestParams = {}) =>
      this.request<AuthorizationCode, any>({
        path: `/generate_auth_code/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  llmFunctionCaller = {
    /**
     * No description
     *
     * @tags llm_function_caller
     * @name LlmFunctionCallerCreate
     * @request POST:/llm_function_caller/
     * @secure
     */
    llmFunctionCallerCreate: (data: LLMFunctionCallerRequest, params: RequestParams = {}) =>
      this.request<LLMFunctionCallerResponse, any>({
        path: `/llm_function_caller/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  login = {
    /**
     * No description
     *
     * @tags login
     * @name LoginCreate
     * @request POST:/login/
     * @secure
     */
    loginCreate: (data: Login, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/login/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  logout = {
    /**
     * No description
     *
     * @tags logout
     * @name LogoutCreate
     * @request POST:/logout/
     * @secure
     */
    logoutCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/logout/`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
  signup = {
    /**
     * No description
     *
     * @tags signup
     * @name SignupCreate
     * @request POST:/signup/
     * @secure
     */
    signupCreate: (data: UserSignup, params: RequestParams = {}) =>
      this.request<void, SignupError>({
        path: `/signup/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  stripeCheckout = {
    /**
     * No description
     *
     * @tags stripe_checkout
     * @name StripeCheckoutCreate
     * @request POST:/stripe_checkout/
     * @secure
     */
    stripeCheckoutCreate: (data: StripeCheckout, params: RequestParams = {}) =>
      this.request<StripeCheckoutResponse, any>({
        path: `/stripe_checkout/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  stripePortal = {
    /**
     * No description
     *
     * @tags stripe_portal
     * @name StripePortalCreate
     * @request POST:/stripe_portal/
     * @secure
     */
    stripePortalCreate: (params: RequestParams = {}) =>
      this.request<StripePortal, any>({
        path: `/stripe_portal/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  stripeWebhook = {
    /**
     * No description
     *
     * @tags stripe_webhook
     * @name StripeWebhookCreate
     * @request POST:/stripe_webhook/
     * @secure
     */
    stripeWebhookCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/stripe_webhook/`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
  testsuite = {
    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiCancelLlmJobCreate
     * @request POST:/testsuite/api/cancel-llm-job/
     * @secure
     */
    testsuiteApiCancelLlmJobCreate: (data: CancelLLMJob, params: RequestParams = {}) =>
      this.request<LLMJob, any>({
        path: `/testsuite/api/cancel-llm-job/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiCancelLlmJobFormattedCreate
     * @request POST:/testsuite/api/cancel-llm-job{format}
     * @secure
     */
    testsuiteApiCancelLlmJobFormattedCreate: (format: ".json", data: CancelLLMJob, params: RequestParams = {}) =>
      this.request<LLMJob, any>({
        path: `/testsuite/api/cancel-llm-job${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiCancelOperationCreate
     * @request POST:/testsuite/api/cancel-operation/{operation_id}/
     * @secure
     */
    testsuiteApiCancelOperationCreate: (operationId: string, data: FullOperation, params: RequestParams = {}) =>
      this.request<FullOperation, any>({
        path: `/testsuite/api/cancel-operation/${operationId}/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiCancelOperationFormattedCreate
     * @request POST:/testsuite/api/cancel-operation/{operation_id}{format}
     * @secure
     */
    testsuiteApiCancelOperationFormattedCreate: (
      format: ".json",
      operationId: string,
      data: FullOperation,
      params: RequestParams = {},
    ) =>
      this.request<FullOperation, any>({
        path: `/testsuite/api/cancel-operation/${operationId}${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiCloneOperationCreate
     * @request POST:/testsuite/api/clone-operation/{operation_id}/
     * @secure
     */
    testsuiteApiCloneOperationCreate: (operationId: string, data: FullOperation, params: RequestParams = {}) =>
      this.request<FullOperation, any>({
        path: `/testsuite/api/clone-operation/${operationId}/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiCloneOperationFormattedCreate
     * @request POST:/testsuite/api/clone-operation/{operation_id}{format}
     * @secure
     */
    testsuiteApiCloneOperationFormattedCreate: (
      format: ".json",
      operationId: string,
      data: FullOperation,
      params: RequestParams = {},
    ) =>
      this.request<FullOperation, any>({
        path: `/testsuite/api/clone-operation/${operationId}${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiCreateAnalysisCreate
     * @request POST:/testsuite/api/create-analysis/
     * @secure
     */
    testsuiteApiCreateAnalysisCreate: (
      data: {
        name: string;
        description: string;
        analysis_class: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<FullAnalysis, any>({
        path: `/testsuite/api/create-analysis/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiCreateAnalysisFormattedCreate
     * @request POST:/testsuite/api/create-analysis{format}
     * @secure
     */
    testsuiteApiCreateAnalysisFormattedCreate: (
      format: ".json",
      data: {
        name: string;
        description: string;
        analysis_class: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<FullAnalysis, any>({
        path: `/testsuite/api/create-analysis${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Create a new dataset with the provided details.
     *
     * @tags testsuite
     * @name TestsuiteApiCreateDatasetCreate
     * @request POST:/testsuite/api/create-dataset/
     * @secure
     */
    testsuiteApiCreateDatasetCreate: (
      data: {
        name: string;
        description?: string;
        data_type: string;
        data_source: string;
        meta_data?: object;
        files: File[];
      },
      params: RequestParams = {},
    ) =>
      this.request<Dataset, any>({
        path: `/testsuite/api/create-dataset/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Create a new dataset with the provided details.
     *
     * @tags testsuite
     * @name TestsuiteApiCreateDatasetFormattedCreate
     * @request POST:/testsuite/api/create-dataset{format}
     * @secure
     */
    testsuiteApiCreateDatasetFormattedCreate: (
      format: ".json",
      data: {
        name: string;
        description?: string;
        data_type: string;
        data_source: string;
        meta_data?: object;
        files: File[];
      },
      params: RequestParams = {},
    ) =>
      this.request<Dataset, any>({
        path: `/testsuite/api/create-dataset${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Create a new experiment with the provided details.
     *
     * @tags testsuite
     * @name TestsuiteApiCreateExperimentCreate
     * @request POST:/testsuite/api/create-experiment/
     * @secure
     */
    testsuiteApiCreateExperimentCreate: (
      data: {
        name: string;
        description: string;
        experiment_class: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<FullExperiment, any>({
        path: `/testsuite/api/create-experiment/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Create a new experiment with the provided details.
     *
     * @tags testsuite
     * @name TestsuiteApiCreateExperimentFormattedCreate
     * @request POST:/testsuite/api/create-experiment{format}
     * @secure
     */
    testsuiteApiCreateExperimentFormattedCreate: (
      format: ".json",
      data: {
        name: string;
        description: string;
        experiment_class: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<FullExperiment, any>({
        path: `/testsuite/api/create-experiment${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiCreateExperimentFromTemplateCreate
     * @request POST:/testsuite/api/create-experiment-from-template/{template_name}/
     * @secure
     */
    testsuiteApiCreateExperimentFromTemplateCreate: (
      templateName: string,
      data: {
        search_dataset: number;
        config_dataset: number;
        model: string;
        synthetic_dataset_size: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CreateExperimentFromTemplateResponse, any>({
        path: `/testsuite/api/create-experiment-from-template/${templateName}/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiCreateExperimentFromTemplateFormattedCreate
     * @request POST:/testsuite/api/create-experiment-from-template/{template_name}{format}
     * @secure
     */
    testsuiteApiCreateExperimentFromTemplateFormattedCreate: (
      format: ".json",
      templateName: string,
      data: {
        search_dataset: number;
        config_dataset: number;
        model: string;
        synthetic_dataset_size: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CreateExperimentFromTemplateResponse, any>({
        path: `/testsuite/api/create-experiment-from-template/${templateName}${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Create a new operation with the provided details.
     *
     * @tags testsuite
     * @name TestsuiteApiCreateOperationCreate
     * @request POST:/testsuite/api/create-operation/
     * @secure
     */
    testsuiteApiCreateOperationCreate: (
      data: {
        name: string;
        description: string;
        execution_class: string;
        experiment: number;
        model?: string;
        experiment_order: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<FullOperation, any>({
        path: `/testsuite/api/create-operation/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Create a new operation with the provided details.
     *
     * @tags testsuite
     * @name TestsuiteApiCreateOperationFormattedCreate
     * @request POST:/testsuite/api/create-operation{format}
     * @secure
     */
    testsuiteApiCreateOperationFormattedCreate: (
      format: ".json",
      data: {
        name: string;
        description: string;
        execution_class: string;
        experiment: number;
        model?: string;
        experiment_order: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<FullOperation, any>({
        path: `/testsuite/api/create-operation${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Create a new prompt template with the provided details.
     *
     * @tags testsuite
     * @name TestsuiteApiCreatePromptTemplateCreate
     * @request POST:/testsuite/api/create-prompt-template/
     * @secure
     */
    testsuiteApiCreatePromptTemplateCreate: (
      data: {
        name: string;
        description: string;
        type: string;
        output_type?: string;
        prompt: string;
        dataset?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<FullPromptTemplate, any>({
        path: `/testsuite/api/create-prompt-template/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Create a new prompt template with the provided details.
     *
     * @tags testsuite
     * @name TestsuiteApiCreatePromptTemplateFormattedCreate
     * @request POST:/testsuite/api/create-prompt-template{format}
     * @secure
     */
    testsuiteApiCreatePromptTemplateFormattedCreate: (
      format: ".json",
      data: {
        name: string;
        description: string;
        type: string;
        output_type?: string;
        prompt: string;
        dataset?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<FullPromptTemplate, any>({
        path: `/testsuite/api/create-prompt-template${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiCreateStudyCreate
     * @request POST:/testsuite/api/create-study/
     * @secure
     */
    testsuiteApiCreateStudyCreate: (
      data: {
        name: string;
        description: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<FullStudy, any>({
        path: `/testsuite/api/create-study/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiCreateStudyFormattedCreate
     * @request POST:/testsuite/api/create-study{format}
     * @secure
     */
    testsuiteApiCreateStudyFormattedCreate: (
      format: ".json",
      data: {
        name: string;
        description: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<FullStudy, any>({
        path: `/testsuite/api/create-study${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiCreateStudyBlockCreate
     * @request POST:/testsuite/api/create-study-block/
     * @secure
     */
    testsuiteApiCreateStudyBlockCreate: (
      data: {
        study: number;
        block_type: "TEXT" | "ANALYSIS" | "BENCHMARK";
        order: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<FullStudyBlock, any>({
        path: `/testsuite/api/create-study-block/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiCreateStudyBlockFormattedCreate
     * @request POST:/testsuite/api/create-study-block{format}
     * @secure
     */
    testsuiteApiCreateStudyBlockFormattedCreate: (
      format: ".json",
      data: {
        study: number;
        block_type: "TEXT" | "ANALYSIS" | "BENCHMARK";
        order: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<FullStudyBlock, any>({
        path: `/testsuite/api/create-study-block${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiCreateSubsetCreate
     * @request POST:/testsuite/api/create-subset/{dataset_id}/
     * @secure
     */
    testsuiteApiCreateSubsetCreate: (datasetId: string, data: SubsetCreation, params: RequestParams = {}) =>
      this.request<SubsetCreation, any>({
        path: `/testsuite/api/create-subset/${datasetId}/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiCreateSubsetFormattedCreate
     * @request POST:/testsuite/api/create-subset/{dataset_id}{format}
     * @secure
     */
    testsuiteApiCreateSubsetFormattedCreate: (
      datasetId: string,
      format: ".json",
      data: SubsetCreation,
      params: RequestParams = {},
    ) =>
      this.request<SubsetCreation, any>({
        path: `/testsuite/api/create-subset/${datasetId}${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiDeleteAnalysisDestroy
     * @request DELETE:/testsuite/api/delete-analysis/{analysis_id}/
     * @secure
     */
    testsuiteApiDeleteAnalysisDestroy: (analysisId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/testsuite/api/delete-analysis/${analysisId}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiDeleteAnalysisFormattedDestroy
     * @request DELETE:/testsuite/api/delete-analysis/{analysis_id}{format}
     * @secure
     */
    testsuiteApiDeleteAnalysisFormattedDestroy: (analysisId: string, format: ".json", params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/testsuite/api/delete-analysis/${analysisId}${format}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiDeleteDatasetDestroy
     * @request DELETE:/testsuite/api/delete-dataset/{dataset_id}/
     * @secure
     */
    testsuiteApiDeleteDatasetDestroy: (datasetId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/testsuite/api/delete-dataset/${datasetId}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiDeleteDatasetFormattedDestroy
     * @request DELETE:/testsuite/api/delete-dataset/{dataset_id}{format}
     * @secure
     */
    testsuiteApiDeleteDatasetFormattedDestroy: (datasetId: string, format: ".json", params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/testsuite/api/delete-dataset/${datasetId}${format}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiDeleteExperimentDestroy
     * @request DELETE:/testsuite/api/delete-experiment/{experiment_id}/
     * @secure
     */
    testsuiteApiDeleteExperimentDestroy: (experimentId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/testsuite/api/delete-experiment/${experimentId}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiDeleteExperimentFormattedDestroy
     * @request DELETE:/testsuite/api/delete-experiment/{experiment_id}{format}
     * @secure
     */
    testsuiteApiDeleteExperimentFormattedDestroy: (experimentId: string, format: ".json", params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/testsuite/api/delete-experiment/${experimentId}${format}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiDeleteOperationDestroy
     * @request DELETE:/testsuite/api/delete-operation/{operation_id}/
     * @secure
     */
    testsuiteApiDeleteOperationDestroy: (operationId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/testsuite/api/delete-operation/${operationId}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiDeleteOperationFormattedDestroy
     * @request DELETE:/testsuite/api/delete-operation/{operation_id}{format}
     * @secure
     */
    testsuiteApiDeleteOperationFormattedDestroy: (format: ".json", operationId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/testsuite/api/delete-operation/${operationId}${format}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiDeletePromptTemplateDestroy
     * @request DELETE:/testsuite/api/delete-prompt-template/{prompt_template_id}/
     * @secure
     */
    testsuiteApiDeletePromptTemplateDestroy: (promptTemplateId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/testsuite/api/delete-prompt-template/${promptTemplateId}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiDeletePromptTemplateFormattedDestroy
     * @request DELETE:/testsuite/api/delete-prompt-template/{prompt_template_id}{format}
     * @secure
     */
    testsuiteApiDeletePromptTemplateFormattedDestroy: (
      format: ".json",
      promptTemplateId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/testsuite/api/delete-prompt-template/${promptTemplateId}${format}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiDeleteStudyBlockDestroy
     * @request DELETE:/testsuite/api/delete-study-block/{study_block_id}/
     * @secure
     */
    testsuiteApiDeleteStudyBlockDestroy: (studyBlockId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/testsuite/api/delete-study-block/${studyBlockId}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiDeleteStudyBlockFormattedDestroy
     * @request DELETE:/testsuite/api/delete-study-block/{study_block_id}{format}
     * @secure
     */
    testsuiteApiDeleteStudyBlockFormattedDestroy: (format: ".json", studyBlockId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/testsuite/api/delete-study-block/${studyBlockId}${format}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Duplicate a dataset with the provided details.
     *
     * @tags testsuite
     * @name TestsuiteApiDuplicateDatasetCreate
     * @request POST:/testsuite/api/duplicate-dataset/{dataset_id}/
     * @secure
     */
    testsuiteApiDuplicateDatasetCreate: (
      datasetId: string,
      data: {
        name?: string;
        description?: string;
        columns?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<Dataset, any>({
        path: `/testsuite/api/duplicate-dataset/${datasetId}/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Duplicate a dataset with the provided details.
     *
     * @tags testsuite
     * @name TestsuiteApiDuplicateDatasetFormattedCreate
     * @request POST:/testsuite/api/duplicate-dataset/{dataset_id}{format}
     * @secure
     */
    testsuiteApiDuplicateDatasetFormattedCreate: (
      datasetId: string,
      format: ".json",
      data: {
        name?: string;
        description?: string;
        columns?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<Dataset, any>({
        path: `/testsuite/api/duplicate-dataset/${datasetId}${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiExportDatasetRetrieve
     * @request GET:/testsuite/api/export-dataset/{dataset_id}/
     * @secure
     */
    testsuiteApiExportDatasetRetrieve: (datasetId: string, params: RequestParams = {}) =>
      this.request<ExportDataset, any>({
        path: `/testsuite/api/export-dataset/${datasetId}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiExportDatasetFormattedRetrieve
     * @request GET:/testsuite/api/export-dataset/{dataset_id}{format}
     * @secure
     */
    testsuiteApiExportDatasetFormattedRetrieve: (datasetId: string, format: ".json", params: RequestParams = {}) =>
      this.request<ExportDataset, any>({
        path: `/testsuite/api/export-dataset/${datasetId}${format}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiExportExperimentRetrieve
     * @request GET:/testsuite/api/export-experiment/{experiment_id}/
     * @secure
     */
    testsuiteApiExportExperimentRetrieve: (experimentId: string, params: RequestParams = {}) =>
      this.request<ExportExperiment, any>({
        path: `/testsuite/api/export-experiment/${experimentId}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiExportExperimentFormattedRetrieve
     * @request GET:/testsuite/api/export-experiment/{experiment_id}{format}
     * @secure
     */
    testsuiteApiExportExperimentFormattedRetrieve: (
      experimentId: string,
      format: ".json",
      params: RequestParams = {},
    ) =>
      this.request<ExportExperiment, any>({
        path: `/testsuite/api/export-experiment/${experimentId}${format}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetAnalysisRegistryRetrieve
     * @request GET:/testsuite/api/get-analysis-registry/
     * @secure
     */
    testsuiteApiGetAnalysisRegistryRetrieve: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/testsuite/api/get-analysis-registry/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetAnalysisRegistryFormattedRetrieve
     * @request GET:/testsuite/api/get-analysis-registry{format}
     * @secure
     */
    testsuiteApiGetAnalysisRegistryFormattedRetrieve: (format: ".json", params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/testsuite/api/get-analysis-registry${format}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetAnalysisRetrieve
     * @request GET:/testsuite/api/get-analysis/{analysis_id}/
     * @secure
     */
    testsuiteApiGetAnalysisRetrieve: (analysisId: string, params: RequestParams = {}) =>
      this.request<FullAnalysis, any>({
        path: `/testsuite/api/get-analysis/${analysisId}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetAnalysisFormattedRetrieve
     * @request GET:/testsuite/api/get-analysis/{analysis_id}{format}
     * @secure
     */
    testsuiteApiGetAnalysisFormattedRetrieve: (analysisId: string, format: ".json", params: RequestParams = {}) =>
      this.request<FullAnalysis, any>({
        path: `/testsuite/api/get-analysis/${analysisId}${format}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetCopilotSuggestionCreate
     * @request POST:/testsuite/api/get-copilot-suggestion/
     * @secure
     */
    testsuiteApiGetCopilotSuggestionCreate: (data: CopilotSuggestion, params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/testsuite/api/get-copilot-suggestion/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetCopilotSuggestionFormattedCreate
     * @request POST:/testsuite/api/get-copilot-suggestion{format}
     * @secure
     */
    testsuiteApiGetCopilotSuggestionFormattedCreate: (
      format: ".json",
      data: CopilotSuggestion,
      params: RequestParams = {},
    ) =>
      this.request<Record<string, any>, any>({
        path: `/testsuite/api/get-copilot-suggestion${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetDatasetDatumRetrieve
     * @request GET:/testsuite/api/get-dataset-datum/{dataset_id}/
     * @secure
     */
    testsuiteApiGetDatasetDatumRetrieve: (datasetId: string, params: RequestParams = {}) =>
      this.request<Datum, any>({
        path: `/testsuite/api/get-dataset-datum/${datasetId}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetDatasetDatumFormattedRetrieve
     * @request GET:/testsuite/api/get-dataset-datum/{dataset_id}{format}
     * @secure
     */
    testsuiteApiGetDatasetDatumFormattedRetrieve: (datasetId: string, format: ".json", params: RequestParams = {}) =>
      this.request<Datum, any>({
        path: `/testsuite/api/get-dataset-datum/${datasetId}${format}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetDatasetSubsetsRetrieve
     * @request GET:/testsuite/api/get-dataset-subsets/{dataset_id}/
     * @secure
     */
    testsuiteApiGetDatasetSubsetsRetrieve: (datasetId: string, params: RequestParams = {}) =>
      this.request<Dataset[], any>({
        path: `/testsuite/api/get-dataset-subsets/${datasetId}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetDatasetSubsetsFormattedRetrieve
     * @request GET:/testsuite/api/get-dataset-subsets/{dataset_id}{format}
     * @secure
     */
    testsuiteApiGetDatasetSubsetsFormattedRetrieve: (datasetId: string, format: ".json", params: RequestParams = {}) =>
      this.request<Dataset[], any>({
        path: `/testsuite/api/get-dataset-subsets/${datasetId}${format}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetDatasetRetrieve
     * @request GET:/testsuite/api/get-dataset/{dataset_id}/
     * @secure
     */
    testsuiteApiGetDatasetRetrieve: (datasetId: string, params: RequestParams = {}) =>
      this.request<Dataset, any>({
        path: `/testsuite/api/get-dataset/${datasetId}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetDatasetFormattedRetrieve
     * @request GET:/testsuite/api/get-dataset/{dataset_id}{format}
     * @secure
     */
    testsuiteApiGetDatasetFormattedRetrieve: (datasetId: string, format: ".json", params: RequestParams = {}) =>
      this.request<Dataset, any>({
        path: `/testsuite/api/get-dataset/${datasetId}${format}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetDatumRetrieve
     * @request GET:/testsuite/api/get-datum/{datum_id}/
     * @secure
     */
    testsuiteApiGetDatumRetrieve: (datumId: string, params: RequestParams = {}) =>
      this.request<Datum, any>({
        path: `/testsuite/api/get-datum/${datumId}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetDatumFormattedRetrieve
     * @request GET:/testsuite/api/get-datum/{datum_id}{format}
     * @secure
     */
    testsuiteApiGetDatumFormattedRetrieve: (datumId: string, format: ".json", params: RequestParams = {}) =>
      this.request<Datum, any>({
        path: `/testsuite/api/get-datum/${datumId}${format}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetExecutionRegistryRetrieve
     * @request GET:/testsuite/api/get-execution-registry/
     * @secure
     */
    testsuiteApiGetExecutionRegistryRetrieve: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/testsuite/api/get-execution-registry/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetExecutionRegistryFormattedRetrieve
     * @request GET:/testsuite/api/get-execution-registry{format}
     * @secure
     */
    testsuiteApiGetExecutionRegistryFormattedRetrieve: (format: ".json", params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/testsuite/api/get-execution-registry${format}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetExperimentRetrieve
     * @request GET:/testsuite/api/get-experiment/{experiment_id}/
     * @secure
     */
    testsuiteApiGetExperimentRetrieve: (experimentId: string, params: RequestParams = {}) =>
      this.request<Experiment, any>({
        path: `/testsuite/api/get-experiment/${experimentId}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetExperimentFormattedRetrieve
     * @request GET:/testsuite/api/get-experiment/{experiment_id}{format}
     * @secure
     */
    testsuiteApiGetExperimentFormattedRetrieve: (experimentId: string, format: ".json", params: RequestParams = {}) =>
      this.request<Experiment, any>({
        path: `/testsuite/api/get-experiment/${experimentId}${format}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetFullExperimentRetrieve
     * @request GET:/testsuite/api/get-full-experiment/{experiment_id}/
     * @secure
     */
    testsuiteApiGetFullExperimentRetrieve: (experimentId: string, params: RequestParams = {}) =>
      this.request<FullExperiment, any>({
        path: `/testsuite/api/get-full-experiment/${experimentId}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetFullExperimentFormattedRetrieve
     * @request GET:/testsuite/api/get-full-experiment/{experiment_id}{format}
     * @secure
     */
    testsuiteApiGetFullExperimentFormattedRetrieve: (
      experimentId: string,
      format: ".json",
      params: RequestParams = {},
    ) =>
      this.request<FullExperiment, any>({
        path: `/testsuite/api/get-full-experiment/${experimentId}${format}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetFullOperationRetrieve
     * @request GET:/testsuite/api/get-full-operation/{operation_id}/
     * @secure
     */
    testsuiteApiGetFullOperationRetrieve: (operationId: string, params: RequestParams = {}) =>
      this.request<FullOperation, any>({
        path: `/testsuite/api/get-full-operation/${operationId}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetFullOperationFormattedRetrieve
     * @request GET:/testsuite/api/get-full-operation/{operation_id}{format}
     * @secure
     */
    testsuiteApiGetFullOperationFormattedRetrieve: (format: ".json", operationId: string, params: RequestParams = {}) =>
      this.request<FullOperation, any>({
        path: `/testsuite/api/get-full-operation/${operationId}${format}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetOperationRetrieve
     * @request GET:/testsuite/api/get-operation/{operation_id}/
     * @secure
     */
    testsuiteApiGetOperationRetrieve: (operationId: string, params: RequestParams = {}) =>
      this.request<Operation, any>({
        path: `/testsuite/api/get-operation/${operationId}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetOperationFormattedRetrieve
     * @request GET:/testsuite/api/get-operation/{operation_id}{format}
     * @secure
     */
    testsuiteApiGetOperationFormattedRetrieve: (format: ".json", operationId: string, params: RequestParams = {}) =>
      this.request<Operation, any>({
        path: `/testsuite/api/get-operation/${operationId}${format}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetPromptTemplateRetrieve
     * @request GET:/testsuite/api/get-prompt-template/{prompt_template_id}/
     * @secure
     */
    testsuiteApiGetPromptTemplateRetrieve: (promptTemplateId: string, params: RequestParams = {}) =>
      this.request<PromptTemplate, any>({
        path: `/testsuite/api/get-prompt-template/${promptTemplateId}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetPromptTemplateFormattedRetrieve
     * @request GET:/testsuite/api/get-prompt-template/{prompt_template_id}{format}
     * @secure
     */
    testsuiteApiGetPromptTemplateFormattedRetrieve: (
      format: ".json",
      promptTemplateId: string,
      params: RequestParams = {},
    ) =>
      this.request<PromptTemplate, any>({
        path: `/testsuite/api/get-prompt-template/${promptTemplateId}${format}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetSearchCollectionRetrieve
     * @request GET:/testsuite/api/get-search-collection/{search_collection_id}/
     * @secure
     */
    testsuiteApiGetSearchCollectionRetrieve: (searchCollectionId: string, params: RequestParams = {}) =>
      this.request<SearchCollection, any>({
        path: `/testsuite/api/get-search-collection/${searchCollectionId}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetSearchCollectionFormattedRetrieve
     * @request GET:/testsuite/api/get-search-collection/{search_collection_id}{format}
     * @secure
     */
    testsuiteApiGetSearchCollectionFormattedRetrieve: (
      format: ".json",
      searchCollectionId: string,
      params: RequestParams = {},
    ) =>
      this.request<SearchCollection, any>({
        path: `/testsuite/api/get-search-collection/${searchCollectionId}${format}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetStudyBlockRetrieve
     * @request GET:/testsuite/api/get-study-block/{study_block_id}/
     * @secure
     */
    testsuiteApiGetStudyBlockRetrieve: (studyBlockId: string, params: RequestParams = {}) =>
      this.request<FullStudyBlock, any>({
        path: `/testsuite/api/get-study-block/${studyBlockId}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetStudyBlockFormattedRetrieve
     * @request GET:/testsuite/api/get-study-block/{study_block_id}{format}
     * @secure
     */
    testsuiteApiGetStudyBlockFormattedRetrieve: (format: ".json", studyBlockId: string, params: RequestParams = {}) =>
      this.request<FullStudyBlock, any>({
        path: `/testsuite/api/get-study-block/${studyBlockId}${format}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetStudyRetrieve
     * @request GET:/testsuite/api/get-study/{study_id}/
     * @secure
     */
    testsuiteApiGetStudyRetrieve: (studyId: string, params: RequestParams = {}) =>
      this.request<FullStudy, any>({
        path: `/testsuite/api/get-study/${studyId}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetStudyFormattedRetrieve
     * @request GET:/testsuite/api/get-study/{study_id}{format}
     * @secure
     */
    testsuiteApiGetStudyFormattedRetrieve: (format: ".json", studyId: string, params: RequestParams = {}) =>
      this.request<FullStudy, any>({
        path: `/testsuite/api/get-study/${studyId}${format}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetUserDatasetsRetrieve
     * @request GET:/testsuite/api/get-user-datasets/
     * @secure
     */
    testsuiteApiGetUserDatasetsRetrieve: (params: RequestParams = {}) =>
      this.request<Dataset[], any>({
        path: `/testsuite/api/get-user-datasets/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetUserDatasetsFormattedRetrieve
     * @request GET:/testsuite/api/get-user-datasets{format}
     * @secure
     */
    testsuiteApiGetUserDatasetsFormattedRetrieve: (format: ".json", params: RequestParams = {}) =>
      this.request<Dataset[], any>({
        path: `/testsuite/api/get-user-datasets${format}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetUserExperimentsRetrieve
     * @request GET:/testsuite/api/get-user-experiments/
     * @secure
     */
    testsuiteApiGetUserExperimentsRetrieve: (params: RequestParams = {}) =>
      this.request<Experiment[], any>({
        path: `/testsuite/api/get-user-experiments/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetUserExperimentsFormattedRetrieve
     * @request GET:/testsuite/api/get-user-experiments{format}
     * @secure
     */
    testsuiteApiGetUserExperimentsFormattedRetrieve: (format: ".json", params: RequestParams = {}) =>
      this.request<Experiment[], any>({
        path: `/testsuite/api/get-user-experiments${format}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetUserFullExperimentsRetrieve
     * @request GET:/testsuite/api/get-user-full-experiments/
     * @secure
     */
    testsuiteApiGetUserFullExperimentsRetrieve: (params: RequestParams = {}) =>
      this.request<FullExperiment[], any>({
        path: `/testsuite/api/get-user-full-experiments/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetUserFullExperimentsFormattedRetrieve
     * @request GET:/testsuite/api/get-user-full-experiments{format}
     * @secure
     */
    testsuiteApiGetUserFullExperimentsFormattedRetrieve: (format: ".json", params: RequestParams = {}) =>
      this.request<FullExperiment[], any>({
        path: `/testsuite/api/get-user-full-experiments${format}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetUserOperationsRetrieve
     * @request GET:/testsuite/api/get-user-operations/
     * @secure
     */
    testsuiteApiGetUserOperationsRetrieve: (params: RequestParams = {}) =>
      this.request<Operation, any>({
        path: `/testsuite/api/get-user-operations/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetUserOperationsFormattedRetrieve
     * @request GET:/testsuite/api/get-user-operations{format}
     * @secure
     */
    testsuiteApiGetUserOperationsFormattedRetrieve: (format: ".json", params: RequestParams = {}) =>
      this.request<Operation, any>({
        path: `/testsuite/api/get-user-operations${format}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetUserPromptTemplatesRetrieve
     * @request GET:/testsuite/api/get-user-prompt-templates/
     * @secure
     */
    testsuiteApiGetUserPromptTemplatesRetrieve: (params: RequestParams = {}) =>
      this.request<PromptTemplate[], any>({
        path: `/testsuite/api/get-user-prompt-templates/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetUserPromptTemplatesFormattedRetrieve
     * @request GET:/testsuite/api/get-user-prompt-templates{format}
     * @secure
     */
    testsuiteApiGetUserPromptTemplatesFormattedRetrieve: (format: ".json", params: RequestParams = {}) =>
      this.request<PromptTemplate[], any>({
        path: `/testsuite/api/get-user-prompt-templates${format}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetUserStudiesRetrieve
     * @request GET:/testsuite/api/get-user-studies/
     * @secure
     */
    testsuiteApiGetUserStudiesRetrieve: (params: RequestParams = {}) =>
      this.request<FullStudy[], any>({
        path: `/testsuite/api/get-user-studies/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiGetUserStudiesFormattedRetrieve
     * @request GET:/testsuite/api/get-user-studies{format}
     * @secure
     */
    testsuiteApiGetUserStudiesFormattedRetrieve: (format: ".json", params: RequestParams = {}) =>
      this.request<FullStudy[], any>({
        path: `/testsuite/api/get-user-studies${format}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiIndexWithWeaviateCreate
     * @request POST:/testsuite/api/index-with-weaviate/
     * @secure
     */
    testsuiteApiIndexWithWeaviateCreate: (
      data: {
        dataset_id: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/testsuite/api/index-with-weaviate/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiIndexWithWeaviateFormattedCreate
     * @request POST:/testsuite/api/index-with-weaviate{format}
     * @secure
     */
    testsuiteApiIndexWithWeaviateFormattedCreate: (
      format: ".json",
      data: {
        dataset_id: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/testsuite/api/index-with-weaviate${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiNextOperationCreate
     * @request POST:/testsuite/api/next-operation/{operation_id}/
     * @secure
     */
    testsuiteApiNextOperationCreate: (operationId: string, data: object, params: RequestParams = {}) =>
      this.request<FullOperation, any>({
        path: `/testsuite/api/next-operation/${operationId}/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiNextOperationFormattedCreate
     * @request POST:/testsuite/api/next-operation/{operation_id}{format}
     * @secure
     */
    testsuiteApiNextOperationFormattedCreate: (
      format: ".json",
      operationId: string,
      data: object,
      params: RequestParams = {},
    ) =>
      this.request<FullOperation, any>({
        path: `/testsuite/api/next-operation/${operationId}${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiPreviewPromptTemplateCreate
     * @request POST:/testsuite/api/preview-prompt-template/
     * @secure
     */
    testsuiteApiPreviewPromptTemplateCreate: (
      data: {
        prompt: string;
        dataset_id: number;
        type: string;
        output_type: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/testsuite/api/preview-prompt-template/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiPreviewPromptTemplateFormattedCreate
     * @request POST:/testsuite/api/preview-prompt-template{format}
     * @secure
     */
    testsuiteApiPreviewPromptTemplateFormattedCreate: (
      format: ".json",
      data: {
        prompt: string;
        dataset_id: number;
        type: string;
        output_type: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/testsuite/api/preview-prompt-template${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiRedownloadAllLlmJobsCreate
     * @request POST:/testsuite/api/redownload-all-llm-jobs/
     * @secure
     */
    testsuiteApiRedownloadAllLlmJobsCreate: (params: RequestParams = {}) =>
      this.request<LLMJob[], any>({
        path: `/testsuite/api/redownload-all-llm-jobs/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiRedownloadAllLlmJobsFormattedCreate
     * @request POST:/testsuite/api/redownload-all-llm-jobs{format}
     * @secure
     */
    testsuiteApiRedownloadAllLlmJobsFormattedCreate: (format: ".json", params: RequestParams = {}) =>
      this.request<LLMJob[], any>({
        path: `/testsuite/api/redownload-all-llm-jobs${format}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiRedownloadLlmJobCreate
     * @request POST:/testsuite/api/redownload-llm-job/
     * @secure
     */
    testsuiteApiRedownloadLlmJobCreate: (data: RedownloadBatchLLMJob, params: RequestParams = {}) =>
      this.request<LLMJob, any>({
        path: `/testsuite/api/redownload-llm-job/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiRedownloadLlmJobFormattedCreate
     * @request POST:/testsuite/api/redownload-llm-job{format}
     * @secure
     */
    testsuiteApiRedownloadLlmJobFormattedCreate: (
      format: ".json",
      data: RedownloadBatchLLMJob,
      params: RequestParams = {},
    ) =>
      this.request<LLMJob, any>({
        path: `/testsuite/api/redownload-llm-job${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiRetryLlmJobCreate
     * @request POST:/testsuite/api/retry-llm-job/
     * @secure
     */
    testsuiteApiRetryLlmJobCreate: (data: RetryLLMJob, params: RequestParams = {}) =>
      this.request<LLMJob, any>({
        path: `/testsuite/api/retry-llm-job/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiRetryLlmJobFormattedCreate
     * @request POST:/testsuite/api/retry-llm-job{format}
     * @secure
     */
    testsuiteApiRetryLlmJobFormattedCreate: (format: ".json", data: RetryLLMJob, params: RequestParams = {}) =>
      this.request<LLMJob, any>({
        path: `/testsuite/api/retry-llm-job${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiRunAnalysisCreate
     * @request POST:/testsuite/api/run-analysis/{analysis_id}/
     * @secure
     */
    testsuiteApiRunAnalysisCreate: (analysisId: string, data: object, params: RequestParams = {}) =>
      this.request<FullAnalysis, any>({
        path: `/testsuite/api/run-analysis/${analysisId}/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiRunAnalysisFormattedCreate
     * @request POST:/testsuite/api/run-analysis/{analysis_id}{format}
     * @secure
     */
    testsuiteApiRunAnalysisFormattedCreate: (
      analysisId: string,
      format: ".json",
      data: object,
      params: RequestParams = {},
    ) =>
      this.request<FullAnalysis, any>({
        path: `/testsuite/api/run-analysis/${analysisId}${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiRunFullExperimentCreate
     * @request POST:/testsuite/api/run-full-experiment/{experiment_id}/
     * @secure
     */
    testsuiteApiRunFullExperimentCreate: (
      experimentId: string,
      data: {
        params?: object;
      },
      params: RequestParams = {},
    ) =>
      this.request<FullExperiment, any>({
        path: `/testsuite/api/run-full-experiment/${experimentId}/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiRunFullExperimentFormattedCreate
     * @request POST:/testsuite/api/run-full-experiment/{experiment_id}{format}
     * @secure
     */
    testsuiteApiRunFullExperimentFormattedCreate: (
      experimentId: string,
      format: ".json",
      data: {
        params?: object;
      },
      params: RequestParams = {},
    ) =>
      this.request<FullExperiment, any>({
        path: `/testsuite/api/run-full-experiment/${experimentId}${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiRunOperationCreate
     * @request POST:/testsuite/api/run-operation/{operation_id}/
     * @secure
     */
    testsuiteApiRunOperationCreate: (operationId: string, data: FullOperation, params: RequestParams = {}) =>
      this.request<FullOperation, any>({
        path: `/testsuite/api/run-operation/${operationId}/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiRunOperationFormattedCreate
     * @request POST:/testsuite/api/run-operation/{operation_id}{format}
     * @secure
     */
    testsuiteApiRunOperationFormattedCreate: (
      format: ".json",
      operationId: string,
      data: FullOperation,
      params: RequestParams = {},
    ) =>
      this.request<FullOperation, any>({
        path: `/testsuite/api/run-operation/${operationId}${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiRunPythonSandboxForDatasetCreate
     * @request POST:/testsuite/api/run-python-sandbox-for-dataset/
     * @secure
     */
    testsuiteApiRunPythonSandboxForDatasetCreate: (data: PythonSandboxForDatasets, params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/testsuite/api/run-python-sandbox-for-dataset/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiRunPythonSandboxForDatasetFormattedCreate
     * @request POST:/testsuite/api/run-python-sandbox-for-dataset{format}
     * @secure
     */
    testsuiteApiRunPythonSandboxForDatasetFormattedCreate: (
      format: ".json",
      data: PythonSandboxForDatasets,
      params: RequestParams = {},
    ) =>
      this.request<Record<string, any>, any>({
        path: `/testsuite/api/run-python-sandbox-for-dataset${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiSearchCreate
     * @request POST:/testsuite/api/search/
     * @secure
     */
    testsuiteApiSearchCreate: (data: Search, params: RequestParams = {}) =>
      this.request<SearchResult, any>({
        path: `/testsuite/api/search/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiSearchFormattedCreate
     * @request POST:/testsuite/api/search{format}
     * @secure
     */
    testsuiteApiSearchFormattedCreate: (format: ".json", data: Search, params: RequestParams = {}) =>
      this.request<SearchResult, any>({
        path: `/testsuite/api/search${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiSemanticSearchCreate
     * @request POST:/testsuite/api/semantic-search/
     * @secure
     */
    testsuiteApiSemanticSearchCreate: (
      data: {
        query: string;
        limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<Datum[], any>({
        path: `/testsuite/api/semantic-search/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiSemanticSearchFormattedCreate
     * @request POST:/testsuite/api/semantic-search{format}
     * @secure
     */
    testsuiteApiSemanticSearchFormattedCreate: (
      format: ".json",
      data: {
        query: string;
        limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<Datum[], any>({
        path: `/testsuite/api/semantic-search${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiTestRenderPromptCreate
     * @request POST:/testsuite/api/test-render-prompt/
     * @secure
     */
    testsuiteApiTestRenderPromptCreate: (
      data: {
        prompt: string;
        datum: object;
        type: "SYSTEM" | "USER";
        output_type: "TEXT" | "JSON";
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/testsuite/api/test-render-prompt/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiTestRenderPromptFormattedCreate
     * @request POST:/testsuite/api/test-render-prompt{format}
     * @secure
     */
    testsuiteApiTestRenderPromptFormattedCreate: (
      format: ".json",
      data: {
        prompt: string;
        datum: object;
        type: "SYSTEM" | "USER";
        output_type: "TEXT" | "JSON";
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/testsuite/api/test-render-prompt${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiTryToRepairExperimentCreate
     * @request POST:/testsuite/api/try-to-repair-experiment/{experiment_id}/
     * @secure
     */
    testsuiteApiTryToRepairExperimentCreate: (
      experimentId: string,
      data: {
        params?: object;
      },
      params: RequestParams = {},
    ) =>
      this.request<FullExperiment, any>({
        path: `/testsuite/api/try-to-repair-experiment/${experimentId}/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiTryToRepairExperimentFormattedCreate
     * @request POST:/testsuite/api/try-to-repair-experiment/{experiment_id}{format}
     * @secure
     */
    testsuiteApiTryToRepairExperimentFormattedCreate: (
      experimentId: string,
      format: ".json",
      data: {
        params?: object;
      },
      params: RequestParams = {},
    ) =>
      this.request<FullExperiment, any>({
        path: `/testsuite/api/try-to-repair-experiment/${experimentId}${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiTryToRepairOperationCreate
     * @request POST:/testsuite/api/try-to-repair-operation/{operation_id}/
     * @secure
     */
    testsuiteApiTryToRepairOperationCreate: (operationId: string, data: FullOperation, params: RequestParams = {}) =>
      this.request<FullOperation, any>({
        path: `/testsuite/api/try-to-repair-operation/${operationId}/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiTryToRepairOperationFormattedCreate
     * @request POST:/testsuite/api/try-to-repair-operation/{operation_id}{format}
     * @secure
     */
    testsuiteApiTryToRepairOperationFormattedCreate: (
      format: ".json",
      operationId: string,
      data: FullOperation,
      params: RequestParams = {},
    ) =>
      this.request<FullOperation, any>({
        path: `/testsuite/api/try-to-repair-operation/${operationId}${format}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiUpdateAnalysisUpdate
     * @request PUT:/testsuite/api/update-analysis/{analysis_id}/
     * @secure
     */
    testsuiteApiUpdateAnalysisUpdate: (
      analysisId: string,
      data: {
        name?: string;
        description?: string;
        params?: object;
        input_datasets?: any[];
      },
      params: RequestParams = {},
    ) =>
      this.request<FullAnalysis, any>({
        path: `/testsuite/api/update-analysis/${analysisId}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiUpdateAnalysisFormattedUpdate
     * @request PUT:/testsuite/api/update-analysis/{analysis_id}{format}
     * @secure
     */
    testsuiteApiUpdateAnalysisFormattedUpdate: (
      analysisId: string,
      format: ".json",
      data: {
        name?: string;
        description?: string;
        params?: object;
        input_datasets?: any[];
      },
      params: RequestParams = {},
    ) =>
      this.request<FullAnalysis, any>({
        path: `/testsuite/api/update-analysis/${analysisId}${format}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiUpdateDatasetInfoUpdate
     * @request PUT:/testsuite/api/update-dataset-info/{dataset_id}/
     * @secure
     */
    testsuiteApiUpdateDatasetInfoUpdate: (datasetId: string, data: DatasetUpdate, params: RequestParams = {}) =>
      this.request<DatasetUpdate, any>({
        path: `/testsuite/api/update-dataset-info/${datasetId}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiUpdateDatasetInfoFormattedUpdate
     * @request PUT:/testsuite/api/update-dataset-info/{dataset_id}{format}
     * @secure
     */
    testsuiteApiUpdateDatasetInfoFormattedUpdate: (
      datasetId: string,
      format: ".json",
      data: DatasetUpdate,
      params: RequestParams = {},
    ) =>
      this.request<DatasetUpdate, any>({
        path: `/testsuite/api/update-dataset-info/${datasetId}${format}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Update an existing experiment with the provided details.
     *
     * @tags testsuite
     * @name TestsuiteApiUpdateExperimentUpdate
     * @request PUT:/testsuite/api/update-experiment/{experiment_id}/
     * @secure
     */
    testsuiteApiUpdateExperimentUpdate: (
      experimentId: string,
      data: {
        name: string;
        description: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<FullExperiment, any>({
        path: `/testsuite/api/update-experiment/${experimentId}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Update an existing experiment with the provided details.
     *
     * @tags testsuite
     * @name TestsuiteApiUpdateExperimentFormattedUpdate
     * @request PUT:/testsuite/api/update-experiment/{experiment_id}{format}
     * @secure
     */
    testsuiteApiUpdateExperimentFormattedUpdate: (
      experimentId: string,
      format: ".json",
      data: {
        name: string;
        description: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<FullExperiment, any>({
        path: `/testsuite/api/update-experiment/${experimentId}${format}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Update an existing operation with the provided details.
     *
     * @tags testsuite
     * @name TestsuiteApiUpdateOperationUpdate
     * @request PUT:/testsuite/api/update-operation/{operation_id}/
     * @secure
     */
    testsuiteApiUpdateOperationUpdate: (operationId: string, data: Operation, params: RequestParams = {}) =>
      this.request<FullOperation, void>({
        path: `/testsuite/api/update-operation/${operationId}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Update an existing operation with the provided details.
     *
     * @tags testsuite
     * @name TestsuiteApiUpdateOperationFormattedUpdate
     * @request PUT:/testsuite/api/update-operation/{operation_id}{format}
     * @secure
     */
    testsuiteApiUpdateOperationFormattedUpdate: (
      format: ".json",
      operationId: string,
      data: Operation,
      params: RequestParams = {},
    ) =>
      this.request<FullOperation, void>({
        path: `/testsuite/api/update-operation/${operationId}${format}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Update an existing prompt template with the provided details.
     *
     * @tags testsuite
     * @name TestsuiteApiUpdatePromptTemplateUpdate
     * @request PUT:/testsuite/api/update-prompt-template/{prompt_template_id}/
     * @secure
     */
    testsuiteApiUpdatePromptTemplateUpdate: (
      promptTemplateId: string,
      data: PromptTemplate,
      params: RequestParams = {},
    ) =>
      this.request<FullPromptTemplate, void>({
        path: `/testsuite/api/update-prompt-template/${promptTemplateId}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Update an existing prompt template with the provided details.
     *
     * @tags testsuite
     * @name TestsuiteApiUpdatePromptTemplateFormattedUpdate
     * @request PUT:/testsuite/api/update-prompt-template/{prompt_template_id}{format}
     * @secure
     */
    testsuiteApiUpdatePromptTemplateFormattedUpdate: (
      format: ".json",
      promptTemplateId: string,
      data: PromptTemplate,
      params: RequestParams = {},
    ) =>
      this.request<FullPromptTemplate, void>({
        path: `/testsuite/api/update-prompt-template/${promptTemplateId}${format}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiUpdateStudyBlockUpdate
     * @request PUT:/testsuite/api/update-study-block/{study_block_id}/
     * @secure
     */
    testsuiteApiUpdateStudyBlockUpdate: (
      studyBlockId: string,
      data: {
        order?: number;
        content?: string;
        analysis?: number;
        experiment?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<FullStudyBlock, any>({
        path: `/testsuite/api/update-study-block/${studyBlockId}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiUpdateStudyBlockFormattedUpdate
     * @request PUT:/testsuite/api/update-study-block/{study_block_id}{format}
     * @secure
     */
    testsuiteApiUpdateStudyBlockFormattedUpdate: (
      format: ".json",
      studyBlockId: string,
      data: {
        order?: number;
        content?: string;
        analysis?: number;
        experiment?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<FullStudyBlock, any>({
        path: `/testsuite/api/update-study-block/${studyBlockId}${format}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiUpdateStudyUpdate
     * @request PUT:/testsuite/api/update-study/{study_id}/
     * @secure
     */
    testsuiteApiUpdateStudyUpdate: (
      studyId: string,
      data: {
        name: string;
        description: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<FullStudy, any>({
        path: `/testsuite/api/update-study/${studyId}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags testsuite
     * @name TestsuiteApiUpdateStudyFormattedUpdate
     * @request PUT:/testsuite/api/update-study/{study_id}{format}
     * @secure
     */
    testsuiteApiUpdateStudyFormattedUpdate: (
      format: ".json",
      studyId: string,
      data: {
        name: string;
        description: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<FullStudy, any>({
        path: `/testsuite/api/update-study/${studyId}${format}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),
  };
  user = {
    /**
     * No description
     *
     * @tags user
     * @name UserRetrieve
     * @request GET:/user
     * @secure
     */
    userRetrieve: (params: RequestParams = {}) =>
      this.request<UserResponse, any>({
        path: `/user`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  verifyToken = {
    /**
     * No description
     *
     * @tags verify_token
     * @name VerifyTokenCreate
     * @request POST:/verify_token/
     * @secure
     */
    verifyTokenCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/verify_token/`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
}
