import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from './pages/Homepage';
import LoginPage from './pages/LoginPage';
import Datasets from './pages/Datasets';
import DatasetDetail from './pages/DatasetDetail';  
import Experiments from './pages/Experiments';
import ManageExperiment from './pages/ManageExperiment';
import Studies from './pages/Studies';
import ManageStudy from './pages/ManageStudy';
import ViewStudy from './pages/ViewStudy';
import Search from './pages/Search';
import Layout from './components/Layout';  // Import the Layout component
import { UserProvider } from '../hooks/useUser';
import { RequireLogin } from '../helper';
import { commonRoutes } from '../commonPages/CommonRoutes';

const TestSuiteIndex = () => {
  return (
    <React.StrictMode>
      <UserProvider mountingScreen={<></>}>
          <Router>
            <Layout>
              <Routes>
                <Route path="/login" element={<LoginPage/>} />
                <Route path="/" element={<RequireLogin><Homepage /></RequireLogin>} />
                <Route path="/datasets" element={<RequireLogin><Datasets /></RequireLogin   >} />
                <Route path="/experiments" element={<RequireLogin><Experiments /></RequireLogin>} />
                <Route path="/studies" element={<RequireLogin><Studies /></RequireLogin>} />
                <Route path="/dataset/:datasetId" element={<RequireLogin><DatasetDetail /></RequireLogin>} />
                <Route path="/manage-experiment/:experimentId" element={<RequireLogin><ManageExperiment /></RequireLogin>} />
                <Route path="/manage-study/:studyId" element={<RequireLogin><ManageStudy /></RequireLogin>} />
                <Route path="/view-study/:studyId" element={<RequireLogin><ViewStudy /></RequireLogin>} />
                <Route path="/search" element={<RequireLogin><Search /></RequireLogin>} />
                {commonRoutes}
                {/* Fallback route */}
                <Route path="*" element={<Homepage />} />
              </Routes>
            </Layout>
          </Router>
      </UserProvider>
    </React.StrictMode>
  );
};

export default TestSuiteIndex;