import React from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { useSaveStatus } from '../context/SaveStatusContext';
import { LoginButton } from '../../components/Login';
import { LogoutButton } from '../../components/LogoutButton';
import SignUpButton from '../../components/SignupButton';
import { useUser } from '../../hooks/useUser';
import SubscriptionButton from './SubscriptionButton';
import {cn} from "../../utils/cn"


import { useEffect } from "react";
import styled from '@emotion/styled';
import { IconMenu } from "@tabler/icons-react";

interface ApplicationNavbarProps {
  className?: string;
  openMenuClassName?: string;
}

const ApplicationNavbar: React.FC<ApplicationNavbarProps> = ({className, openMenuClassName = "bg-gray-800"}) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const user = useUser();
  const location = useLocation();
  const menuRef = React.useRef<HTMLDivElement>(null);
  const loggedIn = user.state === "LoggedIn";

  useEffect(() => {
    if (menuOpen) {
      setMenuOpen(false);
    }
  }, [location]);

  // Close sidebar when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        menuOpen &&
        menuRef.current &&
        !menuRef.current.contains(event.target as Node)
      ) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [menuOpen]);

  return (
      <MenuContainer menuOpen={menuOpen} ref={menuRef} className={cn("flex text-white bg-transparent transition-colors duration-700 flex-col absolute top-0 pt-16 lg:pt-3 px-4 w-full lg:px-8 z-10", menuOpen ? openMenuClassName : "", className)}>
        {/* Logo positioned on the left side */}
        <Link to="/" className="absolute left-5 top-3 lg:top-5 text-2xl font-bold z-[101]">
          <img src="/assets/feistyforms/logo_nofont_white.png" alt="Logo" className="h-10 inline-block mr-2" />
          FeistyForms
        </Link>

        {/* Menu icon positioned on the right side */}
        <MenuIcon onClick={() => setMenuOpen(menuOpen => !menuOpen)} className="absolute right-4 top-4 lg:hidden stroke-current" />
  
        <MenuLinks className="flex flex-col gap-4 h-full lg:w-full lg:justify-between lg:flex-row lg:items-center" menuOpen={menuOpen}>
          <div className="flex flex-col gap-4 md:flex-row">
            {/* Other links or content */}
          </div>
        <div className="flex flex-col gap-4 md:flex-row md:items-center">
          <Link className="text-center shadow-[0_0_0_1px_#fff_inset] text-white px-6 py-2 bg-transparent border border-black text-black rounded-lg font-bold transform hover:-translate-y-1 transition duration-400" to="mailto:thoughtfuloasis@gmail.com?subject=Bug&body=Hi%20Kaspar%2C%0AI%20am%20using%20FeistyForms%20and%20I%20found%20a%20Bug%3A%0A%0A">Report Bug</Link>
          <Link className="text-center shadow-[0_0_0_1px_#fff_inset] text-white px-6 py-2 bg-transparent border border-black text-black rounded-lg font-bold transform hover:-translate-y-1 transition duration-400" to="/fill-form/f030a9bd-45c9-45df-9b35-1d423609c6f5">Give Feedback</Link>
          {loggedIn &&
            <div className="flex flex-col gap-4 md:w-full md:flex-row">
                <Link className="text-center shadow-[0_0_0_1px_#fff_inset] text-white px-6 py-2 bg-transparent border border-black text-black rounded-lg font-bold transform hover:-translate-y-1 transition duration-400" to="/settings">Account</Link>
                <SubscriptionButton className="shadow-[0_0_0_1px_#fff_inset] text-white" />
                <LogoutButton className="shadow-[0_0_0_1px_#fff_inset] text-white"/>
              </div>}
          {!loggedIn && <>
              <SignUpButton className="shadow-[0_0_0_1px_#fff_inset] text-white"/>
              <LoginButton className="shadow-[0_0_0_1px_#fff_inset] text-white"/>
          </>}
        </div>
      </MenuLinks>
    </MenuContainer>
  );
}

const MenuLinks = styled.nav<{ menuOpen: boolean }>`
  display: flex;
  flex-direction: column;
  z-index: 100;
  transition: max-height .5s ease-in-out, opacity .3s ease-in-out;
  text-wrap: nowrap;

  ${props => props.menuOpen && `margin-bottom: 16px;`}

  @media (max-width: 1023px) { 
    ${props => props.menuOpen ? "max-height: 300px; opacity: 1;" : "max-height: 0; opacity: 0;"}
    pointer-events: ${props => props.menuOpen ? 'auto' : 'none'};
  }
  
  overflow: hidden;
  @media (min-width: 1024px) {
    height: 55px;
    width: 100%;
    flex-direction: row;
    margin: 0px;
    opacity: 1;
    pointer-events: auto;
  }
`

const MenuIcon = styled(IconMenu)`
  width: fit-content;
  height: 48px;
  width: 48px;
  padding-bottom: 16px;
`

const MenuContainer = styled.div<{ menuOpen: boolean }>`
  @media (min-width: 1024px) {
    background: none;
  }
`
export default ApplicationNavbar;
