// Fetch a study and display it

// for each study block:
// -> if it is a text block render the content as markdwon using react markdown
// -> if it is an analysis, render the analysis name and description and the ResultCard but without the content scrolling inside the card, it should just make the page longer
// -> if it is a benchmark, render the benchmark name and the analysis result card of the benchmark blocks analysis. (a benchmark block has an expeiment and an analysis...)


// The pagge should  also have an export button which expoert the study as a pdf

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { IconDownload } from "@tabler/icons-react";
import { useOasisBackend } from '../../hooks/useOasisBackend';
import { FullStudy, FullStudyBlock, BlockTypeEnum } from '../../api/OasisBackendApi';
import AnalysisResultsCard from '../components/Cards/AnalysisResultsCard';
import { FullPageLoader } from '../components/loader';
import toPDF from 'react-to-pdf';

const ViewStudy: React.FC = () => {
  const { studyId } = useParams<{ studyId: string }>();
  const [study, setStudy] = useState<FullStudy | null>(null);
  const [loading, setLoading] = useState(true);
  const db = useOasisBackend();

  useEffect(() => {
    const fetchStudy = async () => {
      if (!studyId) return;
      try {
        const response = await db.endpoints.testsuite.testsuiteApiGetStudyRetrieve(studyId);
        setStudy(response.data);
      } catch (error) {
        console.error('Error fetching study:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStudy();
  }, [studyId, db.endpoints.testsuite]);

  const handleExportPDF = async () => {
    if (!study) return;
    
    const element = document.getElementById('study-content');
    if (!element) return;
    
    try {
      await toPDF(() => document.getElementById('study-content'), {
        filename: `study-${studyId}.pdf`,
        page: {
          margin: 20,
          format: 'a4',
        }
      });
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  const renderBlock = (block: FullStudyBlock) => {
    switch (block.block_type) {
      case BlockTypeEnum.TEXT:
        return (
          <div key={block.id} className="prose max-w-none mb-8">
            <ReactMarkdown>{block.content}</ReactMarkdown>
          </div>
        );
      case BlockTypeEnum.ANALYSIS:
        return (
          <div key={block.id} className="mb-8">
            <h3 className="text-xl font-semibold mb-2">{block.analysis?.name}</h3>
            <p className="text-gray-600 mb-4">{block.analysis?.description}</p>
            {block.analysis && (
              <AnalysisResultsCard analysis={block.analysis} expanded={true} />
            )}
          </div>
        );
      case BlockTypeEnum.BENCHMARK:
        return (
          <div key={block.id} className="mb-8">
            <h3 className="text-xl font-semibold mb-2">Benchmark: {block.experiment?.name}</h3>
            {block.analysis && (
              <AnalysisResultsCard analysis={block.analysis} expanded={true} />
            )}
          </div>
        );
      default:
        return null;
    }
  };

  if (loading) {
    return <FullPageLoader />;
  }

  if (!study) {
    return <div>Study not found</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-3xl font-bold mb-2">{study.name}</h1>
          <p className="text-gray-600">{study.description}</p>
        </div>
        <button
          onClick={handleExportPDF}
          className="flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
        >
          <IconDownload size={20} />
          Export PDF
        </button>
      </div>
      
      <div id="study-content" className="space-y-8">
        {study.blocks.map(block => renderBlock(block))}
      </div>
    </div>
  );
};

export default ViewStudy;
