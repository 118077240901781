import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { OasisBackendProvider } from "./hooks/useOasisBackend";
import { useUser } from "./hooks/useUser";

export type LoadingState<T> = {
  status: "loading",
  previous?: T,
  // hash?: string,
} | {
  status: "loaded",
  data: T,
  // hash?: string,
} | {
  status: "error",
  errorMessage: string,
  retryCallback?: () => void,
}


export type LoadingStateStrict<T> = {
  status: "loading",
  data: T | null,
} | {
  status: "loaded",
  data: T,
} | {
  status: "error",
  data: T | null,
  errorMessage: string,
  retryCallback?: () => void,
}


export const RequireLogin: React.FC<{ children?: ReactNode }> = (props) => {
  const userApi = useUser();
  if (userApi.state === "LoggedOut") return <Navigate to={"/login"} />
  return <OasisBackendProvider children={props.children} />;
}