
interface DataPoint {
    id: number;
    [key: string]: number; // Allow for any other numeric properties
  }
  
export function linearTransformation(
    data: DataPoint[],
    dimensionX: string,
    dimensionY: string,
    targetXLength: number,
    targetYLength: number
  ): DataPoint[] {
    // Helper function to perform the linear transformation
    function transform(value: number, min: number, max: number, targetLength: number): number {
      return Math.floor(((value - min) * (targetLength - 1)) / (max - min || 1));
    }
  
    // Get the min and max values for each dimension
    const minX = Math.min(...data.map(item => item[dimensionX]));
    const maxX = Math.max(...data.map(item => item[dimensionX]));

    const minY = Math.min(...data.map(item => item[dimensionY]));
    const maxY = Math.max(...data.map(item => item[dimensionY]));
    console.log("Targets: ", targetXLength, targetYLength, minX, maxX, minY, maxY);
  
    // Apply the transformation to each data point
    const transformedData = data.map(item => ({
      ...item,
      ["left"]: transform(item[dimensionX], minX, maxX, targetXLength),
      ["top"]: transform(item[dimensionY], minY, maxY, targetYLength)
    }));
  
    return transformedData as DataPoint[];
  }