import React, { useEffect, useState } from 'react';
import axios from '../api/axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Submission, SubmissionField, FormField} from '../components/interfaces'; // Assuming interfaces are in a file named interfaces.ts
import { IconBrandXing } from '@tabler/icons-react';
import FloatingTooltip from '../components/FloatingTooltip';

const ViewResults: React.FC = () => {
  const { formId } = useParams<{ formId: string }>(); // Get formId from the URL
  const navigate = useNavigate();
  const [form, setForm] = useState<Form | null>(null); // State for the form object
  const [submissions, setSubmissions] = useState<Submission[]>([]); // State for the submissions
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [enrichingAll, setEnrichingAll] = useState<boolean>(false);
  const [enrichingSubmission, setEnrichingSubmission] = useState<string[]>([]);
  const [selectedConversation, setSelectedConversation] = useState<string | null>(null);
  const [conversationData, setConversationData] = useState<Array<{role: string, content: string}> | null>(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/get-form/${formId}`);
        const data = response.data;

        setForm(data);
        const sortedSubmissions = [...data.submissions].sort((a, b) => 
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
        setSubmissions(sortedSubmissions);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch form results');
        setLoading(false);
      }
    };

    fetchData();
  }, [formId]);

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      try {
        const response = await axios.get('/get-subscription-status/');
        setSubscriptionStatus(response.data.subscription_status);
      } catch (error) {
        console.error('Error fetching subscription status:', error);
      }
    };
    fetchSubscriptionStatus();
  }, []);

  const handleEnrichAllSubmissions = async () => {
    setEnrichingAll(true);
    try {
      // Filter submissions that have at least one "no data" field
      const submissionsToEnrich = submissions.filter(submission => {
        return form?.fields.some(field => 
          !submission.fields.find(f => f.form_field === field.id)
        );
      });

      // Enrich each submission sequentially
      for (const submission of submissionsToEnrich) {
        if (!enrichingSubmission.includes(submission.id)) {
          await handleEnrichSubmission(submission.id);
        }
      }
    } catch (error) {
      console.error('Error enriching submissions:', error);
    } finally {
      setEnrichingAll(false);
    }
  };

  const handleEnrichSubmission = async (submissionId: string) => {
    setEnrichingSubmission(prev => [...prev, submissionId]);
    try {
      const response = await axios.post(`/enrich-submission/${submissionId}/`);
      setForm(prevForm => {
        if (!prevForm) return null;
        const updatedSubmissions = prevForm.submissions.map(submission => 
          submission.id === submissionId.toString() ? response.data : submission
        );
        return { ...prevForm, submissions: updatedSubmissions };
      });
      setSubmissions(prevSubmissions => {
        const updatedSubmissions = prevSubmissions.map(submission =>
          submission.id === submissionId.toString() ? response.data : submission
        );
        return [...updatedSubmissions].sort((a, b) => 
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      });
    } catch (error) {
      console.error('Error enriching submission:', error);
    } finally {
      setEnrichingSubmission(prev => prev.filter(id => id !== submissionId.toString()));
    }
  };

  const handleViewConversation = async (submissionId: string) => {
    if (!subscriptionStatus || subscriptionStatus === 'free') {
      setConversationData([{
        role: 'assistant',
        content: 'Viewing conversations is a premium feature. Please upgrade your subscription to access conversation history.'
      }]);
      setSelectedConversation(submissionId);
      return;
    }

    try {
      const response = await axios.get(`/get-submission-conversation/${submissionId}/`);
      setConversationData(response.data.conversation);
      setSelectedConversation(submissionId);
    } catch (error) {
      console.error('Error fetching conversation:', error);
    }
  };

  const convertToCSV = (submissions: Submission[], fields: { id: string, name: string }[]) => {
    const headers = ['Submission ID', ...fields.map(field => field.name)];
    const rows = submissions.map(submission => {
      const row = [submission.id];
      fields.forEach(field => {
        const submissionField = submission.fields.find(f => f.form_field === field.id);
        row.push(submissionField ? submissionField.value : '');
      });
      return row;
    });

    const csvContent = [headers, ...rows].map(e => e.join(",")).join("\n");
    return csvContent;
  };

  const downloadCSV = () => {
    if (!form) return;

    const csvContent = convertToCSV(submissions, form.fields);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `form_results_${formId}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) {
    return <div className='flex justify-center items-center h-screen w-full'>
    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!form) {
    return <div>No form data available</div>;
  }

  return (
    <div className="p-4 pt-10 h-full w-full bg-gray-700 text-white flex flex-col rounded items-center justify-center overflow-auto relative ">
        <nav className="p-2 absolute top-0 left-0">
          <ul className="flex space-x-2 text-white">
              <li>
                  <button
                      onClick={() => navigate('/')}
                      className="underline hover:text-gray-300"
                  >
                      Home
                  </button>
              </li>
              <li>/</li>
              <li>
                  <button
                      onClick={() => navigate(`/edit-form/${formId}`)}
                      className="underline hover:text-gray-300"
                  >
                      {form.title}
                  </button>
              </li>
              <li>/</li>
              <li>
                  <button
                      onClick={() => navigate(`/view_results/${formId}`)}
                      className="underline hover:text-gray-300"
                  >
                      Results
                  </button>
              </li>
          </ul>
      </nav>
      <h1 className="text-2xl font-bold mb-4">You got {submissions.length} submissions!</h1>
      <div className="w-full max-w-5/6 max-h-5/6 overflow-auto bg-slate-800 rounded-xl ">
        <table className="min-w-full bg-slate-800 text-center">
          <thead>
            <tr>
              <th className="py-2 px-4 bg-slate-900 min-w-48">
                <button 
                  onClick={() => handleEnrichAllSubmissions()} 
                  disabled={enrichingAll || enrichingSubmission.length > 0}
                  className={`bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded ${
                    (enrichingAll || enrichingSubmission.length > 0) ? 'opacity-50 cursor-not-allowed' : ''
                  } disabled:opacity-50 disabled:cursor-not-allowed`}
                >
                  {enrichingAll ? 'Enriching...' : 'Extract all'}
                </button>
              </th>
              <th className="py-2 px-4 bg-slate-900 min-w-48">
                Conversation
              </th>
              <th className="py-2 px-4 bg-slate-900 min-w-48">Created at </th>
              {form.fields.map(field => (
                <th key={field.id} className="py-2 px-4 bg-slate-900 min-w-48">{field.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {submissions.map(submission => (
              <tr key={submission.id}>
                <td className="py-2 px-4 border-b border-slate-600">
                  <button 
                    id={submission.id.toString()} 
                    onClick={() => handleEnrichSubmission(submission.id)} 
                    disabled={enrichingSubmission.includes(submission.id.toString())}
                    className={`bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded ${enrichingSubmission.includes(submission.id.toString()) ? 'opacity-50 cursor-not-allowed' : ''} disabled:opacity-50 disabled:cursor-not-allowed`}
                  >
                    {enrichingSubmission.includes(submission.id.toString()) ? 'Enriching...' : 'Extract'}
                  </button>
                </td>
                <td className="py-2 px-4 border-b border-slate-600">
                  <button 
                    onClick={() => handleViewConversation(submission.id)}
                    className="bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded"
                  >
                    View Conversation
                  </button>
                </td>
                <td className="py-2 px-4 border-b border-slate-600">{(new Date(submission.created_at)).toLocaleString()}</td>
                {form.fields.map((field: FormField) => {
                  const submissionField = submission.fields.find((f: SubmissionField) => f.form_field === field.id);
                  return (
                    <td 
                      key={field.id} 
                      className={`py-2 px-4 border-b border-slate-600 min-w-48 max-w-48 ${submissionField ? '' : 'text-gray-400 italic'}`}
                      id={`cell-${submission.id}-${field.id}`}
                    >
                      {submissionField ? submissionField.value : 'no data'}
                      {!submissionField && (
                        <FloatingTooltip
                          message="<- click extract to search conversation for data"
                          targetRef={{ current: document.getElementById(`cell-${submission.id}-${field.id}`) }}
                        />
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
    
          </tbody>
        </table>
      </div>
      <div className="mt-4">
        <button
          onClick={downloadCSV}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Export as CSV
        </button>
      </div>
      {selectedConversation && conversationData && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50" 
          onClick={() => setSelectedConversation(null)}
        >
          <div 
            className="bg-slate-800 p-6 rounded-lg max-w-2xl max-h-[80vh] overflow-auto" 
            onClick={e => e.stopPropagation()}
          >
            <div className="flex justify-between items-center mb-4">
              <h3 className={`text-xl font-bold ${(!subscriptionStatus || subscriptionStatus === 'free') ? 'text-yellow-300' : 'text-gray-300'}`}>
                {(!subscriptionStatus || subscriptionStatus === 'free') ? 'Premium Feature' : 'Conversation'}
              </h3>
              <button 
                onClick={() => setSelectedConversation(null)}
                className="text-gray-400 hover:text-white"
              >
                ✕
              </button>
            </div>
            {(!subscriptionStatus || subscriptionStatus === 'free') ? (
              <div className="p-3">
                Viewing conversations is a premium feature. Please upgrade your subscription to access conversation history.
              </div>
            ) : (
              <div className="space-y-4">
                {conversationData.map((message, index) => (
                  <div 
                    key={index} 
                    className={`p-3 rounded-lg ${
                      message.role === 'assistant' 
                        ? 'bg-slate-700 text-gray-300 mr-10' 
                        : 'bg-slate-600 text-yellow-300 ml-10'
                    }`}
                  >
                    <div className="text-sm text-gray-400 mb-1">
                      {message.role === 'assistant' ? 'Feisty' : 'User'}
                    </div>
                    <div>
                      {message.content}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewResults;