/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; 
import SignUpButton from '../../components/SignupButton';
import { LoginButton } from '../../components/Login';
import { useUser } from '../../hooks/useUser';
import  Eyes  from '../components/Eyes';
import { css, keyframes } from '@emotion/react';


const Homepage: React.FC = () => {
  const navigate = useNavigate();
  const userApi = useUser();
  const isAuthenticated = userApi.state === 'LoggedIn';

  const [iframeSrc, setIframeSrc] = useState<string | null>(null);




  useEffect(() => {
    if (isAuthenticated) {
      navigate('/user-homepage');
      console.log('Navigating to user-homepage');
    }
  }, [isAuthenticated, navigate]);

  
  return (
    <div className="flex flex-col items-center min-h-full bg-slate-900 px-4 py-8 rounded h-full text-xl overflow-auto">

      <div className="my-8 md:my-12"></div>

      {/* Logo Animation Section */}
      <div css={animationStyle} className='flex flex-col items-center relative m-8 md:m-12'>
        <div className="w-fit opacity-0 scale-50 md:scale-100"><Eyes/></div>
        <img src="/assets/feistyforms/logo_nofont_white.png" className="h-32 md:h-60 opacity-70" />
        <div className="w-fit scale-50 md:scale-100"><Eyes/></div>
      </div>

      {/* Hero Section */}
      <div className="text-center max-w-3xl my-8 md:my-12 pb-12">
        <h1 className="text-4xl md:text-5xl font-bold text-slate-400 mb-6">Feisty<span className="text-slate-100">Forms</span>
        </h1>
        <p className="text-lg md:text-xl text-gray-300 mb-8">
          Engage users with a funny, feisty AI that guides them through forms effortlessly.
        </p>
        <button onClick={() => {
          const howItFeelsSection = document.getElementById('how-it-feels-section');
          if (howItFeelsSection) {
            howItFeelsSection.scrollIntoView({ behavior: 'smooth' });
          }
        }} className="mt-4 text-slate-300 text-2xl rounded bg-slate-700 p-3 animate-bounce shadow-[0_0px_10px_0px_rgba(250,250,250,.3)]">
          Try FeistyForms Now
        </button>
      </div>

      <div id="how-it-feels-section" className="my-20"></div>
      <div className="my-8"></div>

      {/* How It Feels to Use FeistyForms Section */}
      <div className="text-center max-w-3xl pb-12">
        <h1 className="text-4xl md:text-5xl font-bold text-slate-400 mb-12">A totally new <span className="text-slate-100">Experience</span>
        </h1>
        <p className="text-gray-300 mb-8">
          Forget boring forms. Experience what it’s like to have a conversation instead of filling fields. Test one of our demo forms below and see for yourself.
        </p>
        <div className="flex flex-col items-center mt-12 gap-6 text-lg text-slate-500">
          <div className='flex flex-col my-4 items-center md:flex-row md:my-2'>
          <span className="mb-4 md:mb-0 mr-2">How it feels to</span>
          <button onClick={() => setIframeSrc('/fill-form/c1c3ce2c-c44a-4b8f-a0f2-80401ca0b609')} className="text-slate-300 rounded bg-slate-700 p-3 hover:bg-slate-500 animate-bounce shadow-[0_0px_10px_0px_rgba(250,250,250,.3)] text-2xl ">
            sign up for Prom
          </button>
          <div className='flex flex-row items-center'>
            <span className="ml-2">with</span>
            <img src="/assets/feistyforms/logo_nofont_white.png" className="ml-2 h-10 opacity-50" />
            <span className="">FeistyForms</span>
          </div>
          </div>
          <div className='flex flex-col my-4 items-center md:flex-row md:my-2'>
            <span className="mb-4 md:mb-0 mr-2">How it feels to</span>
            <button onClick={() => setIframeSrc('/fill-form/54857afd-3dc3-497e-b677-75e655e694ec')} className="text-slate-300 rounded bg-slate-700 p-3 hover:bg-slate-500 animate-bounce shadow-[0_0px_10px_0px_rgba(250,250,250,.3)] text-2xl">
              give feedback to Google
            </button>
            <div className='flex flex-row items-center'>  
              <span className="ml-2">with</span>
              <img src="/assets/feistyforms/logo_nofont_white.png" className="ml-2 h-10 opacity-50" />
              <span className="">FeistyForms</span>
            </div>
          </div>
          <div className='flex flex-col my-4 items-center md:flex-row md:my-2'>
            <span className="mb-4 md:mb-0 mr-2">How it feels to</span>
            <button onClick={() => setIframeSrc('/fill-form/732eec76-ecf6-4406-b7d0-204eb065ca5d')} className="text-slate-300 rounded bg-slate-700 p-3 hover:bg-slate-500 animate-bounce shadow-[0_0px_10px_0px_rgba(250,250,250,.3)] text-2xl">
              Apply for a Job
            </button>
            <div className='flex flex-row items-center'>
              <span className="ml-2">with</span>
              <img src="/assets/feistyforms/logo_nofont_white.png" className="ml-2 h-10 opacity-50" />
              <span className="">FeistyForms</span>
            </div>
          </div>
        </div>
        {iframeSrc && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-slate-700 rounded-lg overflow-hidden w-11/12 h-5/6">
              <div className="flex justify-end p-2">
                <button onClick={() => setIframeSrc(null)} className="text-white">Close</button>
              </div>
              <iframe src={iframeSrc} className="w-full h-full"></iframe>
            </div>
          </div>
        )} 
      </div>

      <div className="my-20"></div>
      <div className="my-8"></div>

      {/* Key Features Section */}
      <div className="text-center max-w-3xl mb-12 pb-12">
        <h1 className="text-4xl md:text-5xl font-bold text-slate-400 mb-12">Why FeistyForms is <span className="text-slate-100">Different</span>
        </h1>
        <p className="text-gray-300 mb-8">
          We’ve reinvented form-filling. Here's how:
        </p>
        <ul className="list-disc list-inside text-gray-300 text-left">
          <li>Personalized Follow-Ups: Our AI doesn’t stop at basic questions...</li>
          <br></br>
          <li>Feisty Personalities: With a free feisty, funny, and a little passive-aggressive AI...</li>
          <br></br>
          <li>CSV Export: Review responses in real-time through our web app...</li>
        </ul>
      </div>

      <div className="my-20"></div>

      {/* Who Is It For Section */}
      <div className="text-center max-w-3xl mb-12  pb-12">
      <h1 className="text-4xl md:text-5xl font-bold text-slate-400 mb-12">Who <span className="text-slate-100">Benefits</span>
      </h1> 
        <p className="text-gray-300 mb-8">
          Designed for a range of use cases to make complex forms simple and fun:
        </p>
        <ul className="list-disc list-inside text-gray-300 text-left">
          <li>Corporates: Collect structured data like opinions and feedback...</li>
          <br></br>
          <li>Customer Support: Turn the dull process of gathering customer information...</li>
          <br></br>
          <li>Event Sign-ups: Make signing up for events fun...</li>
          <br></br>
          <li>Complex Forms: Complicated forms with many fields and explanations...</li>
        </ul>
      </div>

      <div className="my-20"></div>

      {/* Call to Action Section */}
      <div className="text-center max-w-3xl mb-12  pb-12">
        <h2 className="text-3xl font-bold text-slate-400 mb-4">
          Ready to Make Forms Fun?
        </h2>
        <p className="text-lg text-gray-300 mb-8">
          Sign up today and discover a new way to collect data...
        </p>
        <div className="flex flex-col items-center gap-6 ">
          <SignUpButton className="shadow-[0_0_0_1px_#fff_inset] text-white"/>
        </div>
      </div>

      <div className="my-20"></div>
    </div>
  )
};

export default Homepage;




// Combining bounce (twice ) and spin (once) animations
const bounceAndSpin = keyframes`
  0%{ transform: translateY(0px) rotate(0deg); cubic-bezier(.5, 0.05, 1, .5) }
  25%{ transform: translateY(0px) rotate(180deg); cubic-bezier(.5, 0.05, 1, .5) }
  50%{ transform: translateY(0px) rotate(180deg); cubic-bezier(.5, 0.05, 1, .5) }
  75%{ transform: translateY(0px) rotate(360deg); cubic-bezier(.5, 0.05, 1, .5)}
  100%{ transform: translateY(0px) rotate(360deg); cubic-bezier(.5, 0.05, 1, .5) }
`


const animationStyle = css`
 
  /* Apply the bounce and spin animation */
  animation: ${bounceAndSpin} 10s infinite;
`;
