
import axios from 'axios';
import { BACKEND_URL } from '../../hooks/useOasisBackend';

const api = axios.create({
  baseURL: `${BACKEND_URL}/feistyforms/api/`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,  // Include credentials (cookies) in requests
});

// Add a request interceptor to include the CSRF token in each request
api.interceptors.request.use(config => {
  const csrfToken = localStorage.getItem('csrfToken');  // Get the CSRF token from localStorage
  if (csrfToken) {
    config.headers['X-CSRFToken'] = csrfToken;  // Add the CSRF token to the headers
  }
  return config;
});

export default api;
