import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from './pages/Homepage';
import FillForm from './pages/FillForm';
import UserHomepage from './pages/UserHomepage';
import EditForm from './pages/EditForm';
import ViewResults from './pages/ViewResults';
import Layout from './components/Layout';  // Import the Layout component
import { SaveStatusProvider } from './context/SaveStatusContext';
import { UserProvider } from '../hooks/useUser';
import { RequireLogin } from '../helper';
import { commonRoutes } from '../commonPages/CommonRoutes';
import UserSettings from './pages/UserSettings';
import { Helmet } from 'react-helmet';
const FeistyFormsIndex = () => {
  return (
    <React.StrictMode>
      <UserProvider mountingScreen={<></>}>
        <Helmet>
          <meta name="description" content="" />
          <meta name="keywords" content="" />
          <title>Feisty Forms: AI powered conversational forms</title>
        </Helmet>
        <SaveStatusProvider>
          <Router>
            <Layout>
              <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="/fill-form/:formId" element={<FillForm />} />

                {/* Protected Routes */}
                <Route path="/user-homepage" element={<RequireLogin><UserHomepage /></RequireLogin>} />
                <Route path="/edit-form/:formId" element={<RequireLogin><EditForm /></RequireLogin>} />
                <Route path="/view-results/:formId" element={<RequireLogin><ViewResults /></RequireLogin>} />
                <Route path="settings" element={<RequireLogin><UserSettings /></RequireLogin>} />    
                {commonRoutes}
                {/* Fallback route */}
                <Route path="*" element={<Homepage />} />
              </Routes>
            </Layout>
          </Router>
        </SaveStatusProvider>
      </UserProvider>
    </React.StrictMode>
  );
};

export default FeistyFormsIndex;