import React from 'react';
import Plot from 'react-plotly.js';
import { CorrelationMatrixProps } from './ChartInterfaces';

const CorrelationMatrix: React.FC<CorrelationMatrixProps> = ({
  data,
  title = 'Correlation Matrix',
  xAxisLabel,
  yAxisLabel,
  colorscale = 'sunset',
  fontColor = 'white',
  showText = false,
}) => {
  const heatmapData = {
    z: data.matrix,
    x: data.labels,
    y: data.labels,
    type: 'heatmap' as const,
    colorscale,
  };

  const textData = showText
    ? {
        x: data.labels.flatMap(label => new Array(data.labels.length).fill(label)),
        y: data.labels.reduce<string[]>((acc, label) => [...acc, ...data.labels], []),
        mode: 'text' as const,
        text: data.matrix.flat().map(String),
        type: 'scatter' as const,
        textfont: {
          size: 10,
          color: fontColor,
        },
        hoverinfo: 'none' as const,
      }
    : null;

  return (
    <Plot
      data={[heatmapData, ...(textData ? [textData] : [])]}
      layout={{
        title,
        xaxis: { title: xAxisLabel, side: 'bottom' },
        yaxis: { title: yAxisLabel, autorange: 'reversed' as const },
        margin: { t: 50, b: 200, l: 400, r: 10 },
        
      }}
      config={{responsive: true}}
      className="w-full h-full"
    />
  );
};

export default CorrelationMatrix;
