import React from 'react';
import Plot from 'react-plotly.js';
import { ScatterPlotProps } from './ChartInterfaces';

const ScatterPlot: React.FC<ScatterPlotProps> = ({ data, title = 'Scatter Plot', xAxisLabel, yAxisLabel, markerColor = 'red' }) => {
  return (
    <Plot
      data={[
        {
          x: data.xValues,
          y: data.yValues,
          mode: 'markers',
          marker: { color: markerColor, size: 8 },
          type: 'scatter',
        },
      ]}
      layout={{
        title,
        xaxis: { title: xAxisLabel },
        yaxis: { title: yAxisLabel },
        margin: { t: 50, b: 40, l: 50, r: 10 },
      }}
      config={{responsive: true}}
      className="w-full h-full"
    />
  );
};

export default ScatterPlot;