import React, { useState, useEffect, useRef } from 'react';
import { FullOperation as FullOperationType, ModelEnum } from '../../../api/OasisBackendApi';
import SubmitButton from '../SubmitButton';
import { useOasisBackend } from '../../../hooks/useOasisBackend';
import { useEditOperation } from '../../context/EditOperationContext';
import { debounce } from 'lodash';
import AutocompleteDropdown from '../AutocompleteDropdown';

interface EditingLLMOperationsCardProps {
    operation: FullOperationType;
    className?: string;
    openLibraryPanel?: (type: 'prompt_template' | 'dataset', step: 'new' | 'prompt' | 'edit' | 'dataset', target: 'input_dataset' | 'system_prompt_template' | 'user_prompt_template' | 'output_dataset') => void;
    onOpenLibraryPanel?: (type: 'prompt_template' | 'dataset', step: 'new' | 'prompt' | 'edit' | 'dataset', target: 'input_dataset' | 'system_prompt_template' | 'user_prompt_template' | 'output_dataset') => void;
    onSave?: (operation: FullOperationType) => void;
    onCancel?: () => void;
}

const EditingOperationsCard: React.FC<EditingLLMOperationsCardProps> = ({ 
    operation, 
    className, 
    onOpenLibraryPanel, 
    onSave,
    onCancel
}) => {
    const { editedOperation, updateEditedOperation, resetEditedOperation } = useEditOperation();
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const db = useOasisBackend();
    const [showDropdown, setShowDropdown] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
    const [currentMultiselectField, setCurrentMultiselectField] = useState('');
    const [filterText, setFilterText] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (operation && (!editedOperation || operation.id !== editedOperation.id)) {
            updateEditedOperation(operation);
        }
    }, [operation, editedOperation, updateEditedOperation]);

    const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!editedOperation) return;
        setIsSaving(true);
        try {
            const operationToUpdate = {
                ...editedOperation,
                input_dataset: editedOperation.input_dataset?.id || undefined,
                output_dataset: editedOperation.output_dataset?.id || undefined,
                system_prompt_template: editedOperation?.allowed_params?.system_prompt_template ? editedOperation.system_prompt_template?.id || undefined : undefined,
                user_prompt_template: editedOperation.user_prompt_template?.id || undefined,
                llm_jobs: editedOperation.llm_jobs || [],
                experiment_order: editedOperation.experiment_order || 0,
                params: editedOperation.params || {},
            };
            const response = await db.endpoints.testsuite.testsuiteApiUpdateOperationUpdate(editedOperation.id.toString(), operationToUpdate as any);
            onSave && onSave(response.data as FullOperationType);
        } catch (error) {
            console.error('Failed to save operation:', error);
        } finally {
            setIsSaving(false);
        }
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (editedOperation) {
            updateEditedOperation({ ...editedOperation, name: e.target.value });
        }
    };

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (editedOperation) {
            updateEditedOperation({ ...editedOperation, description: e.target.value });
        }
    };

    const handleExperimentOrderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (editedOperation) {
            updateEditedOperation({ ...editedOperation, experiment_order: parseInt(e.target.value) });
        }
    };

    const handleCancel = () => {
        resetEditedOperation();
        onCancel && onCancel();
    };

    const renderAddButton = (onClick: () => void, text: string) => (
        <button 
            type="button"
            className="text-sm text-gray-600 px-2 py-1 bg-none border-2 border-gray-300 border-dashed rounded-md my-2 cursor-pointer hover:shadow-lg transition-shadow duration-200" 
            onClick={onClick}
        >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
            </svg>
            {text}
        </button>
    );

    return (
        <div className={`bg-white flex shadow-md rounded-lg p-6 cursor-pointer hover:shadow-xl transition-shadow duration-200 w-full ${className}`}>
            <form onSubmit={handleSave} className='flex-col md:flex-row items-center justify-center w-full h-full'>
            <div className='flex flex-col m-2'>
                <input 
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"
                    value={editedOperation?.name || ''}
                    onChange={handleNameChange}
                    placeholder="Operation Name"
                />
                <textarea 
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline resize-y"
                    value={editedOperation?.description || ''}
                    onChange={handleDescriptionChange}
                    placeholder="Operation Description"
                    rows={5}
                />
                <input 
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"
                    value={editedOperation?.experiment_order || 0}
                    onChange={handleExperimentOrderChange}
                    type = "number"
                    placeholder="Experiment Order"
                />
            {editedOperation?.allowed_params?.model ? (
                    <div className='flex flex-col m-2 justify-center align-end '>
                        <label htmlFor="model" className="text-sm text-gray-600">Model</label>
                        <select 
                            id="model" 
                            name="model" 
                            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            value={editedOperation?.model || 'None'} 
                            onChange={(e) => updateEditedOperation({ ...editedOperation, model: e.target.value as ModelEnum })} 
                        >
                            
                            {Object.values(ModelEnum).map((model) => (
                                <option key={model} value={model}>{model}</option>
                            ))}
                        </select>
                    </div>
                ) : null}
            </div>
            <div className="flex flex-grow m-4">
                <div className='flex flex-grow'>
                <div className="flex flex-col w-2/3">
                    <div className='flex justify-end items-center'>
                        <div className="flex flex-wrap items-center">
                            {editedOperation?.allowed_params?.input_dataset ? (
                                <>
                                    {editedOperation.input_dataset ? (
                                        <p className={`text-sm text-gray-600 px-2 py-1 bg-gray-100 rounded-md my-2 cursor-pointer hover:shadow-lg transition-shadow duration-200`} onClick={() => onOpenLibraryPanel && onOpenLibraryPanel('dataset', 'dataset', 'input_dataset')}>{editedOperation.input_dataset.name}</p>
                                    ) : renderAddButton(() => onOpenLibraryPanel && onOpenLibraryPanel('dataset', 'new', 'input_dataset'), "Add Input Dataset")}
                                    <div className='w-5 border-b-2 border-gray-300'></div>
                                </>
                            ) : null}
                        </div>
                    </div>

                    {editedOperation?.allowed_params?.params && (
                        <>
                            {Object.entries(editedOperation.allowed_params.params).map(([key, value]) => {
                                const [label, inputType] = key.split('__');
                                console.log('label', label);
                                // Get the actual value from editedOperation.params using the label
                                const paramValue = editedOperation.params?.[label] || editedOperation.allowed_params.params?.[key] || '';
                                
                                switch (inputType) {
                                    case 'number':
                                        return (
                                            <div key={key} className="flex items-center mb-2">
                                                <label className="text-sm text-gray-600 mr-2">{label}</label>
                                                <input
                                                    type="number"
                                                    className="shadow appearance-none border rounded py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    value={paramValue}
                                                    onChange={(e) => updateEditedOperation({
                                                        ...editedOperation,
                                                        params: { ...editedOperation.params, [label]: e.target.value }
                                                    })}
                                                />
                                            </div>
                                        );
                                    case 'text':
                                        return (
                                            <div key={key} className="flex items-center mb-2">
                                                <label className="text-sm text-gray-600 mr-2">{label}</label>
                                                <input
                                                    type="text"
                                                    className="shadow appearance-none border rounded py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    value={paramValue}
                                                    onChange={(e) => updateEditedOperation({
                                                        ...editedOperation,
                                                        params: { ...editedOperation.params, [label]: e.target.value }
                                                    })}
                                                />
                                            </div>
                                        );
                                    case 'textarea':
                                        return (
                                            <div key={key} className="flex items-center mb-2">
                                                <label className="text-sm text-gray-600 mr-2">{label}</label>
                                                <textarea
                                                    className="shadow appearance-none border rounded py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    value={paramValue}
                                                    onChange={(e) => updateEditedOperation({
                                                        ...editedOperation,
                                                        params: { ...editedOperation.params, [label]: e.target.value }
                                                    })}
                                                />
                                            </div>
                                        );
                                    case 'select':
                                        return (
                                            <div key={key} className="flex items-center mb-2">
                                                <label className="text-sm text-gray-600 mr-2">{label}</label>
                                                <select
                                                    className="shadow appearance-none border rounded py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    value={paramValue}
                                                    onChange={(e) => updateEditedOperation({
                                                        ...editedOperation,
                                                        params: { ...editedOperation.params, [label]: e.target.value }
                                                    })}
                                                >
                                                    <option value="">Select {label}</option>
                                                    {Array.isArray(value) && 
                                                        value.map((option: string) => (
                                                            <option key={option} value={option}>{option}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        );
                                    case 'multiselect':
                                        let selectOptions: string[] = [];
                                        let isImplemented = false;

                                        if (editedOperation.allowed_params.params?.[key]?.dataset) {
                                            if (editedOperation.allowed_params.params[key].dataset === 'input_dataset'){
                                                const content = editedOperation?.input_dataset?.sample_datum?.content;
                                                if (content && typeof content === 'object') {
                                                    selectOptions = Object.keys(content);
                                                    isImplemented = true;
                                                }
                                            }
                                        }

                                        if (!isImplemented) {
                                            return (
                                                <div key={key} className="flex items-center mb-2">
                                                    <label className="text-sm text-gray-600 mr-2">{label}</label>
                                                    <span className="text-sm text-red-500">Multiselect not implemented for this configuration</span>
                                                </div>
                                            );
                                        }

                                        return (
                                            <div key={key} className="flex flex-col mb-2">
                                                <label className="text-sm text-gray-600 mb-1">{label}</label>
                                                <div>
                                                    <div className="flex items-center w-full mb-2">
                                                        <input
                                                            type='checkbox'
                                                            className="mr-2"
                                                            checked={Array.isArray(paramValue) && paramValue.length === selectOptions.length}
                                                            onChange={(e) => {
                                                                const newValues = e.target.checked ? [...selectOptions] : [];
                                                                updateEditedOperation({
                                                                    ...editedOperation,
                                                                    params: { ...editedOperation.params, [label]: newValues }
                                                                });
                                                            }}
                                                        />
                                                        <span className="text-sm font-medium">Select All</span>
                                                    </div>
                                                    <div className="flex items-center w-full">
                                                        <input
                                                            ref={inputRef}
                                                            type="text"
                                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                            value={filterText}
                                                            onChange={(e) => {
                                                                setFilterText(e.target.value);
                                                                setShowDropdown(true);
                                                            }}
                                                            onFocus={() => {
                                                                setCurrentMultiselectField(key);
                                                                setShowDropdown(true);
                                                                if (inputRef.current) {
                                                                    const rect = inputRef.current.getBoundingClientRect();
                                                                    setDropdownPosition({ top: rect.bottom, left: rect.left });
                                                                }
                                                            }}
                                                            onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
                                                        />
                                                    </div>
                                                    <div className="flex flex-wrap mt-2">
                                                        {Array.isArray(paramValue) && paramValue.map((item: string, index: number) => (
                                                            <span key={index} className="bg-blue-100 text-blue-800 px-2 py-1 rounded mr-2 mb-2">
                                                                <button
                                                                    className="mr-2 text-blue-600 hover:text-blue-800"
                                                                    onClick={() => {
                                                                        const newValues = paramValue.filter((_: string, i: number) => i !== index);
                                                                        updateEditedOperation({
                                                                            ...editedOperation,
                                                                            params: { ...editedOperation.params, [label]: newValues }
                                                                        });
                                                                    }}
                                                                    type="button"
                                                                >
                                                                ×
                                                                </button>
                                                                {item}
                                                            </span>
                                                        ))}
                                                    </div>
                                                    {showDropdown && currentMultiselectField === key && (
                                                        <AutocompleteDropdown
                                                            options={selectOptions}
                                                            onSelect={(option) => {
                                                                if (!editedOperation) return;
                                                                const currentValues = editedOperation.params?.[label] || [];
                                                                if (!currentValues.includes(option)) {
                                                                    updateEditedOperation({
                                                                        ...editedOperation,
                                                                        params: { 
                                                                            ...editedOperation.params, 
                                                                            [label]: [...currentValues, option] 
                                                                        }
                                                                    });
                                                                }
                                                                setFilterText('');
                                                            }}
                                                            position={dropdownPosition}
                                                            filterText={filterText}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    case 'dataset':
                                        return (
                                            <div key={key} className="flex items-center mb-2">
                                                <label className="text-sm text-gray-600 mr-2">{label}</label>
                                                {editedOperation.params?.[label] ? (
                                                    <p 
                                                        className="text-sm text-gray-600 px-2 py-1 bg-gray-100 rounded-md cursor-pointer hover:shadow-lg transition-shadow duration-200"
                                                        onClick={() => onOpenLibraryPanel && onOpenLibraryPanel('dataset', 'dataset', ('params__' + label).toString() as any)}
                                                    >
                                                        {editedOperation.params[label].split('__')[1]}
                                                    </p>
                                                ) : (
                                                    renderAddButton(
                                                        () => onOpenLibraryPanel && onOpenLibraryPanel('dataset', 'new', ('params.' + label).toString() as any),
                                                        "Add Dataset"
                                                    )
                                                )}
                                            </div>
                                        );
                                    default:
                                        return null;
                                }
                            })}
                        </>
                    )}

                    {editedOperation?.allowed_params?.system_prompt_template ? (    
                        <>
                        <div className='flex justify-end items-center'>
                            <div className="flex flex-wrap items-center">
                                {editedOperation.system_prompt_template ? (
                                    <>
                                        <p className={`text-sm text-gray-600 px-2 py-1 bg-gray-100 rounded-md my-2 cursor-pointer hover:shadow-lg transition-shadow duration-200`} onClick={() => onOpenLibraryPanel && onOpenLibraryPanel('prompt_template', 'dataset', 'system_prompt_template')}>{editedOperation.system_prompt_template?.dataset?.name || 'None'}</p>
                                        <div className='w-5 border-b-2 border-gray-300'></div>
                                        <p className="text-sm text-gray-600 px-2 py-1 bg-gray-100 rounded-md my-2 cursor-pointer hover:shadow-lg transition-shadow duration-200" onClick={() => onOpenLibraryPanel && onOpenLibraryPanel('prompt_template', 'edit', 'system_prompt_template')}>
                                            {editedOperation.system_prompt_template?.name || 'None'}
                                        </p>
                                    </>
                                ) : 
                                    renderAddButton(() => onOpenLibraryPanel && onOpenLibraryPanel('prompt_template', 'new', 'system_prompt_template'), "Add System Prompt")
                                }
                                    <div className='w-2 border-b-2 border-gray-300'></div>
                                    <p className='text-sm text-gray-400'>SYSTEM</p>
                                    <div className='w-2 border-b-2 border-gray-300'></div>
                            </div>
                        </div>
                        </>
                    ) : null}

                    <div className='flex justify-end items-center'>
                        <div className="flex flex-wrap items-center">
                            {editedOperation?.allowed_params?.user_prompt_template ? (
                                <>
                                    {editedOperation.user_prompt_template ? (
                                    <>
                                        <p className={`text-sm text-gray-600 px-2 py-1 bg-gray-100 rounded-md my-2 cursor-pointer hover:shadow-lg transition-shadow duration-200`} onClick={() => onOpenLibraryPanel && onOpenLibraryPanel('prompt_template', 'dataset', 'user_prompt_template')}>{editedOperation.user_prompt_template?.dataset?.name || 'None'}</p>
                                        <div className='w-5 border-b-2 border-gray-300'></div>
                                        <p className="text-sm text-gray-600 px-2 py-1 bg-gray-100 rounded-md my-2 cursor-pointer hover:shadow-lg transition-shadow duration-200" onClick={() => onOpenLibraryPanel && onOpenLibraryPanel('prompt_template', 'edit', 'user_prompt_template')}>
                                            {editedOperation.user_prompt_template?.name || 'None'}
                                        </p>
                                    </>
                                ) : 
                                    renderAddButton(() => onOpenLibraryPanel && onOpenLibraryPanel('prompt_template', 'new', 'user_prompt_template'), "Add User Prompt")
                                }
                                    <div className='w-2 border-b-2 border-gray-300'></div>
                                    <p className='text-sm text-gray-400'>USER</p>
                                    <div className='w-2 border-b-2 border-gray-300'></div>
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
                {editedOperation?.allowed_params?.output_dataset ? (
                <div className="flex flex-wrap w-1/3 border-l-2 border-gray-300">
                    <div className='flex h-full justify-center items-center '>
                        <div className='w-4 border-b-2 border-gray-300'></div>
                        <div className='flex flex-col h-full justify-center'>
                            {editedOperation?.allowed_params?.output_dataset ? (
                                <>
                                    {editedOperation.output_dataset ? (
                                        <p className="text-sm text-gray-600 px-2 py-1 bg-gray-100 rounded-md cursor-pointer hover:shadow-lg transition-shadow duration-200" onClick={() => onOpenLibraryPanel && onOpenLibraryPanel('dataset', 'dataset', 'output_dataset')}>{editedOperation.output_dataset.name}</p>
                                    ) : renderAddButton(() => onOpenLibraryPanel && onOpenLibraryPanel('dataset', 'new', 'output_dataset'), "Add Output Dataset")}
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>) : null}
                </div>
                
            </div>
            <div className='flex w-full'>
                <div className='flex-grow flex'>
                    <SubmitButton isSubmitting={isSaving} submitText="Save" loadingText="Saving..." />
                    <button onClick={handleCancel} className="text-gray-500 px-4 py-2 hover:text-gray-600">Cancel</button>
                </div>
            </div>
            </form>
            
            
        </div>
    );
};

export default EditingOperationsCard;
