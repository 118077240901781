import React, { useState, useEffect, useMemo } from 'react';
import { Dataset, SearchResult, SearchCollection } from '../../api/OasisBackendApi';
import DatasetLibrary from '../components/Libraries/DatasetLibrary';
import Modal from '../components/modal';
import { useOasisBackend } from '../../hooks/useOasisBackend';
import {SearchResults} from '../components/SearchResults';
import { useSearchParams } from 'react-router-dom';
import Searchbar from '../components/Searchbar';

const Search: React.FC = () => {
    const [selectedDataset, setSelectedDataset] = useState<Dataset | null>(null);
    const [isDatasetModalOpen, setIsDatasetModalOpen] = useState(false);
    const [query, setQuery] = useState<string>('');
    const [isSearching, setIsSearching] = useState(false);
    const db = useOasisBackend();
    const [searchParams, setSearchParams] = useSearchParams();
    const [collection, setCollection] = useState<SearchCollection | null>(null);
    const [searchCollectionId, setSearchCollectionId] = useState<string | null>(null);
    const [allowNewSearch, setAllowNewSearch] = useState(true);
    const [currentResultIndex, setCurrentResultIndex] = useState(0);
    const [filteredResults, setFilteredResults] = useState<SearchResult[]>([]);

    // Fetch collection on mount if collection_id is present
    useEffect(() => {
        const collectionId = searchParams.get('collection_id');
        if (collectionId) {
            setSearchCollectionId(collectionId);
            setAllowNewSearch(false);
        }
        const fetchCollection = async () => {
            try {
                const result = await db.endpoints.testsuite.testsuiteApiGetSearchCollectionRetrieve(
                    collectionId ? collectionId : 'history'
                );
                if (result.data) {
                    setCollection(result.data);
                    setFilteredResults(result.data.search_results); // Initialize filtered results
                }
            } catch (error) {
                console.error('Failed to fetch collection:', error);
            }
        };
        fetchCollection();
    }, [searchParams]);


    const searchableProperties = useMemo(() => {
        // get first result and return all of its nested keys as a list. if it is nested, concacenate them with . 
        if (!collection?.search_results?.length) return [];
        
        const firstResult = collection.search_results[0];
        const properties: string[] = [];

        const extractNestedKeys = (obj: any, prefix = '') => {
            for (const key in obj) {
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    extractNestedKeys(obj[key], prefix ? `${prefix}.${key}` : key);
                } else {
                    properties.push(prefix ? `${prefix}.${key}` : key);
                }
            }
        };

        extractNestedKeys(firstResult);
        return properties;
    }, [collection]);

    // New function to handle filtered results from Searchbar
    const handleFilterChange = (filtered: SearchResult[]) => {
        setFilteredResults(filtered);
        setCurrentResultIndex(0); // Reset to first result when filter changes
    };

    // Navigation handlers
    const handlePrevResult = () => {
        setCurrentResultIndex(current => Math.max(0, current - 1));
    };

    const handleNextResult = () => {
        setCurrentResultIndex(current => 
            Math.min((filteredResults.length || 0) || (collection?.search_results?.length || 0), current + 1)
        );
    };

    // Get current result to display
    const currentResult = useMemo(() => {
        const results = filteredResults.length > 0 ? 
            filteredResults : 
            collection?.search_results || [];
        console.log('Current results array:', results);
        console.log('Current index:', currentResultIndex);
        return results[currentResultIndex];
    }, [filteredResults, collection?.search_results, currentResultIndex]);

    const handleDatasetSelect = (dataset: Dataset) => {
        setSelectedDataset(dataset);
        setIsDatasetModalOpen(false);
    };

    const handleSearch = async (e?: React.FormEvent) => {
        if (e) e.preventDefault();
        if (!selectedDataset || !query.trim()) return;

        setIsSearching(true);
        try {
            const result = await db.endpoints.testsuite.testsuiteApiSearchCreate({
                query: query,
                dataset_id: selectedDataset.id,
            });
            
            setCollection(prev => prev ? {
                ...prev,
                search_results: [...prev.search_results, result.data]
            } : null);
        } catch (error) {
            console.error('Search failed:', error);
        } finally {
            setIsSearching(false);
        }
    };


    if (!selectedDataset && allowNewSearch) {
        return (
            <>
            <div className="container mx-auto p-4 min-h-full flex flex-col">
                <div className="flex justify-center items-center h-64">
                    <button
                        onClick={() => setIsDatasetModalOpen(true)}
                        className="bg-gray-300 text-slate-800 px-6 py-3 rounded-lg text-lg hover:bg-gray-400"
                    >
                        Choose Dataset
                    </button>
                </div>
            </div>
            <Modal
                isOpen={isDatasetModalOpen}
                onClose={() => setIsDatasetModalOpen(false)}
                size="large"
            >
                <DatasetLibrary
                    onSelect={handleDatasetSelect}
                    onCancel={() => setIsDatasetModalOpen(false)}
                />
            </Modal>
            </>
            
        );
    }

    return (
        <div className="container mx-auto p-4 min-h-full flex flex-col">
            {selectedDataset && (
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-2xl font-bold">
                    Searching{" "}

                    <button 
                        onClick={() => setIsDatasetModalOpen(true)}
                        className="text-slate-800 font-normal bg-gray-300 px-2 py-1 rounded-md hover:bg-gray-400"
                    >
                            {selectedDataset.name}
                        </button>
                    </h1>
                </div>
            )}
            {allowNewSearch ? (
            <form onSubmit={handleSearch} className="w-full mb-6">
                <textarea
                    className="w-full h-48 p-4 border rounded-lg resize-none outline-none shadow-md mb-4"
                    placeholder="Enter your search query..."
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            handleSearch();
                        }
                    }}
                />
                <button
                    type="submit"
                    disabled={isSearching}
                    className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 disabled:bg-gray-400"
                >
                    {isSearching ? 'Searching...' : 'Search'}
                    </button>
                </form>
            ) : (
                <p className="italic">Search is disabled for this collection</p>
            )}

            {collection?.search_results && collection.search_results.length > 0 && (
                <>
                    {console.log('Collection exists, about to check currentResult:', {
                        currentResult,
                        collectionLength: collection.search_results.length,
                        currentResultIndex,
                        filteredResults,
                        allowNewSearch
                    })}
                    
                    {!allowNewSearch && (
                        <Searchbar
                            items={collection.search_results}
                            searchableProperties={searchableProperties}
                            onFilterChange={handleFilterChange}
                        />
                    )}
                    
                    <div className="mt-8">
                        <div className="flex justify-between items-center mb-4">
                            <span className="text-sm text-gray-600">
                                Showing result {currentResultIndex + 1} of {filteredResults.length || collection.search_results.length}
                            </span>
                            <div className="flex gap-2">
                                <button
                                    onClick={handlePrevResult}
                                    disabled={currentResultIndex === 0}
                                    className="px-3 py-1 border rounded hover:bg-gray-100 disabled:opacity-50"
                                >
                                    Previous
                                </button>
                                <button
                                    onClick={handleNextResult}
                                    disabled={currentResultIndex === (filteredResults.length - 1 || collection.search_results.length - 1)}
                                    className="px-3 py-1 border rounded hover:bg-gray-100 disabled:opacity-50"
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                        
                        {currentResult ? (
                            <SearchResults searchResults={currentResult as any} />
                        ) : (
                            <p>No result available (currentResult is {JSON.stringify(currentResult)})</p>
                        )}
                    </div>
                </>
            )}

            <Modal
                isOpen={isDatasetModalOpen}
                onClose={() => setIsDatasetModalOpen(false)}
                size="large"
            >
                <DatasetLibrary
                    onSelect={handleDatasetSelect}
                    onCancel={() => setIsDatasetModalOpen(false)}
                />
            </Modal>
        </div>
    );
};

export default Search;