import React, { useState, useRef, useEffect } from 'react';

type OptionButtonProps = {
  buttons: { label: string; onClick: (e: React.MouseEvent) => void }[]; // Accept the event in onClick
  className?: string;
};

const OptionButton: React.FC<OptionButtonProps> = ({ buttons, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [expandUpwards, setExpandUpwards] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);

  const toggleOption = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent event propagation to parent
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (buttonRef.current) {
      const { bottom } = buttonRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      setExpandUpwards(bottom > windowHeight / 2);
    }
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className={`relative ${className}`} ref={buttonRef}>
      <button
        className="px-3 pb-3 rounded hover:bg-gray-400 focus:outline-none"
        onClick={toggleOption}

      >
        <span className="text-xl text-white">...</span>
      </button>

      {isOpen && (
        <div
          className={`absolute ${
            expandUpwards ? 'bottom-full mb-2' : 'top-full mt-2'
          } right-0 flex flex-col space-y-2 bg-slate-900 shadow-lg rounded-md p-2 z-50`}
        >
          {buttons.map((button, index) => (
            <button
              key={index}
              className="px-4 py-2 text-left text-slate-200 hover:bg-slate-800 rounded-md focus:outline-none"
              onClick={button.onClick}
            >
              {button.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default OptionButton;
