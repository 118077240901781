import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../../hooks/useUser';
import { useOasisBackend } from "../../hooks/useOasisBackend";
import { Dataset as DatasetType } from '../../api/OasisBackendApi';
import BackButton from '../../components/ui/back_button';
import { FullPageLoader } from '../components/loader';
import Searchbar from '../components/Searchbar';
import CreateDataset from '../components/forms/CreateDataset'; // Import the CreateDataset component
import Modal from '../components/modal'; // Import a Modal component if you have one
import DatasetCard from '../components/Cards/DatasetCard';

const Datasets: React.FC = () => {
  const db = useOasisBackend();
  const navigate = useNavigate();
  const [datasets, setDatasets] = useState<DatasetType[]>([]);
  const [filteredDatasets, setFilteredDatasets] = useState<DatasetType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const user = useUser();
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

  const handleFilterChange = useCallback((filteredItems: DatasetType[]) => {
    setFilteredDatasets(filteredItems);
  }, []);

  const searchableProps = useMemo(
    () => ['name', 'description', 'data_count', 'is_root', 'data_type', 'data_source', 'created_at'],
    []
  );

  useEffect(() => {
    const fetchDatasets = async () => {
      if (user.state === 'LoggedIn') {
        try {
          const response = await db.endpoints.testsuite.testsuiteApiGetUserDatasetsRetrieve();
          console.log(response);
          setDatasets(response.data);
          setFilteredDatasets(response.data); // Initialize filtered data
        } catch (error) {
          console.error('Error fetching datasets:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchDatasets();
  }, [user]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onDatasetCreated = (newDataset: DatasetType) => {
    console.log(newDataset);
    navigate(`/dataset/${newDataset.id}`);
    toggleModal();
  };


  const handleDatasetCardClick = (datasetId: number) => {
    navigate(`/dataset/${datasetId}`);
  };

  const handleParentDatasetClick = (datasetId: number) => {
    navigate(`/dataset/${datasetId}`);
  };

  if (isLoading) {
    return <FullPageLoader />;
  }

  return (
    <div className="h-full rounded bg-gray-100">
      <BackButton />
      <div className="container mx-auto py-12">
        <h1 className="text-4xl font-bold text-center mb-8">Your Datasets</h1>
        <Searchbar<DatasetType>
          items={datasets} // Pass original data here
          searchableProperties={searchableProps}
          onFilterChange={handleFilterChange}
        />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <button
            onClick={toggleModal} // Toggle modal on click
            className="block p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 flex items-center justify-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-12 w-12 text-gray-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4v16m8-8H4"
              />
            </svg>
          </button>

          {isModalOpen && (
            <Modal isOpen={isModalOpen} onClose={toggleModal}>
              <CreateDataset onDatasetCreated={onDatasetCreated} />
            </Modal>
          )}

          {filteredDatasets.map((dataset) => ( // Use filtered data here
              <DatasetCard dataset={dataset} onCardClick={handleDatasetCardClick} onParentDatasetClick={handleParentDatasetClick} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Datasets;
