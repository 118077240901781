// Edit Experiment by specifying a series of operations
// Each operation has at least one input dataset, and exactly one output dataset
// An output dataset can be used as input for the next operation
// There are several operation types
// It starts with a landing page that shows the name and description of the experiment on the left, which are editable in a modal dialog
// On the right is a list panel showing the operations of the experiment, which are fetched from the backend
// When clicking the plus button on the Operation grid, the OperationType library opens (this will be a component for now it is a modal dialog with only one option (LLM operation).
// On select an operation block is added to the Operations grid. An LLM operation block has 2 input dataset blocks, which at least one is required, one system prompt template block and one user prompt template block. One Dataset is connected to each prompt which means there is a 1-1-1 connection of Dataset to PromptTemplate
// To add a dataset to an input of an operation, click the plus button on the input dataset block, the DatasetLibrary opens, one select a dataset and it is added to the input dataset of the operation, the DatasetLibrary closes
// The same process is used to add a dataset to the output of an operation
// The system and user prompt template blocks have a plus button to open the PromptTemplateLibrary component. On selection of a prompt template the prompt template block is filled with the prompt template name and first 100 characters of the prompt and the PromptTemplateLibrary closes
// When the user clicks an existing prompt the EditPromptTemplate component opens instead of the PromptTemplateLibrary
// If an operation has at least one input dataset and one output dataset and both prompts are set, the save button on the bottom is enabled and the user can click it to save the operation.
// A Saved operation collapses all of its content to only show the operation type and its inputs and outputs, together with a run button, a delete button. Clicking anywhere else than the deletion or run button opens the operation again for editing.
// Existing operations can be reordered by clicking and dragging them on the grid

import React, { useState, useEffect } from 'react';
import { useOasisBackend } from '../../hooks/useOasisBackend';
import { Dataset as DatasetType, FullOperation as OperationType, FullExperiment as ExperimentType, FullPromptTemplate as PromptTemplateType, FullPromptTemplate } from '../../api/OasisBackendApi';

import SubmitButton from '../components/SubmitButton';
import { useParams } from 'react-router-dom';
import { FullPageLoader } from '../components/loader';
import ExperimentCard from '../components/Cards/ExperimentCard';
import Modal from '../components/modal';
import OperationsCard from '../components/Cards/OperationsCard';
import EditingOperationsCard from '../components/Cards/EditingOperationsCard';
import { EditOperationProvider } from '../context/EditOperationContext';
import LibraryPanel from '../components/LibraryPanel';


const ManageExperiments: React.FC = () => {
  const [experiment, setExperiment] = useState<ExperimentType>();
  const [operations, setOperations] = useState<OperationType[]>([]);
  const [selectedOperation, setSelectedOperation] = useState<OperationType | null>(null);
  const [editedOperation, setEditedOperation] = useState<OperationType | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmittingEdit, setIsSubmittingEdit] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editedExperiment, setEditedExperiment] = useState<ExperimentType>({} as ExperimentType);
  const [isExecutionClassModalOpen, setIsExecutionClassModalOpen] = useState(false);
  const [selectedExecutionClass, setSelectedExecutionClass] = useState<string>('OpenRouterLLMOperation');
  const db = useOasisBackend();
  const [libraryPanelType, setLibraryPanelType] = useState<'dataset' | 'prompt_template' | null>(null);
  const [currentLibraryStep, setCurrentLibraryStep] = useState<string | null>(null);
  const [currentLibraryTarget, setCurrentLibraryTarget] = useState<keyof OperationType>();

  const { experimentId } = useParams<{ experimentId: string }>();
  const [executionClasses, setExecutionClasses] = useState<string[]>([]);

  const [lastRefreshTime, setLastRefreshTime] = useState<Date>(new Date());
  const [, setTimeUpdateTrigger] = useState<number>(0);

  const getAndSetExecutionClasses = () => {
    db.endpoints.testsuite.testsuiteApiGetExecutionRegistryRetrieve().then((response) => {
      setExecutionClasses(response.data);
    });
  }

  useEffect(() => {
    getAndSetExecutionClasses();
  }, [])



  // Experimeriment ------------------------------------------------------------

  useEffect(() => {
    const fetchExperiment = async () => {
      if (experimentId) {
        try {
          const response = await db.endpoints.testsuite.testsuiteApiGetFullExperimentRetrieve(experimentId);
          setExperiment(response.data as ExperimentType);
          setEditedExperiment(response.data as ExperimentType);
          setOperations(response.data.operations || []);
        } catch (error) {
          console.error('Error fetching experiment:', error);
          // Handle error (e.g., show error message, redirect)
        }
      }
    };

    fetchExperiment();
  }, [experimentId, db.endpoints.testsuite]);

  // Add this useEffect for auto-refresh
  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const autoRefresh = async () => {
      if (!isEditing && experimentId) {
        try {
          const response = await db.endpoints.testsuite.testsuiteApiGetFullExperimentRetrieve(experimentId);
          setExperiment(response.data as ExperimentType);
          setEditedExperiment(response.data as ExperimentType);
          setOperations(response.data.operations || []);
          setLastRefreshTime(new Date());
        } catch (error) {
          console.error('Error auto-refreshing experiment:', error);
        }
      }
    };

    // Start the interval when not editing
    if (!isEditing) {
      intervalId = setInterval(autoRefresh, 20000); // 20 seconds
    }

    // Cleanup function to clear the interval
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [experimentId, isEditing, db.endpoints.testsuite]);

  // Add this new useEffect for live time updates
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeUpdateTrigger(prev => prev + 1); // Force re-render every second
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);
  

  const handleEditSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmittingEdit(true);
    if (experimentId) {
      try {

        const response = await db.endpoints.testsuite.testsuiteApiUpdateExperimentUpdate(experimentId, {name: editedExperiment.name, description: editedExperiment.description});
        setExperiment(response.data as ExperimentType);
        setIsEditModalOpen(false);
      } catch (error) {
        console.error('Error updating experiment:', error);
        alert('Error updating experiment:');
        // Handle error (e.g., show error message)
      }
    } else {
      console.error('Experiment ID is undefined');
      alert('Experiment ID is undefined');
      // Handle the case where experimentId is undefined
    }
    setIsSubmittingEdit(false);
  };

  const handleEditChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setEditedExperiment(prev => ({ ...prev, [name]: value }));
  };

  const handleEditStart = () => {
    setIsEditing(true);
    setIsEditModalOpen(true);
  };

  if (!experiment) {
    return <FullPageLoader />;
  }


  // Operations ----------------------------------------------------------------

  const handleCreateOperation = () => {
    setIsExecutionClassModalOpen(true);
  };

  const handleExecutionClassSelect = async () => {
    try {
      const response = await db.endpoints.testsuite.testsuiteApiCreateOperationCreate({
        name: 'New ' + selectedExecutionClass,
        description: 'does something',
        experiment: experiment.id,
        execution_class: selectedExecutionClass,
        experiment_order: operations.length,
      });
      setOperations([...operations, response.data]);
      setIsExecutionClassModalOpen(false);
    } catch (error) {
      console.error('Error creating operation:', error);
      alert('Error creating operation:');
    }
  };

  const handleEditOperation = (operation: OperationType) => {
    console.log("Edit", operation, selectedOperation, isEditing);
    if (selectedOperation) {
      setSelectedOperation(operation);
      setIsEditing(true);
    } else if (operation.status === 'RUNNING') {
      // alert the user that the operation is of status X and cannot be edited
      alert('Operation is of status ' + operation.status + ' and cannot be edited');
    } else {
      setSelectedOperation(operation);
      setIsEditing(true);
    }
  };

  const handleSaveOperation = (operation: OperationType) => {
    setSelectedOperation(null);
    setIsEditing(false);
    setOperations(operations.map(op => op.id === operation.id ? operation : op));
  };
  
    const handleCancelEditingOperation = () => {
      setSelectedOperation(null);
      setIsEditing(false);
    };

  const handleDeleteOperation = async (operation: OperationType) => {
    if (window.confirm('Are you sure you want to delete this operation?')) {
      await db.endpoints.testsuite.testsuiteApiDeleteOperationDestroy(operation.id.toString());
      setOperations(operations.filter(op => op.id !== operation.id));
      setSelectedOperation(null);
      setIsEditing(false);
    }
  };

  const pollOperation = async (operation_id: number) => {
    const poll = async () => {
      try {
        const response = await db.endpoints.testsuite.testsuiteApiGetFullOperationRetrieve(operation_id.toString());
        
        // Update the operation state using a functional update to ensure the latest state is used
        setOperations(prevOperations =>
          prevOperations.map(op => (op.id === operation_id ? response.data : op))
        );
        
        if (response.data.status === 'COMPLETED' || response.data.status === 'FAILED') {
          // Stop polling if the operation is completed or failed
          return;
        } else {
          // Poll again after a delay (e.g., 2 seconds)
          setTimeout(poll, 2000);
        }
      } catch (error) {
        console.error(`Error polling operation ${operation_id}:`, error);
        // Optionally, you can stop polling or retry based on the error
      }
    };
  
    // Start the polling process
    poll();
  };


  const handleRunOperation = async (operation: OperationType) => {
    setSelectedOperation(operation);
    if(operation.llm_jobs && operation.llm_jobs.length > 0) {
      if(!window.confirm("This operation has been run before. Re-running with destroy the old LLM Jobs and data associated. Are you sure?")){
        return;
      }
    }

    const response = await db.endpoints.testsuite.testsuiteApiRunOperationCreate(operation.id.toString(), operation as OperationType);
    setOperations(operations.map(op => op.id === operation.id ? response.data : op));
    pollOperation(operation.id);
    setIsEditing(false);
  };

  const handleRepairOperation = async (operation: OperationType) => {
    const response = await db.endpoints.testsuite.testsuiteApiTryToRepairOperationCreate(operation.id.toString(), operation);
    if(response.status === 200) {
      setOperations(operations.map(op => op.id === operation.id ? response.data : op));
    } else {
      alert('Error repairing operation');
    }
  };




  const handleCloneOperation = async (operation: OperationType) => {
    try {
      const response = await db.endpoints.testsuite.testsuiteApiCloneOperationCreate(operation.id.toString(), operation);
      setOperations([...operations, response.data]);
    } catch (error) {
      console.error('Error cloning operation:', error);
      alert('Error cloning operation');
    }
  };

  const handleRunNext = async (operation: OperationType) => {
    try {
      const response = await db.endpoints.testsuite.testsuiteApiNextOperationCreate(operation.id.toString(), operation);
      setOperations([...operations, response.data]);
    } catch (error) {
      console.error('Error running next operation:', error);
      alert('Error running next()');
    }
  };

  const handleCancelOperation = async (operation: OperationType) => {
    if (window.confirm('Are you sure you want to cancel this operation?')) {
      try {
        const response = await db.endpoints.testsuite.testsuiteApiCancelOperationCreate(operation.id.toString(), operation);
        setOperations(operations.map(op => op.id === operation.id ? response.data : op));
      } catch (error) {
        console.error('Error cancelling operation:', error);
        alert('Error cancelling operation');
      }
    }
  };

  // Library Panel ------------------------------------------------------------

  const openLibraryPanel = (type: 'dataset' | 'prompt_template', step: string, target: keyof OperationType) => {
    setLibraryPanelType(type);
    setCurrentLibraryStep(step);
    setCurrentLibraryTarget(target);
  };

  const closeLibraryPanel = () => {
    setLibraryPanelType(null);
    setCurrentLibraryStep(null);
    setCurrentLibraryTarget(undefined);
  };

  const handleRefresh = async () => {
    if (experimentId) {
      try {
        const response = await db.endpoints.testsuite.testsuiteApiGetFullExperimentRetrieve(experimentId);
        setExperiment(response.data as ExperimentType);
        setEditedExperiment(response.data as ExperimentType);
        setOperations(response.data.operations || []);
        setLastRefreshTime(new Date());
      } catch (error) {
        console.error('Error refreshing experiment:', error);
      }
    }
  };

  const getTimeAgoString = (date: Date) => {
    const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
    return `${seconds} seconds ago`;
  };


  return (
    <>
    <EditOperationProvider>
      <div className="flex flex-col h-full max-h-screen overflow-hidden">
        <div className="sticky top-0 z-50 w-full bg-white shadow-md p-2">
          <div className="text-sm text-gray-600 cursor-pointer" onClick={handleRefresh}>
            Last refreshed: {getTimeAgoString(lastRefreshTime)}
            {isEditing && " (Auto-refresh paused during editing)"}
          </div>
        </div>
        <div className="flex overflow-hidden pt-4">
          <div className="w-1/2 m-2 max-h-full">
            {(libraryPanelType && currentLibraryTarget && selectedOperation) ? (
              <LibraryPanel
                type={libraryPanelType}
                step={currentLibraryStep || 'default'}
                target={currentLibraryTarget}
                onClose={closeLibraryPanel}
              />
            ) : (
              <ExperimentCard experiment={experiment} onCardClick={handleEditStart} />
            )}
          </div>
          <div className="flex-1 flex flex-col h-full overflow-y-scroll justify-start items-center p-4 m-2 bg-gray-100 rounded-lg">
            <h1 className='text-2xl font-bold'>Operations:</h1>
              {operations.map((operation) => (
                isEditing && selectedOperation && selectedOperation.id === operation.id ? (
                    <EditingOperationsCard 
                      operation={operation} 
                      onSave={handleSaveOperation} 
                      onCancel={handleCancelEditingOperation} 
                      onOpenLibraryPanel={openLibraryPanel}
                      className="mb-3" 
                    />
                ) : (
                  <OperationsCard 
                    key={operation.id}
                    operation={operation} 
                    onCardClick={() => handleEditOperation(operation)} 
                    buttons={[
                      {
                        label: 'Run',
                        onClick: () => handleRunOperation(operation)
                      },
                      {
                        label: 'Repair',
                        onClick: () => handleRepairOperation(operation) 
                      },
                      {
                        label: 'Delete',
                        onClick: () => handleDeleteOperation(operation)
                      },
                      {
                        label: 'Clone',
                        onClick: () => handleCloneOperation(operation)
                      },
                      {
                        label: 'Cancel',
                        onClick: () => handleCancelOperation(operation)
                      },
                      {
                        label: "Run Next",
                        onClick: () => handleRunNext(operation)
                      }
                    ]}
                    className="mb-3" 
                  />
                )
              ))}
              <div 
                className="flex justify-center items-center w-full h-24 bg-white shadow-md rounded-lg cursor-pointer hover:shadow-xl transition-shadow duration-200 mt-4"
                onClick={handleCreateOperation}
                >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                </svg>
              </div>
          </div>
        </div>
        

        

      {/* Edit Experiment Modal */}
      <Modal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
        <h2 className="text-2xl font-bold mb-4">Edit Experiment</h2>
        <form onSubmit={handleEditSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Experiment Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={editedExperiment.name}
              onChange={handleEditChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
              />
          </div>
          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <textarea
              id="description"
              name="description"
              value={editedExperiment.description}
              onChange={handleEditChange}
              rows={3}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
              ></textarea>
          </div>
          <SubmitButton isSubmitting={isSubmittingEdit} submitText="Save Changes" loadingText="Saving..." />
        </form>
      </Modal>

      {/* Execution Class Modal */}
      <Modal isOpen={isExecutionClassModalOpen} onClose={() => setIsExecutionClassModalOpen(false)} size="medium">
        <h2 className="text-xl font-bold mb-4">Select Execution Class</h2>
        <button onClick={getAndSetExecutionClasses}>Refresh Execution Class Options</button>
        <select
          value={selectedExecutionClass}
          onChange={(e) => setSelectedExecutionClass(e.target.value)}
          className="w-full p-2 border rounded"
          >
          {Object.values(executionClasses).map((executionClass) => (
            <option key={executionClass} value={executionClass}>
              {executionClass}
            </option>
          ))}
        </select>
        <button
          onClick={handleExecutionClassSelect}
          className="mt-4 bg-gray-500 text-white p-2 rounded"
          >
          Create Operation
        </button>
      </Modal>
      </div>
    </EditOperationProvider>
    </>
  );
};

export default ManageExperiments;
