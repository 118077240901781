/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css } from '@emotion/react';
import useChat from '../../hooks/useChat'; // Adjust the path as necessary
import { useSearchParams } from 'react-router-dom';

interface ChatComponentProps {
}

const ChatComponent: React.FC<ChatComponentProps> = ({  }) => {
    const [input, setInput] = useState<string>("");
    const [searchParams, setSearchParams] = useSearchParams();
    const conversationId = searchParams.get("chatId");
    const { messages, animatedText, isComplete, sendMessage } = useChat(conversationId, (functionDefinition) => console.log(functionDefinition));


    const handleSendMessage = () => {
        sendMessage(input);
        setInput("");
    };

    return (
        <div className="flex flex-col p-4 bg-gray-100 rounded-md">
            <div className="flex-grow overflow-y-auto mb-4">
                {messages.map((message, index) => (
                    <p key={index} className="text-gray-800 mb-2">{message.content}</p>
                ))}
                {!isComplete && (
                    <p
                        className="text-gray-800"
                        css={css`
                            font-family: monospace;
                            display: inline-block;
                            white-space: pre;
                            border-right: 2px solid black;
                            animation: blink-caret 0.75s step-end infinite;

                            @keyframes blink-caret {
                                from, to { border-color: transparent; }
                                50% { border-color: black; }
                            }
                        `}
                    >
                        {animatedText}
                    </p>
                )}
            </div>
            <div className="flex">
                <input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder="Type your message..."
                    className="flex-grow p-2 border border-gray-300 rounded-md mr-2"
                />
                <button
                    onClick={handleSendMessage}
                    className="bg-blue-500 text-white p-2 rounded-md"
                >
                    Send
                </button>
            </div>
        </div>
    );
};

export default ChatComponent;
