import React, { useState, useEffect, useRef } from 'react';
import SubmitButton from './SubmitButton';
import { FullPromptTemplate, FullRequestParams, FullPromptTemplate as PromptTemplateType, TypeEnum, Datum as DatumType, OutputTypeEnum} from '../../api/OasisBackendApi';
import { useOasisBackend } from '../../hooks/useOasisBackend';
import Modal from './modal';
import PromptInjectionPreview from './PromptInjectionPreview';
import AutocompleteDropdown from './AutocompleteDropdown';

interface EditPromptTemplateComponentProps {
  Template : PromptTemplateType;
  type?: 'system_prompt_template' | 'user_prompt_template';
  onSave: (prompt: PromptTemplateType) => void;
  onCancel: () => void;
  onChooseDiffernetTemplate?: () => void;
}


const EditPromptTemplateComponent: React.FC<EditPromptTemplateComponentProps> = ({ Template, type, onSave, onCancel, onChooseDiffernetTemplate }) => {
  const [promptTemplate, setPromptTemplate] = useState<PromptTemplateType>(Template);
  const [isChanged, setIsChanged] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const db = useOasisBackend();
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [autocompleteOptions, setAutocompleteOptions] = useState<string[]>([]);
  const [cursorScreenPosition, setCursorScreenPosition] = useState<{top: number, left: number} | undefined>();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [lastAtPosition, setLastAtPosition] = useState<number | null>(null);
  const [filterText, setFilterText] = useState('');

  if (!promptTemplate) {
    return null;
  }

  if (type === 'system_prompt_template') {
    promptTemplate.type = TypeEnum.SYSTEM;
  } else if (type === 'user_prompt_template') {
    promptTemplate.type = TypeEnum.USER;
  }

  const handleChange = (field: keyof PromptTemplateType, value: string) =>  {
    
    setPromptTemplate({ ...promptTemplate, [field]: value });
    setIsChanged(true);
  };
  console.log("Prompt Template: ", promptTemplate.dataset)

  const handleSave = async () => {
    setIsSubmitting(true);
    console.log('setting is submitting to true');
    try {
      if (promptTemplate.id === -1) {
        const new_prompt_template = {
          name: promptTemplate.name,
          description: promptTemplate.description,
          type: promptTemplate.type || TypeEnum.USER, // Provide a default value
          prompt: promptTemplate.prompt || '',
          dataset: promptTemplate.dataset?.id,
          output_type: promptTemplate.output_type,
        }
        const response = await db.endpoints.testsuite.testsuiteApiCreatePromptTemplateCreate(new_prompt_template);
        onSave(response.data as FullPromptTemplate);
      } else {
        onSave(promptTemplate);
      }
    } catch (error) {
      console.error("Error saving prompt template:", error);
      // Optionally, you can add error handling here (e.g., show an error message to the user)
    } finally {
      setIsSubmitting(false);
      console.log('setting is submitting to false');
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const handlePromptClick = (e: React.MouseEvent<HTMLTextAreaElement>) => {
    const textarea = e.currentTarget;
    const cursorPos = textarea.selectionStart;
    const text = textarea.value;

    const bracketRegex = /{{(\w+)}}/g;
    let match: RegExpExecArray | null;

    while ((match = bracketRegex.exec(text)) !== null) {
      if (cursorPos >= match.index && cursorPos <= match.index + match[0].length) {
        const propertyName = match[1];
        const newText = text.slice(0, match.index) + '@' + propertyName + text.slice(match.index + match[0].length);
        handleChange('prompt', newText);

        // Set cursor position to select the property name
        setTimeout(() => {
          textarea.selectionStart = match!.index + 1;
          textarea.selectionEnd = match!.index + propertyName.length + 1;
          textarea.focus();
        }, 0);

        // Show autocomplete dropdown
        setShowAutocomplete(true);
        setLastAtPosition(match.index);
        if (promptTemplate.dataset?.sample_datum?.content) {
          const properties = Object.keys(promptTemplate.dataset.sample_datum.content);
          setAutocompleteOptions(properties);
        }
        if (textareaRef.current) {
          setCursorScreenPosition(getCursorCoordinates(textareaRef.current, match.index + 1));
        }
        break;
      }
    }
  };

  const handlePromptChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    handleChange('prompt', value);

    const cursorPos = e.target.selectionStart;
    const textBeforeCursor = value.slice(0, cursorPos);
    const lastAtSymbolIndex = textBeforeCursor.lastIndexOf('@');

    if (lastAtSymbolIndex !== -1 && cursorPos - lastAtSymbolIndex <= 20) {
      const textAfterAt = textBeforeCursor.slice(lastAtSymbolIndex + 1);
      const isNewAt = lastAtSymbolIndex !== lastAtPosition;

      if (isNewAt || (textAfterAt.length > 0 && !textAfterAt.includes(' '))) {
        setShowAutocomplete(true);
        setLastAtPosition(lastAtSymbolIndex);
        setFilterText(textAfterAt);

        if (promptTemplate.dataset?.sample_datum?.content) {
          const properties = Object.keys(promptTemplate.dataset.sample_datum.content);
          setAutocompleteOptions(properties);
        } else {
          setAutocompleteOptions([]);
        }
        
        // Calculate cursor position for dropdown
        if (textareaRef.current) {
          setCursorScreenPosition(getCursorCoordinates(textareaRef.current, cursorPos));
        }
      } else {
        setShowAutocomplete(false);
      }
    } else {
      setShowAutocomplete(false);
      setLastAtPosition(null);
    }
  };

  const handleAutocompleteSelect = (option: string) => {
    const currentPrompt = promptTemplate.prompt || '';
    const cursorPos = textareaRef.current?.selectionStart || 0;
    const textBeforeCursor = currentPrompt.slice(0, cursorPos);
    const lastAtSymbolIndex = textBeforeCursor.lastIndexOf('@');
    
    const newPrompt = 
      currentPrompt.slice(0, lastAtSymbolIndex) + 
      `{{${option}}}` + 
      currentPrompt.slice(cursorPos);
    
    handleChange('prompt', newPrompt);
    setShowAutocomplete(false);
  }; 

  const getCursorCoordinates = (textarea: HTMLTextAreaElement, cursorPos: number) => {
    const textBeforeCursor = textarea.value.slice(0, cursorPos);
    const lines = textBeforeCursor.split('\n');
    const lineHeight = parseInt(getComputedStyle(textarea).lineHeight);
    
    // Get the textarea's position relative to the viewport
    const rect = textarea.getBoundingClientRect();
    
    // Calculate position relative to the screen
    const top = rect.top + lines.length * lineHeight;
    const left = rect.left + (lines[lines.length - 1].length % textarea.cols) * 8; // Assuming 8px per character
    console.log("Top: ", top, "Left: ", left);
    return { top, left };
  };

  return (
    <div>
        {onChooseDiffernetTemplate && (
          <button onClick={onChooseDiffernetTemplate}>Choose different template</button>
        )}
    <div className="flex flex-col gap-4">
      <form className="flex flex-col gap-3" onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
        <input
          type="text"
          value={promptTemplate.name}
          onChange={(e) => handleChange('name', e.target.value)}
          placeholder="Name"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
        <textarea
          value={promptTemplate.description ?? ''}
          onChange={(e) => handleChange('description', e.target.value)}
          placeholder="Description"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
        <div>
          <select
            value={promptTemplate.type || ''}
            onChange={(e) => handleChange('type', e.target.value as TypeEnum)}
            disabled={type !== undefined}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="SYSTEM">SYSTEM</option>
            <option value="USER">USER</option>
          </select> 
        </div>
        <div>
          <select
            value={promptTemplate.output_type || 'STRING'}
            onChange={(e) => handleChange('output_type', e.target.value as OutputTypeEnum)}
            disabled={type !== undefined}
          >
            <option value="STRING">STRING</option>
            <option value="JSON">JSON</option>
          </select> 
        </div>
        <div className="relative">
          <textarea
            ref={textareaRef}
            value={promptTemplate.prompt ?? ''}
            onChange={handlePromptChange}
            onClick={handlePromptClick}
            placeholder="Prompt"
            rows={10}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
          {showAutocomplete && cursorScreenPosition && (
            <AutocompleteDropdown
              options={autocompleteOptions}
              onSelect={handleAutocompleteSelect}
              position={cursorScreenPosition}
              filterText={filterText}
            />
          )}
        </div>
        <div className="flex justify-between mt-4">
          <button
            type="button"
            onClick={() => setIsPreviewModalOpen(true)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Preview
          </button>
          <SubmitButton
            isSubmitting={isSubmitting}
            submitText="Save"
            loadingText="Saving..."
            onClick={handleSave}
          />
        </div>
      <button onClick={handleCancel} className="mt-2 text-gray-600 hover:text-gray-800">Cancel</button>
      <Modal isOpen={isPreviewModalOpen} onClose={() => setIsPreviewModalOpen(false)}>
        <PromptInjectionPreview
          promptText={promptTemplate.prompt ?? 'No prompt text provided'}
          datasetId={promptTemplate.dataset?.id.toString() ?? ''}
          promptType={promptTemplate.type || 'USER'}
          outputType={(promptTemplate.output_type === OutputTypeEnum.JSON ? 'JSON' : 'TEXT') as 'JSON' | 'TEXT'}
        />
      </Modal>
      </form>
    </div>
    </div>
  );
};

export default EditPromptTemplateComponent;
