import React, { useState, useEffect } from 'react';
import { useOasisBackend } from '../../hooks/useOasisBackend';
import { FullRequestParams } from '../../api/OasisBackendApi';
import { ContainerLoader } from './loader';
import SubmitButton from './SubmitButton';
import { set } from 'lodash';

interface PromptInjectionPreviewProps {
  datasetId: string;
  promptText: string;
  promptType: 'SYSTEM' | 'USER';
  outputType: 'TEXT' | 'JSON';
}

const PromptInjectionPreview: React.FC<PromptInjectionPreviewProps> = ({ datasetId, promptText, promptType, outputType }) => {
  const db = useOasisBackend();
  const [currentDatum, setCurrentDatum] = useState<any>(null);
  const [currentDatumIndex, setCurrentDatumIndex] = useState(0);
  const [injectedPrompt, setInjectedPrompt] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchDatum();
  }, []);

  useEffect(() => {
    if (currentDatum) {
        renderPrompt();
    }
  }, [currentDatum, promptText]);

  const fetchDatum = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await db.endpoints.testsuite.testsuiteApiGetDatasetDatumRetrieve(datasetId, {
        path: `/testsuite/api/get-dataset-datum/${datasetId}/`,
        query: {
          index: currentDatumIndex
        }
      } as FullRequestParams);
      setCurrentDatum(response.data);
    } catch (error) {
      console.error('Error fetching datum:', error);
      setError('Failed to fetch data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleNextDatum = async () => {
    setCurrentDatumIndex(prev => prev + 1);
    setCurrentDatum(null);
    await fetchDatum();
  };

  const renderPrompt = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await db.endpoints.testsuite.testsuiteApiTestRenderPromptCreate({
        prompt: promptText,
        datum: currentDatum.content,
        type: promptType,
        output_type: outputType
      });
      setInjectedPrompt(response.data);
    } catch (error) {
      console.error('Error rendering prompt:', error);
      setError('Error in template syntax. Please check your prompt text.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-xl font-semibold mb-4">Prompt Injection Preview</h2>
      {injectedPrompt.length === 0 && isLoading ? (
        <ContainerLoader />
      ) : error ? (
        <div className="text-red-500">{error}</div>
      ) : (
        <>
          <form className="flex flex-col align-end">
          <SubmitButton
            isSubmitting={isLoading}
            submitText="Next Datum"
            loadingText="Loading..."
            type='button'
            onClick={handleNextDatum}
            />
            <div className="bg-gray-100 p-4 rounded-md mb-4">
              <pre className="whitespace-pre-wrap">{injectedPrompt}</pre>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default PromptInjectionPreview;
