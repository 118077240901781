import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

interface AutocompleteDropdownProps {
  options: string[];
  onSelect: (option: string) => void;
  position: { top: number; left: number };
  filterText: string;
}

const AutocompleteDropdown: React.FC<AutocompleteDropdownProps> = ({ options, onSelect, position, filterText }) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState<number>(100);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [filteredOptions, setFilteredOptions] = useState<string[]>(options);

  useEffect(() => {
    if (dropdownRef.current) {
      const windowHeight = window.innerHeight;
      const spaceBelow = windowHeight - position.top;
      setMaxHeight(Math.min(spaceBelow - 10, 300)); // Max height of 300px or available space minus 10px padding
    }

    // Filter options based on filterText
    const filtered = options.filter(option => 
      option.toLowerCase().includes(filterText.toLowerCase())
    );
    setFilteredOptions(filtered);
    setSelectedIndex(0); // Reset selected index when options change

    const handleKeyDown = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          setSelectedIndex((prevIndex) => (prevIndex + 1) % filtered.length);
          break;
        case 'ArrowUp':
          e.preventDefault();
          setSelectedIndex((prevIndex) => (prevIndex - 1 + filtered.length) % filtered.length);
          break;
        case 'Enter':
          e.preventDefault();
          if (filtered.length > 0) {
            onSelect(filtered[selectedIndex]);
          }
          break;
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [position.top, options, selectedIndex, onSelect, filterText]);

  const dropdownContent = (
    <div
      ref={dropdownRef}
      className="fixed z-50 bg-white border border-gray-300 rounded shadow-lg overflow-auto"
      style={{
        top: `${position.top}px`,
        left: `${position.left}px`,
        maxHeight: `${maxHeight}px`,
        minWidth: '200px',
      }}
    >
      {filteredOptions.length > 0 ? (
        filteredOptions.map((option, index) => (
          <div
            key={index}
            className={`px-4 py-2 cursor-pointer ${index === selectedIndex ? 'bg-blue-100' : 'hover:bg-gray-100'}`}
            onClick={() => onSelect(option)}
          >
            {option}
          </div>
        ))
      ) : (
        <div className="px-4 py-2 text-gray-500">No matches found</div>
      )}
    </div>
  );

  return createPortal(dropdownContent, document.body);
};

export default AutocompleteDropdown;