import BackButton from "../components/ui/back_button";


const PrivacyPolicy = () => {
    return (
        <div className="p-5 py-12 mt-10 md:mt-18 flex flex-col gap-3 overflow-scroll">
            <BackButton />

            <h1 className="text-3xl">Privacy Policy</h1>

            <h3 className="text-lg">Effective Date: 11/22/2023</h3>

            <div>Welcome to <a className="inline w-fit" href="https://thoughtful-oasis.com">thoughtful-labs.com.com</a>. This policy outlines how we collect, use, disclose, and safeguard your personal data when you interact with our AI chat service.</div>

            <h2 className="text-2xl">1. Information We Collect</h2>

            We collect information that helps us deliver and improve our services. This includes:

            <div>- Account Information: When you create an account, we collect basic information such as your username and contact details.</div>
            <div>- Chat Data: We store details of the conversations you have with our AI, including the messages you send and receive.</div>
            <div>- Subscription and Transaction Details: If you subscribe to our services, we collect information related to your subscription and transactions.</div>
            <div>- Usage Data: We gather data about how you interact with our services, which helps us tailor your experience.</div>

            <h2 className="text-2xl">2. How We Use Your Information</h2>

            Your information is used for the following purposes:

           <div> - To provide and manage our AI chat service.</div>
           <div> - To improve the functionality and user experience of our service.</div>
           <div> - For account management and communication purposes.</div>
            <div>- To process transactions and maintain a record of your subscription.</div>

            <h2 className="text-2xl">3. Sharing of Your Information</h2>

            We do not sell or rent your personal information to third parties. However, we may share your information with:

            <div>- Service providers who assist us in offering our services.</div>
            <div>- Legal authorities, if required by law or to protect our rights.</div>

            <h2 className="text-2xl">4. Data Security</h2>

            We take the security of your data seriously. We implement various security measures to protect against unauthorized access or misuse of your personal data.

            <h2 className="text-2xl">5. Your Rights</h2>

            You have the right to access, correct, or delete your personal information. Please contact us if you wish to exercise these rights.

            <h2 className="text-2xl">6. Changes to This Policy</h2>

            We may update this policy from time to time. We will notify you of any changes by posting the new policy on this page.

            <h2 className="text-2xl">8. Full User Data Deletion</h2>

            For full account and data deletion reach out to thoughtful-oasis@gmail.com and we will process your request and a summary of deleted data.

            <h2 className="text-2xl">9. Contact Us</h2>

            If you have any questions about this policy, please contact us at thoughtful-oasis@gmail.com
        </div>)
}

export default PrivacyPolicy;