import React, { useState, useEffect } from 'react';
import { useEditOperation } from '../context/EditOperationContext';
import DatasetLibrary from './Libraries/DatasetLibrary';
import PromptTemplateLibrary from './Libraries/PromptTemplateLibrary';
import EditPromptTemplate from './EditPromptTemplate';
import { Dataset, PromptTemplate, FullPromptTemplate, FullOperation, TypeEnum } from '../../api/OasisBackendApi';
import { useOasisBackend } from '../../hooks/useOasisBackend';

interface LibraryPanelProps {
  type: 'dataset' | 'prompt_template';
  step: string;
  target: keyof FullOperation | `params.${string}`;
  onClose: () => void;
}

const LibraryPanel: React.FC<LibraryPanelProps> = ({ type, step, target, onClose }) => {
  const [currentStep, setCurrentStep] = useState<string>(step);
  const [selectedDataset, setSelectedDataset] = useState<Dataset | null>(null);
  const [selectedPromptTemplate, setSelectedPromptTemplate] = useState<PromptTemplate | null>(null);
  const { editedOperation, updateEditedOperation } = useEditOperation();
  const db = useOasisBackend();


  if (editedOperation && target.startsWith('params.')) {
    if (!editedOperation.params) {
      const updatedOperation = { ...editedOperation } as FullOperation;
      updatedOperation.params = {};
      updateEditedOperation(updatedOperation);
    }
  }
  if (editedOperation && editedOperation[target as keyof FullOperation] === null) {
    if (type === 'prompt_template') {
      const newPromptTemplate: FullPromptTemplate = {
        id: -1,
        name: '',
        description: '',
        prompt: '',
        dataset: {} as Dataset,
        type: TypeEnum.SYSTEM,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      };
      const updatedOperation = { ...editedOperation } as FullOperation;
      (updatedOperation[target as keyof FullOperation] as FullPromptTemplate) = newPromptTemplate;
      updateEditedOperation(updatedOperation);
      console.log(editedOperation);
    }
  } else {
    console.log(editedOperation);
  }



  const renderStep = () => {
    switch (type) {
      case 'dataset':
        return <DatasetLibrary onSelect={handleDatasetSelect} />;
      case 'prompt_template':
        switch (currentStep) {
          case 'new':
            return <DatasetLibrary onSelect={handleDatasetSelect} />;
          case 'dataset':
            return <DatasetLibrary onSelect={handleDatasetSelect} />;
          case 'prompt':
            return <PromptTemplateLibrary onSelect={handlePromptTemplateSelect}/>;
          case 'edit':
            if (editedOperation && target in editedOperation) {
                const template = editedOperation[target as keyof FullOperation] as FullPromptTemplate;
                return <EditPromptTemplate Template={template} onSave={handlePromptTemplateSave} onCancel={handlePromptEditCancel} onChooseDiffernetTemplate={handleChooseDifferentTemplate} />;
            }
            return null;
          default:  
            return null;
        }
      default:  
        return null;
    }
  };

  const handleDatasetSelect = (dataset: Dataset) => {
    setSelectedDataset(dataset);
    if (type === 'dataset') {
      handleSave(dataset);
    } else if (type === 'prompt_template') {
      if ((editedOperation && target in editedOperation && (editedOperation[target as keyof FullOperation] as FullPromptTemplate).id === -1)) {
        setCurrentStep('prompt');
      } else {
        setCurrentStep('edit');
      }
      const updatedOperation = { ...editedOperation } as FullOperation;
      (updatedOperation[target as keyof FullOperation] as FullPromptTemplate).dataset = dataset;
      updateEditedOperation(updatedOperation);
    }
  };

  const handlePromptTemplateSelect = (promptTemplate: PromptTemplate) => {

    setSelectedPromptTemplate(promptTemplate);
    if (editedOperation && target in editedOperation && editedOperation[target as keyof FullOperation] !== null) {
      const updatedOperation = { ...editedOperation } as FullOperation;
      (updatedOperation[target as keyof FullOperation] as FullPromptTemplate).prompt = promptTemplate.prompt;
      (updatedOperation[target as keyof FullOperation] as FullPromptTemplate).name = promptTemplate.name;
      (updatedOperation[target as keyof FullOperation] as FullPromptTemplate).description = promptTemplate.description;
      updateEditedOperation(updatedOperation);
    }
    setCurrentStep('edit');
  };

  const handlePromptTemplateSave = (editedPromptTemplate: FullPromptTemplate) => {
    handleSave(editedPromptTemplate);
  };

  const handleChooseDifferentTemplate = () => {
    setCurrentStep('prompt');
  };

  const handleSave = async (item: Dataset | FullPromptTemplate) => {
    if (editedOperation) {
      const updatedOperation = { ...editedOperation } as FullOperation;
      if (type === 'dataset') {
        if (target.startsWith('params.')) {
          const paramKey = target.replace('params.', '');
          if (!updatedOperation.params) {
            updatedOperation.params = {};
          }
          console.log(updatedOperation);
          updatedOperation.params[paramKey] = item.id + '.' + item.name;
          console.log(updatedOperation);
        } else {
          (updatedOperation[target as keyof FullOperation] as Dataset) = item as Dataset;
        }
      } else if (type === 'prompt_template') {
        const promptTemplate = item as FullPromptTemplate;
        const response = await db.endpoints.testsuite.testsuiteApiUpdatePromptTemplateUpdate(
          promptTemplate.id.toString(),
          {
            name: promptTemplate.name,
            description: promptTemplate.description,
            prompt: promptTemplate.prompt,
            dataset: promptTemplate.dataset?.id,
            type: promptTemplate.type,
            output_type: promptTemplate.output_type,
          } as PromptTemplate
        );
        (updatedOperation[target as keyof FullOperation] as FullPromptTemplate) = response.data as FullPromptTemplate;
      }
      updateEditedOperation(updatedOperation);
      console.log(updatedOperation);
    }
    onClose();
  };

  useEffect(() => {
    setCurrentStep(step);
  }, [step]);

  const handlePromptEditCancel = () => {
    setCurrentStep('prompt');
  };

  return (
    <div className="h-full w-full overflow-hidden">
      <div className="relative p-3 border w-full h-full shadow-xl rounded-md bg-white flex flex-col">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-3">
          {type === 'dataset' ? 'Select Dataset' : `Select Prompt Template - ${currentStep}`}
        </h3>
        <div className="flex-grow overflow-hidden overflow-y-scroll">
          {renderStep()}
        </div>
      </div>
    </div>
  );
};

export default LibraryPanel;
