import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { motion, PanInfo } from 'framer-motion';
import styled from "@emotion/styled";
import { linearTransformation } from '../../utils/dataTransformations';
import { useAnimationControls } from 'framer-motion';
import { DataCardStack } from '../../components/DataCardStack';
import useArrowKeyMovement from '../../hooks/useArrowKeyMovement';
// TODO Steps

// Create data card with information
// Create loading state for data card with place holders
// Create unfocused state for data card

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background: linear-gradient(290deg, #000000 0%, #0F1830 80%);

`;

const DraggableArea = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;

`;

const DataPoint = styled.div`
  width: 350px;
  height: 600px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
`;


const Line = styled.div`
`

const Axis = ({xAxis, yAxis, computedAxis}: {xAxis: string, yAxis: string, computedAxis: string}) => {
    return (
        <div className="top-0 bottom-0 right-0 left-0 absolute">
            <div className="h-full left-2 flex justify-center items-center absolute">
                <div className="text-[#FF914C] origin-left" style={{rotate: "-90deg"}}>{yAxis}</div>
            </div>
            <div className="w-2 rounded-xl h-[90vh] bottom-5 bg-black left-5 absolute"
            style = {{background: "linear-gradient(-180deg, #35164D 0%, #FB7706 80%)"}}></div>

            <div className="w-full bottom-0 flex justify-center items-center absolute"
            >
                <div className="text-[#833039]">{xAxis}</div>
            </div>
            <div className="h-2 rounded-xl w-[85vw] bottom-5 left-5 bg-black absolute"
            style = {{background: "linear-gradient(-90deg, #35164D 0%, #FB7706 80%)"}}></div>


            <div className="w-full bottom-0 top-0 flex justify-center items-center absolute">
                <div className="text-[#833039]"
                style = {{
                    rotate: "-45deg",
                    transformOrigin: "left"
                }}>{computedAxis}</div>
            </div>
            <div className="h-2 rounded-xl w-[110vh] bottom-5 left-5 bg-black absolute"
            style = {{
                background: "linear-gradient(-90deg, #35164D 0%, #FB7706 80%)",
                rotate: "-45deg",
                transformOrigin: "left"
            }}></div>

        </div>
    )
}


interface DataSpaceProps {
    idealXAxisCenter: number;
    idealYAxisCenter: number;
    xAxis: string;
    yAxis: string;
    computedAxis: string;
    dataPoints: any[];
    dimensions: string[]
    database: string
}


const DataSpace: React.FC<DataSpaceProps> = ({database, dimensions, idealXAxisCenter, idealYAxisCenter, xAxis, yAxis, computedAxis, dataPoints}) => {  
  // Find the closest point to the iidealXAxisCenter, idealYAxisCenter and set it as the center
  const center = dataPoints.reduce((acc:any, item:any) => {
    const distance = Math.sqrt(Math.pow(item[xAxis] - idealXAxisCenter, 2) + Math.pow(item[yAxis] - idealYAxisCenter, 2))
    if(distance < acc.distance) {
        acc.distance = distance
        acc.id = item.id
    }
    return acc
  }, {id: 0, distance: 1000000})
  const centerId = center.id

  // Memoize unique axis values
  const uniqueXAxisValues = useMemo(() => {
    return Array.from(new Set(dataPoints.map(item => item[xAxis]))).sort((a, b) => a - b);
  }, [dataPoints, xAxis]);

  const uniqueYAxisValues = useMemo(() => {
    return Array.from(new Set(dataPoints.map(item => item[yAxis]))).sort((a, b) => a - b);
  }, [dataPoints, yAxis]);



  const padding = 20
  const cellWidth = 330;
  const cellHeight = 460;
  const spaceWidth = (cellWidth + padding) * uniqueXAxisValues.length;
  const spaceHeight = (cellHeight + padding) * uniqueYAxisValues.length;

  const halfViewportWidth = window.innerWidth / 2.0;
  const halfViewportHeight = window.innerHeight / 2.0;
  const marginX = spaceWidth - halfViewportWidth
  const marginY = spaceHeight -halfViewportHeight
  console.log( spaceWidth - cellWidth, spaceHeight - cellHeight, "Margin X: ", marginX, "Margin Y: ", marginY)

    
  const data = useMemo(() => {
    const transformedData = dataPoints.map((item) => {
      return {
        ...item,
        x: uniqueXAxisValues.indexOf(item[xAxis]),
        y: uniqueYAxisValues.length - uniqueYAxisValues.indexOf(item[yAxis]),
      };
    });
    return linearTransformation(transformedData, 'x', 'y', spaceWidth - cellWidth, spaceHeight - cellHeight).map(item => ({
      ...item,
      top: item.top,
      left: item.left,
    }));
  }, [dataPoints, uniqueXAxisValues, uniqueYAxisValues, spaceWidth, cellWidth, spaceHeight, cellHeight]);


  const normalizedCenter = data.find((item:any) => item.id === centerId) as any

  const centerX = normalizedCenter?.left || 0;
  const centerY = normalizedCenter?.top || 0;

  // Group data into coordinates (0, 0) => [list of data points]
  const groupedData = useMemo(() => {
    return data.reduce((acc:any, item:any) => {
      const key = `${item.left},${item.top}`;
      const card = {
        data: item,
        xAxis,
        yAxis,
        dimensions,
        database,
        key: item.id, // Ensure a unique key
      };
      if (!acc[key]) {
        acc[key] = { key, items: [], top: item.top, left: item.left };
      }
      acc[key].items.push(card);
      return acc;
    }, {});
  }, [data, xAxis, yAxis, dimensions, database]);  


//   console.log(spaceWidth, "Center X: ", centerX, spaceHeight, "Center Y: ", centerY)

  const [initialDragPosition, setInitialDragPosition] = useState({ x: centerX, y: centerY });
  // replace this with ref
  const [currentDragPosition, setCurrentDragPosition] = useState({ x: centerX, y: centerY });
  // const currentDragPosition = useRef({ x: centerX, y: centerY });  
  const [currentCenter, setCurrentCenter] = useState({ x: centerX, y: centerY });
  const animationControls = useAnimationControls()
  const dataSpace = useRef(null);

  useArrowKeyMovement(
    setCurrentDragPosition,
    animationControls,
    cellWidth,
    cellHeight,
    marginX,
    marginY
  );


  // Make sure to set the centerX and centerY from the normalizedCenter if the center changes
  useEffect(() => {
    setCurrentCenter({ x: centerX, y: centerY });
    setCurrentDragPosition({ x: centerX, y: centerY })
    setInitialDragPosition({ x: centerX, y: centerY });
    // marginLeft: window.innerWidth / 2 - centerX - cellWidth / 2,
    // marginTop: window.innerHeight / 2 - centerY - cellHeight / 2,
    animationControls.start({
        x: window.innerWidth / 2 - centerX - cellWidth / 2,
        y: window.innerHeight / 2 - centerY - cellHeight / 2,
    })
  }, [centerX, centerY]);

  const handleDragStart = (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    setInitialDragPosition({ x: info.point.x, y: info.point.y });
  };

  const handleDrag = useCallback((event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    const rect = document.getElementById("data-space")?.getBoundingClientRect();
    if(rect){
      const centerX = window.innerWidth / 2 - (rect.left + cellWidth/2.0);
      const centerY = window.innerHeight / 2 - (rect.top + cellHeight/2.0);
      setCurrentDragPosition({ x: centerX, y: centerY })
    }

  }, [initialDragPosition, currentCenter, dataSpace]);

  const handleDragEnd = useCallback((event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    const rect = document.getElementById("data-space")?.getBoundingClientRect();
    if(rect){
      const centerX = window.innerWidth / 2 - (rect.left + cellWidth/2.0);
      const centerY = window.innerHeight / 2 - (rect.top + cellHeight/2.0);
      setCurrentDragPosition({ x: centerX, y: centerY })

    }
  }, [initialDragPosition, currentCenter]);


  const calculateScale = (x: number, y: number): number => {
    const size = Math.abs(currentDragPosition.x - x) < cellWidth*.4 && Math.abs(currentDragPosition.y - y) < cellHeight*.4 ? 1 : .5;
    return size;
  };

  

  return (
    <Container>
      <Axis xAxis={xAxis} yAxis={yAxis} computedAxis={computedAxis} />
      <DraggableArea
        drag
        onDragStart={handleDragStart}
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
        id="data-space"
        dragElastic={0}
        dragMomentum={false}
        dragConstraints={{ left: -marginX, top: -marginY, right: halfViewportWidth, bottom: halfViewportHeight}}
        style={{
            height: spaceHeight,
            width: spaceWidth,
            border: "1px solid white"
        }}
        animate={animationControls}
      >
        <DataPoint style={{ top: currentDragPosition.y, left: currentDragPosition.x, width: cellWidth, height: cellHeight }}>
            DataPoint
        </DataPoint >


        {(Object.entries(groupedData) as any).map((entry:any) => {
          const [key, itemGroup] = entry;
          const { top, left } = itemGroup;
          const scale = calculateScale(left, top);
          const visible = Math.abs(currentDragPosition.x - left) < halfViewportWidth*1.5 && Math.abs(currentDragPosition.y - top) < halfViewportHeight*1.5 ? true : false;
          if(!visible) return null;
          return (
            <DataPoint
                key={key}
                style={{
                    top: top,
                    left: left,
                    width: cellWidth,
                    height: cellHeight,
                    backgroundColor: "transparent",
                    transform: `scale(${scale})`,
                }}
            >
                <DataCardStack
                    items={itemGroup.items}
                />
            </DataPoint>
          );
        })}
      </DraggableArea>
    </Container>
  );
};

export default DataSpace;
