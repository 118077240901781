import React, { useState, useEffect, useCallback } from 'react';
import {Link, useParams, useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import debounce from 'lodash.debounce'; // Install lodash.debounce
import { useSaveStatus } from '../context/SaveStatusContext';
import { stringify } from 'querystring';
import _, { set } from 'lodash';
import CopilotInput from '../components/CopilotInput';
import CopilotTextarea from '../components/CopilotTextarea';
import { CopilotContextProvider } from '../context/CopilotContextProvider';
import { CopilotContextItem } from '../components/CopilotContextItem';

// Define the types for the form and fields
interface FormField {
  id: string | 'new';
  name: string;
  description: string;
  allowed_format: string;  // e.g., text, number, email
  mandatory: boolean;
  disabled: boolean;
  form: string;
}

interface Form {
  id: number;
  title: string;
  purpose: string;
  fields: FormField[];
}


const getSuggestion = (input: string) => {
  // Your logic to generate suggestions based on input
  if (input.startsWith('hello')) {
    return 'hello world';
  }
  return input + ' suggestion';
};

const handleShareForm = (formId: number) => {
  // Copy the form URL to the clipboard
  const formUrl = `${window.location.origin}/fill-form/${formId}`;
  navigator.clipboard.writeText(formUrl);
  alert('Form URL copied to clipboard!');
};




const EditForm: React.FC = () => {
  const { formId } = useParams();  // Get formId from the URL
  const navigate = useNavigate();
  const [form, setForm] = useState<Form | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [waitingOnNewField, setWaiting] = useState(false);
  

  useEffect(() => {
    const fetchFormDetails = async () => {
      try {
        const response = await axios.get(`/get-form/${formId}`);
        setForm(response.data);
      } catch (error) {
        setErrorMessage('Error fetching form details.');
        console.log('Error fetching form:', error);
      }
    };
    fetchFormDetails();
  }, [formId]);

  

  const validateField = (field: FormField): boolean => {
    // Validation: Name should not be empty, and allowed_format should be valid
    if (!field.name || field.name.trim() === '') {
      setErrorMessage('Field name cannot be empty');
      return false;
    }
    if (!['text', 'number', 'email'].includes(field.allowed_format)) {
      setErrorMessage('Invalid field type');
      return false;
    }
    setErrorMessage(''); // Clear error message if valid
    return true;
  };

  const { isSaved, setIsSaved } = useSaveStatus();

  const autoSave = useCallback(
    debounce(async (updatedForm: Form) => {
      try {
        if (waitingOnNewField) {
          console.log('Waiting on new field');
          return;
        }
        setIsSaved(false);  // Mark as not saved while saving
        console.log(updatedForm)
        
        const response = await axios.post(`/edit-form/${formId}/`, updatedForm);
        const id_mapping = response.data.id_mapping
        
        const updateFields = (updatedForm : Form) => {
          return updatedForm.fields.map(field => {
            if (`${field.id}`.startsWith('new_') && id_mapping[field.id]) {
              const newId = id_mapping[field.id]['id'];
              const created_at = id_mapping[field.id]['created_at'];
              if (newId) {
                return { ...field, id: newId, created_at: created_at };
              }
            }
            return field;
          });
        }
        console.log("Heredog: ", response)

        setForm(prevForm => {
          const newForm = prevForm ? { ...prevForm, fields: updateFields(prevForm) } : null;

          // console.log('newForm:', newForm);
          // console.log('response.data.form:', response.data.form);
          console.log("Check is Equal: ", response.data.form, newForm)
          if (_.isEqual(response.data.form, newForm)) {
            setIsSaved(true);
          }
          return newForm;
        });
        console.log('Auto-saved successfully:', response.data);
        setTimeout(() => setWaiting(false), 800);
        
        if (errorMessage === 'Error saving form.') {
          setErrorMessage('');
        }

        
      } catch (error) {
        setErrorMessage('Error saving form.');
        console.log('Error auto-saving form:', error);
      }
    }, 500),  // 500ms delay
    [formId, waitingOnNewField]
  );

  const handleFieldChange = (index: number, updatedField: Partial<FormField>) => {
    setIsSaved(false);
    setForm(prevForm => {
      if (!prevForm) return null;
      const updatedFields = prevForm.fields.map((field, idx) =>
        idx === index ? { ...field, ...updatedField } : field
      );

      const updatedForm = { ...prevForm, fields: updatedFields };

      // Validate and Auto-Save if valid
      if (validateField(updatedFields[index])) {
        autoSave(updatedForm);
      }

      return updatedForm;
    });
  };

  const handleAddField = () => {
    setIsSaved(false);
    setForm(prevForm => {
      if (!prevForm) return null;
      const newField: FormField = {
        id: `new_${prevForm.fields.length}`,
        name: '',
        description: '',
        allowed_format: 'text',  // Default format
        mandatory: false,
        disabled: false,
        form: `${formId}`,
      };
      const updatedForm = { ...prevForm, fields: [...prevForm.fields, newField] };
      autoSave(updatedForm);
      setWaiting(true);

      // No validation needed for newly added fields (until they are modified)
      return updatedForm;
    });
  };

  const handleRemoveField = (index: number) => {
    setIsSaved(false);  // Mark as not saved
  
    setForm(prevForm => {
      if (!prevForm) return null;
  
      // Create a new array excluding the field at the specified index
      const updatedFields = prevForm.fields.filter((_, idx) => idx !== index);
  
      // Update the form with the new fields array
      const updatedForm = { ...prevForm, fields: updatedFields };
  
      // Call autoSave to persist the updated form after deletion
      autoSave(updatedForm);
  
      return updatedForm;  // Return the updated form to trigger re-render
    });
  };

  const handleViewResults = (formId: number) => {
    navigate(`/view-results/${formId}`);
  };

  if (!form) return <div className='flex justify-center items-center h-screen w-screen'>
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </div>;

  return (
    <>
    <nav className="m-2 absolute top-16 lg:top-20 left-2 lg:left-4 z-10">
        <ul className="flex space-x-2 text-white">
          <li>
            <button
              onClick={() => navigate('/')}
              className="underline hover:text-gray-300"
            >
              Home
            </button>
          </li>
          <li>/</li>
          <li>  
            <button
              onClick={() => window.location.reload()}
              className="underline hover:text-gray-300"
            >
              {form.title}
            </button>
          </li>
        </ul>
      </nav>

    <div className="h-full bg-gray-800 px-8 py-2 relative overflow-auto flex flex-col items-center">
      
      <div className='p-10'></div>
      <div className="sticky top-2 bg-black z-1 p-1 m-2 shadow-md mx-auto max-w-100 flex align-center justify-around rounded-full">
        <span className="text-sm pt-4 p-2">
          {isSaved ? 'Saved' : 'Saving...'}
        </span>
        {errorMessage && <p className="text-red-500">{errorMessage}</p>}
        <Link
          to={`/fill-form/${formId}?test=true`}
          className="bg-gray-500 text-white py-2 px-4 m-2 rounded-lg hover:bg-gray-600"
        >
          Test
        </Link>
        <button 
            className="bg-gray-500 text-white py-2 px-4 m-2 rounded-lg hover:bg-gray-600"
            onClick={() => handleShareForm(form.id)}
          >
            Share
        </button>
        <button 
          className="bg-gray-500 text-white py-2 px-4 m-2 rounded-lg hover:bg-gray-600"
          onClick={() => handleViewResults(form.id)}
        >
          Results
        </button>
      </div>
      <div className='p-5'></div>
      {/* Form Title and Purpose */}

      <div className="bg-gradient-to-tr from-slate-900 to-slate-800 shadow-[0_20px_50px_-15px_rgba(250,250,250,.3)] rounded-lg p-8 py-14  mb-6 w-full max-w-7xl">
        <input
          type="text"
          className="w-full text-3xl p-2 mb-4 outline-none focus:border-b border-b-white bg-transparent"
          value={form.title}
          placeholder='Form Title'
          onChange={e => {
            const updatedForm = { ...form, title: e.target.value };
            setForm(updatedForm);
            autoSave(updatedForm);
          }}
        />

        <textarea
          className="w-full p-2 mb-4 outline-none rounded bg-gradient-to-tr from-slate-800 to-slate-700 text-white"
          rows={8}
          value={form.purpose}
          placeholder="specify the purpose of your form here, the more info the better!"
          onChange={e => {
            const updatedForm = { ...form, purpose: e.target.value };
            setForm(updatedForm);
            autoSave(updatedForm);
          }}
        />




        {/* Form Fields */}
        <div className="space-y-4">
          {form.fields.map((field, index) => (
            <div key={index} className="bg-gradient-to-tr from-slate-800 to-slate-700 shadow-md rounded-lg p-4 text-xl">
              <div className="flex">
                {/* Field Name */}
                <div className='flex-grow '>
                  <input
                    type="text"
                    className="w-full outline-none focus:border-b border-b-white bg-transparent p-2 mb-4"
                    value={field.name}
                    placeholder="Untitled Field"
                    onChange={e => handleFieldChange(index, { name: e.target.value })}
                  />
                </div>

                {/* Field Type */}
                <div>
                  <select
                    className="outline-none bg-slate-600 p-2 mb-4 rounded text-right"
                    value={field.allowed_format}
                    onChange={e => handleFieldChange(index, { allowed_format: e.target.value })}
                  >
                    <option value="text">Text</option>
                    <option value="number">Number</option>
                    <option value="email">Email</option>
                    {/* Add more types as needed */}
                  </select> 

                {/* Mandatory Checkbox */}
                  <label className="inline-flex items-right">
                    <input
                      type="checkbox"
                      className="form-checkbox h-5 w-6 text-slate-600 ml-10 mt-4 mb-4"
                      checked={field.mandatory}
                      onChange={e => handleFieldChange(index, { mandatory: e.target.checked })}
                    />
                    <span className="mr-10 p-3">required</span>
                  </label>
                  {/* Remove Field Button */}

                  {field.id !== 'new' && (
                    <button
                      className="bg-slate-500 text-white py-2 px-4 rounded hover:bg-slate-600 justify-self-end"
                      onClick={() => handleRemoveField(index)}
                    >
                      Remove Field
                    </button>
                  )}
                </div>
              </div>

              {/* Field Description */}
              <textarea
                className="w-full p-2 mb-4 outline-none rounded bg-gradient-to-tr from-slate-900 to-slate-800 text-white"
                rows={2}
                value={field.description}
                placeholder="describe what this field should capture"
                onChange={e => handleFieldChange(index, { description: e.target.value })}
              />
            </div>
          ))}
        </div>
        {/* Add New Field Button */}
        <button
          className="bg-slate-500 text-white py-2 px-4 rounded-lg mt-4 hover:bg-slate-700"
          onClick={handleAddField}
        >
          + Add New Field
        </button>
      </div>
    </div>
    </>
  );

};

export default EditForm;
