import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useOasisBackend } from '../../../hooks/useOasisBackend';
import { Dataset as DatasetType } from '../../../api/OasisBackendApi';
import Searchbar from '../Searchbar';
import CreateDataset from '../forms/CreateDataset';
import { ContainerLoader } from '../loader';
import DatasetCard from '../Cards/DatasetCard';

interface DatasetLibraryProps {
    onSelect: (dataset: DatasetType) => void;
    onCancel?: () => void;
}

const DatasetLibrary: React.FC<DatasetLibraryProps> = ({ onSelect, onCancel }) => {
  const [datasets, setDatasets] = useState<DatasetType[]>([]);
  const [filteredDatasets, setFilteredDatasets] = useState<DatasetType[]>([]);
  const [isCreatingDataset, setIsCreatingDataset] = useState(false);
  const [createDatasetType, setCreateDatasetType] = useState<'empty' | 'upload' | null>(null);
  const db = useOasisBackend();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchDatasets();
  }, []);

  const fetchDatasets = async () => {
    try {
      const response = await db.endpoints.testsuite.testsuiteApiGetUserDatasetsRetrieve();
      setDatasets(response.data);
      setFilteredDatasets(response.data);
    } catch (error) {
      console.error('Error fetching datasets:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const searchableProps = useMemo(
    () => ['name', 'description', 'data_count', 'is_root', 'data_type', 'data_source', 'created_at'],
    []
  );

  const handleFilterChange = useCallback((filteredItems: DatasetType[]) => {
    setFilteredDatasets(filteredItems);
  }, []);


  const startCreatingDataset = (type: 'empty' | 'upload') => {
    setCreateDatasetType(type);
    setIsCreatingDataset(true);
  };

  const onDatasetCreated = (newDataset: DatasetType) => {
    setDatasets([...datasets, newDataset]);
    setFilteredDatasets([...filteredDatasets, newDataset]);
    onSelect(newDataset);
    setIsCreatingDataset(false);
  };

  if (isCreatingDataset) {
    return (
      <CreateDataset
        empty={createDatasetType === 'empty'}
        onDatasetCreated={onDatasetCreated}
      />
    );
  }

  const handleDatasetCardClick = (datasetId: number) => {
    const dataset = datasets.find(dataset => dataset.id === datasetId);
    if (dataset) {
      onSelect(dataset);
    }
  };

  const handleParentDatasetClick = (datasetId: number) => {
    handleDatasetCardClick(datasetId);
  };


  return (
    <div className="flex flex-col h-full overflow-hidden">
      <div className="mb-4 flex justify-between items-center">
    {isLoading && (
      <button
            onClick={() => startCreatingDataset('empty')} // Toggle modal on click
            className="block p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 flex items-center justify-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-12 w-12 text-gray-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4v16m8-8H4"
              />
            </svg>
        </button>
      )}
        <div>
          {onCancel && (
            <button
              className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
              onClick={onCancel}
            >
              Cancel
            </button>
          )}
        </div>
      </div>
      {isLoading ? (
        <ContainerLoader />
      ) : (
        <div className="flex flex-col h-full overflow-hidden">
          <Searchbar<DatasetType>
            items={datasets}
            searchableProperties={searchableProps}
            onFilterChange={handleFilterChange}
          />
          <div className="flex-grow overflow-y-auto mt-4">
            <div className="grid grid-cols-3 gap-4">
            <button
                onClick={() => startCreatingDataset('empty')} // Toggle modal on click
                className="block p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 flex items-center justify-center"
            >
                <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-12 w-12 text-gray-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
                >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4v16m8-8H4"
                />
                </svg>
            </button>
              {filteredDatasets.map((dataset) => (
                <DatasetCard
                  key={dataset.id}
                  dataset={dataset}
                  onCardClick={handleDatasetCardClick}
                  onParentDatasetClick={handleParentDatasetClick}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DatasetLibrary;
