import React from 'react';
import { Link } from 'react-router-dom';
import APIWaitlistModal from '../../components/APIWaitlistModal';
import ContactUsModal from '../../components/ContactUsModal';

const Navbar = ({apiWaitlistModal}: {apiWaitlistModal: () => void}) => {
    return (<div className="fixed h-[55px] bg-pitchDeckBlack text-pitchDeckWhite px-8 w-full flex flex-row justify-between">
        <div className="text-lg font-bold leading-none py-4 leading-none">
            Thoughtful Oasis
        </div>
        <a href="#about" className="py-4 leading-none m-0 md:mr-[334px]">
            About Us
        </a>
        <button onClick={apiWaitlistModal} className="bg-pitchDeckTeal w-fit top-[55px] right-[0px] md:right-[86px] md:top-[10px] text-white font-bold px-[61.5px] py-4 text-base absolute">
                API waitlist
        </button>
    </div>)
}


const profiles = [
    {
        name: "Kaspar Rothenfusser",
        role: "Product",
        linkedin: "https://www.linkedin.com/in/k-rothenfusser/",
        image: "/kaspar.jpeg"
    },
    {
        name: "Bekk Blando",
        role: "Tech",
        linkedin: "https://www.linkedin.com/in/bekk-blando-a3b97aa9/",
        image: "/bekk.jpeg"
    },
    {
        name: "Rens Dommerholt",
        role: "Company",
        linkedin: "https://www.linkedin.com/in/rensdommerholt/",
        image: "/rens.jpeg"
    }
]

interface ProfileProps {
    name: string;
    role: string;
    linkedin: string;
    image: string;
}

const Profile = ({ name, role, linkedin, image }: ProfileProps) => {
    return (
        <div className="flex flex-col gap-1">
            <div className="max-h-[228px] max-w-[181px] pb-3">
                <img
                    className="object-contain w-full h-full"
                    src={process.env.PUBLIC_URL + image}
                    alt={name}
                />
            </div>
            <div className="lg:h-12 xl:h-6 overflow-hidden min-w-[105px] font-bold text-ellipsis">{name}</div>
            <div>{role}</div>
            <div>
                <a href={linkedin} target="_blank" rel="noopener noreferrer">
                    LinkedIn
                </a>
            </div>
        </div>
    );
};

const PitchDeckStyleLandingPage = () => {
    const [waitListModalIsOpen, setWaitListModalIsOpen] = React.useState(false);
    const [contactUsModalIsOpen, setContactUsModalIsOpen] = React.useState(false);

    const apiWaitlistModal = () => {
        setWaitListModalIsOpen(true);
    }

    const contactUsModal = () => {
        setContactUsModalIsOpen(true);
    }

    return (
        <div className="font-['Open_Sans'] overflow-x-hidden">
            <APIWaitlistModal modalIsOpen={waitListModalIsOpen} setIsOpen={setWaitListModalIsOpen} />
            <ContactUsModal modalIsOpen={contactUsModalIsOpen} setIsOpen={setContactUsModalIsOpen} />
            <Navbar apiWaitlistModal={apiWaitlistModal} />
            <div className="pt-[120px] md:pt-[255px] bg-pitchDeckWhite w-full flex flex-col items-center">

                <div className="text-center w-max-[72.9%] p-4 md:p-0">
                    <div className="text-left text-2xl md:text-5xl xl:text-6xl leading-none font-bold">
                        The new <span className="text-pitchDeckTeal">standard</span>
                    </div>
                    <div className="text-left text-2xl md:text-5xl xl:text-6xl leading-none font-bold">
                        for Retrieval Augmented Generation
                    </div>
                </div>

                <p className="py-[3%] text-[20px] my-[1.5rem] px-8 md:px-[25.9%]">
                    RAG should have been the answer to failing LLMs, but neither vector nor graph deliver. That’s why we are building the new standard for RAG, adding <span className="text-pitchDeckTeal font-bold">contextual reasoning</span> to LLMs
                </p>
                <button onClick={apiWaitlistModal} className="bg-pitchDeckTeal self-center w-fit text-white font-bold px-[4.6875rem] py-[1.5625rem] ">
                    API waitlist
                </button>

                <div className="flex flex-col md:flex-row gap-[60px] py-[86px] justify-center">
                    <div className="bg-pitchDeckGray p-16 w-auto md:w-[42.3%] min-h-[25.875rem] pb-6 md:px-[5.375rem] md:pt-[5.375rem] gap-4 flex flex-col">
                        <div className="text-3xl font-bold">
                            For developers
                        </div>
                        <div className="text-left flex flex-1 flex-col justify-between h-full">
                            <div>
                                <div>The Oasis protocol is a RAG alternative to boost the performance of your LLM agent.</div>
                                <div>It is developed out of a passion for search and a frustration of search by LLMs.</div>
                                We are currently benchmarking Oasis against semanticRAG and GraphRAG.
                            </div>
                            <button onClick={apiWaitlistModal} className="bg-pitchDeckTeal mt-4 sm:mt-0 self-end text-sm leading-none self-center w-fit py-4 px-7 text-white ">
                                API waitlist
                            </button>
                        </div>
                    </div>
                    <div className="bg-pitchDeckGray p-16 w-auto md:w-[42.3%] min-h-[25.875rem] pb-6 md:px-[5.375rem] md:pt-[5.375rem] gap-4 flex flex-col">
                        <div className="text-3xl font-bold">
                            For users
                        </div>
                        <div className="text-left flex flex-1 flex-col justify-between h-full">
                            <div>
                                If you’re working with an internal knowledge base where you can’t find the things you’re looking for, 
                                or <div>being forced to chat with a company chatbot that has no clue for which company it works: then Oasis could the solution.</div>
                                <div>Have a chat with us to discover if we can help.</div>
                            </div>
                            <button onClick={contactUsModal} className="bg-pitchDeckTeal mt-4 sm:mt-0 self-end text-sm leading-none self-center w-fit py-4 px-7 text-white ">
                                Contact Us
                            </button>
                        </div>
                    </div>
                </div>

                <div className="bg-pitchDeckTeal flex flex-col w-full px-8 md:px-[15.25rem] pt-14 pb-8">
                    <div className="text-xl md:text-3xl md:leading-none font-bold pb-12">
                        If everyone is fixing or bypassing the limitations of vector-based and graph RAG, then we believe we should <span className="text-pitchDeckWhite">develop option 3</span>
                    </div>
                    <div className="flex flex-col text-lg leading-none justify-between items-center gap-2 md:flex-row text-pitchDeckWhite">
                        <div>
                            Contextual Reasoning
                        </div>
                        <div>
                            Transparent Indexing
                        </div>
                        <div>
                            Finetune Search
                        </div>

                    </div>
                </div>

                <div className="w-full flex flex-col lg:flex-row pt-11">
                    <div id="about" className="bg-pitchDeckBlack text-xl p-8 lg:pt-[107px] lg:pb-[86px] lg:pl-[114px] lg:pr-[36px] w-full flex flex-col items-start lg:w-[56.8%]">
                        <div className="font-bold text-3xl leading-[42px] text-pitchDeckWhite">
                            About Us
                        </div>
                        <div className="gap-4 flex flex-col text-pitchDeckWhite">
                            <div className="pt-12">Thoughtful Oasis is on a mission to solve search forever</div>

                            <div>We are a remote & international first startup, with shared roots in Amsterdam and Enschede. With a pragmatic and research-based views on problem solving, we’re interested in complex and highly dynamic environments.</div>
                        </div>
                        <button onClick={contactUsModal} className="bg-pitchDeckTeal mt-14 text-[14px] font-bold leading-none place-self-start w-fit py-4 px-12 text-white ">
                                Contact Us
                        </button>
                    </div>
                    <div className="w-full lg:w-[43.2%] flex flex-col items-center md:items-start gap-8 md:flex-row pt-28 justify-around px-4">
                       {profiles.map(profile => <Profile {...profile} />)}
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row text-3xl px-8 md:px-0 md:text-6xl leading-none font-bold py-44 gap-24">
                    <div>Will this work? Maybe</div> <div className="text-pitchDeckDarkTeal">but if...</div>
                </div>

                <div className="flex flex-col pt-20 items-center justify-center pb-12 bg-pitchDeckDarkTeal w-full">
                    <div className="text-2xl px-4 lg:px-0 md:text-[40px] md:leading-none font-bold pb-12 text-pitchDeckWhite">
                        Interested in the new industry standard for RAG?
                    </div>
                    <button onClick={apiWaitlistModal} className="bg-pitchDeckBlack text-base m-auto leading-[22px] self-start w-fit py-4 px-14 text-white">
                        API Waitlist
                    </button>
                </div>
                
                <div className="bg-pitchDeckBlack text-pitchDeckWhite pt-9 px-7 md:pr-9 pb-5 w-full">
                    <div className="flex flex-col md:flex-row justify-between">
                        <div className="text-xl">
                            Thoughtful Oasis
                        </div>
                        <div className="flex flex-col text-sm md:flex-row gap-24 p-0 md:pr-12 pt-9">
                            <div className="flex flex-col gap-6">
                                <div className="text-sm font-bold pb-9">ABOUT US</div>
                                <a href="#about">About</a>
                                <div onClick={apiWaitlistModal}>API waitlist</div>
                                <Link to="privacy-policy">Privacy policy</Link>
                            </div>
                            <div className="flex flex-col  gap-6">
                                <div className="text-sm font-bold pb-9">SLUSH</div>
                                <a href="https://airtable.com/appMtb5TBNZYBoXmo/shrajxHwfGWlErSOY/tblsFWIl5454ohKhG">Dutch Discovery Deck</a>
                                <a href="mailto:rens@thoughtful-oasis.com">Schedule a demo</a>
                                <a href="https://platform.slush.org/slush24/home">Slush matchmaking</a>
                            </div>
                            <div className="flex flex-col  gap-6">
                                <div className="text-sm font-bold pb-9">CONNECT</div>
                                <a href="https://www.linkedin.com/company/oasis-search">LinkedIn</a>
                                <a href="mailto:rens@thoughtful-oasis.com">Email</a>
                            </div>
                        </div>
                    </div>
                    <div className="text-xs w-full pt-[20px] pb-[12px] text-left">
                            © 2024 Thoughtful Oasis -  All rights reserved
                    </div>
                </div>

            </div>
        </div>
        );
}


export default PitchDeckStyleLandingPage;