import React from 'react';

interface SubmitButtonProps {
  isSubmitting: boolean;
  submitText: string;
  loadingText: string;
  className?: string;
  type?: 'submit' | 'button';
  onClick?: () => void;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  isSubmitting,
  submitText,
  loadingText,
  className = "bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline",
  type = 'submit',
  onClick
}) => {
  return (
    <button
      type={type}
      disabled={isSubmitting}
      className={`${className} ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
      onClick={onClick}
    >
      {isSubmitting ? (
        <div className="flex items-center justify-center">
          <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-2"></div>
          {loadingText}
        </div>
      ) : (
        submitText
      )}
    </button>
  );
};

export default SubmitButton;
