import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useOasisBackend } from '../../../hooks/useOasisBackend';
import { PromptTemplate as PromptTemplateType } from '../../../api/OasisBackendApi';
import Searchbar from '../Searchbar';

interface PromptTemplateLibraryProps {
  onSelect: (prompt: PromptTemplateType) => void;
  onCancel?: () => void;
}

const PromptTemplateLibrary: React.FC<PromptTemplateLibraryProps> = ({ onSelect, onCancel }) => {
  const [promptTemplates, setPromptTemplates] = useState<PromptTemplateType[]>([]);
  const [filteredPromptTemplates, setFilteredPromptTemplates] = useState<PromptTemplateType[]>([]);
  const db = useOasisBackend();

  useEffect(() => {
    fetchPromptTemplates();
  }, []);

  const fetchPromptTemplates = async () => {
    try {
      const response = await db.endpoints.testsuite.testsuiteApiGetUserPromptTemplatesRetrieve();
      const templates = response.data as PromptTemplateType[];
      
      // Deduplicate templates based on name, description, and prompt
      const uniqueTemplates = templates.reduce((acc: PromptTemplateType[], current) => {
        const isDuplicate = acc.some(item => 
          item.name === current.name && 
          item.description === current.description && 
          item.prompt === current.prompt
        );
        if (!isDuplicate) {
          acc.push(current);
        }
        return acc;
      }, []);

      setPromptTemplates(uniqueTemplates);
      setFilteredPromptTemplates(uniqueTemplates);
    } catch (error) {
      console.error('Error fetching prompt templates:', error);
    }
  };

  const searchableProps = useMemo(
    () => ['name', 'description'],
    []
  );

  const handleFilterChange = useCallback((filteredItems: PromptTemplateType[]) => {
    setFilteredPromptTemplates(filteredItems);
  }, []);

  return (
    <div className="flex flex-col h-full overflow-hidden">
      <div className="mb-4 flex justify-between items-center">
        {onCancel && (
          <button
            className="bg-gray-300 text-black px-4 py-2 rounded"
            onClick={onCancel}
          >
            Cancel
          </button>
        )}
      </div>
      <div className="flex-grow overflow-hidden">
        <Searchbar<PromptTemplateType>
          items={promptTemplates}
          searchableProperties={searchableProps}
          onFilterChange={handleFilterChange}
        />
        <div className="mt-4 overflow-y-auto h-full">
          <div className="grid grid-cols-3 gap-4">
            <button
              onClick={() => onSelect({} as PromptTemplateType)}
              className="block p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 flex items-center justify-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-12 w-12 text-gray-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4v16m8-8H4"
                />
              </svg>
            </button>
            {filteredPromptTemplates.map((template) => (
              <div
                key={template.id}
                className="p-4 border rounded cursor-pointer bg-white hover:bg-gray-50"
                onClick={() => onSelect(template)}
              >
                <h3 className="font-bold">{template.name}</h3>
                <p className="text-sm">{template.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromptTemplateLibrary;
