import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { FullAnalysis, Dataset as DatasetType } from '../../../api/OasisBackendApi';
import { useOasisBackend } from '../../../hooks/useOasisBackend';
import { debounce } from 'lodash';
import Modal from '../modal';
import DatasetLibrary from '../Libraries/DatasetLibrary';
import AutocompleteDropdown from '../AutocompleteDropdown';
import SubmitButton from '../SubmitButton';
import AnalysisResultsCard from './AnalysisResultsCard';
import { memo } from 'react';

interface AnalysisCardProps {
  analysis: FullAnalysis; 
  blockId: number;
  onAnalysisUpdated?: (analysis: FullAnalysis, blockId: number) => void;
}

// Memoize the AnalysisResultsCard component
const MemoizedAnalysisResultsCard = memo(AnalysisResultsCard);

const AnalysisCard: React.FC<AnalysisCardProps> = memo(({ analysis, blockId, onAnalysisUpdated }) => {
  const db = useOasisBackend();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [localAnalysis, setLocalAnalysis] = useState(analysis);
  const [isDatasetModalOpen, setIsDatasetModalOpen] = useState(false);
  const [currentDatasetIndex, setCurrentDatasetIndex] = useState<number | null>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [currentMultiselectField, setCurrentMultiselectField] = useState('');
  const [filterText, setFilterText] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [isRunning, setIsRunning] = useState(false);

  // Update localAnalysis when props change
  useEffect(() => {
    setLocalAnalysis(analysis);
  }, [analysis.id]); // Only update when analysis ID changes

  const updateAnalysis = useCallback(async (updatedFields: Partial<FullAnalysis>) => {
    setLoading(true);
    setError(null);
    try {
      let fieldsToUpdate: { [key: string]: any } = { ...updatedFields };

      if (updatedFields.input_datasets) {
        fieldsToUpdate.input_datasets = updatedFields.input_datasets.map((dataset: DatasetType) => dataset.id);
      }

      // If updating params, merge with existing params
      if (updatedFields.params) {
        fieldsToUpdate.params = { ...localAnalysis.params, ...updatedFields.params };
      }

      const updatedAnalysis = await db.endpoints.testsuite.testsuiteApiUpdateAnalysisUpdate(
        analysis.id.toString(), 
        fieldsToUpdate
      );
      setLocalAnalysis(updatedAnalysis.data);
      if (onAnalysisUpdated) {
        onAnalysisUpdated(updatedAnalysis.data, blockId);
      }
    } catch (err) {
      setError('Failed to update analysis. Please try again.');
      console.error('Error updating analysis:', err);
    } finally {
      setLoading(false);
    }
  }, [analysis.id, blockId, db.endpoints.testsuite, localAnalysis.params, onAnalysisUpdated]);

  const debouncedUpdateAnalysis = useMemo(
    () => debounce(updateAnalysis, 1000),
    [updateAnalysis]
  );

  // Cleanup debounce on unmount
  useEffect(() => {
    return () => {
      debouncedUpdateAnalysis.cancel();
    };
  }, [debouncedUpdateAnalysis]);

  const handleInputChange = useCallback((field: string, value: any) => {
    setLocalAnalysis(prev => {
      const updatedAnalysis = { ...prev };
      if (field.startsWith('params.')) {
        const paramKey = field.split('.')[1];
        updatedAnalysis.params = { ...updatedAnalysis.params, [paramKey]: value };
      } else {
        (updatedAnalysis as any)[field] = value;
      }
      return updatedAnalysis;
    });

    if (field.startsWith('params.') && Array.isArray(value)) {
      updateAnalysis({ params: { [field.split('.')[1]]: value } });
    } else if (field.startsWith('params.')) {
      const paramKey = field.split('.')[1];
      debouncedUpdateAnalysis({ params: { [paramKey]: value } });
    } else {
      debouncedUpdateAnalysis({ [field]: value });
    }
  }, [debouncedUpdateAnalysis, updateAnalysis]);

  console.log('localAnalysis', localAnalysis);

  const renderConfigInputs = () => {
    if (!localAnalysis.config || !localAnalysis.config.params) return null;

    return Object.entries(localAnalysis.config.params).map(([key, value]) => {
      const [label, inputType] = key.split('__');
      
      const renderInput = () => {
        switch (inputType) {
          case 'number':
            return (
              <input
                id={key}
                type="number"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={localAnalysis.params[label] || ''}
                onChange={(e) => handleInputChange(`params.${label}`, e.target.value)}
              />
            );
          case 'text':
            return (
              <input
                id={key}
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={localAnalysis.params[label] || ''}
                onChange={(e) => handleInputChange(`params.${label}`, e.target.value)}
              />
            );
          case 'textarea':
            return (
              <textarea
                id={key}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={localAnalysis.params[label] || ''}
                onChange={(e) => handleInputChange(`params.${label}`, e.target.value)}
              />
            );
          case 'select':
            return (
              <select
                id={key}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={localAnalysis.params[label] || ''}
                onChange={(e) => handleInputChange(`params.${label}`, e.target.value)}
              >
                <option value="">Select {label}</option>
                {Array.isArray(value) && value.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            );
          case 'multiselect':
            return (
              <div>
                <div className="flex items-center w-full mb-2">
                  <input
                    type='checkbox'
                    className="mr-2"
                    checked={Array.isArray(localAnalysis.params[label]) && localAnalysis.params[label].length === (value as string[]).length}
                    onChange={(e) => {
                      const newValues = e.target.checked ? [...(value as string[])] : [];
                      handleInputChange(`params.${label}`, newValues);
                    }}
                  />
                  <span className="text-sm font-medium">Select All</span>
                </div>
                <div className="flex items-center w-full">
                  <input
                    ref={inputRef}
                    type="text"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={filterText}
                    onChange={(e) => {
                      setFilterText(e.target.value);
                      setShowDropdown(true);
                    }}
                    onFocus={() => {
                      setCurrentMultiselectField(label);
                      setShowDropdown(true);
                      if (inputRef.current) {
                        const rect = inputRef.current.getBoundingClientRect();
                        setDropdownPosition({ top: rect.bottom, left: rect.left });
                      }
                    }}
                    onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
                  />
                </div>
                <div className="flex flex-wrap mt-2">
                  {Array.isArray(localAnalysis.params[label]) && localAnalysis.params[label].map((item: string, index: number) => (
                    <span key={index} className="bg-blue-100 text-blue-800 px-2 py-1 rounded mr-2 mb-2">
                      <button
                        className="mr-2 text-blue-600 hover:text-blue-800"
                        onClick={(e) => {
                          e.preventDefault();
                          const newValues = localAnalysis.params[label].filter((_: string, i: number) => i !== index);
                          handleInputChange(`params.${label}`, newValues);
                        }}
                      >
                        ×
                      </button>
                      {item}
                    </span>
                  ))}
                </div>
              </div>
            );
          default:
            return null;
        }
      };

      return (
        <div key={key} className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={key}>
            {label}
          </label>
          {renderInput()}
        </div>
      );
    });
  };

  const renderInputDatasets = () => {
    if (!localAnalysis.config || !localAnalysis.config.min_datasets || !localAnalysis.config.max_datasets) return null;

    const min = localAnalysis.config.min_datasets;
    const max = localAnalysis.config.max_datasets;
    const currentInputs = localAnalysis.input_datasets || [];

    return (
      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">Input Datasets</h3>
        <p className="text-sm text-gray-600 mb-2">
          Required: {min}, Maximum: {max}
        </p>
        {Array.from({ length: max }).map((_, index) => (
          <div key={index} className="mb-2">
            <button
              className="w-full p-2 border rounded text-left hover:bg-gray-100"
              onClick={() => {
                setCurrentDatasetIndex(index);
                setIsDatasetModalOpen(true);
              }}
            >
              {currentInputs[index]
                ? `Dataset ${index + 1}: ${currentInputs[index].name}`
                : `Dataset ${index + 1}: Not selected`}
            </button>
          </div>
        ))}
      </div>
    );
  };

  const handleDatasetSelect = (dataset: DatasetType) => {
    if (currentDatasetIndex !== null) {
      const newInputDatasets = [...(localAnalysis.input_datasets || [])];
      newInputDatasets[currentDatasetIndex] = dataset;
      handleInputChange('input_datasets', newInputDatasets);
    }
    setIsDatasetModalOpen(false);
  };

  const handleRunAnalysis = async () => {
    setIsRunning(true);
    setError(null);
    try {
      const response = await db.endpoints.testsuite.testsuiteApiRunAnalysisCreate(analysis.id.toString(), {});
      const updatedAnalysis = response.data;
      setLocalAnalysis(updatedAnalysis);
      if (onAnalysisUpdated) {
        onAnalysisUpdated(updatedAnalysis, blockId);
      }
    } catch (err) {
      setError('Failed to run analysis. Please try again.');
      console.error('Error running analysis:', err);
    } finally {
      setIsRunning(false);
    }
  };

  return (
    <div className="analysis-card p-4 border rounded shadow">
      <input
        className="text-2xl font-bold mb-2 w-full"
        value={localAnalysis.name}
        onChange={(e) => handleInputChange('name', e.target.value)}
      />
      <textarea
        className="w-full mb-4 p-2 border rounded"
        value={localAnalysis.description}
        onChange={(e) => handleInputChange('description', e.target.value)}
      />
      
      {renderInputDatasets()}
      {renderConfigInputs()}
      
      <div className="mt-4">
        <SubmitButton
          isSubmitting={isRunning}
          submitText="Run Analysis"
          loadingText="Running..."
          onClick={handleRunAnalysis}
          type="button"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        />
      </div>

      {error && <p className="error-message text-red-500 mt-2">{error}</p>}
      {loading && <p className="text-gray-500 mt-2">Saving changes...</p>}
      {/* Add visualization components based on analysis type */}

      <Modal
        isOpen={isDatasetModalOpen}
        onClose={() => setIsDatasetModalOpen(false)}
        size="large"
      >
        <DatasetLibrary
          onSelect={handleDatasetSelect}
          onCancel={() => setIsDatasetModalOpen(false)}
        />
      </Modal>
      {showDropdown && currentMultiselectField && (
        <AutocompleteDropdown
          options={(localAnalysis.config?.params[`${currentMultiselectField}__multiselect`] || []) as string[]}
          onSelect={(option) => {
            const currentValues = localAnalysis.params[currentMultiselectField] || [];
            if (!currentValues.includes(option)) {
              handleInputChange(`params.${currentMultiselectField}`, [...currentValues, option]);
            }
            setFilterText('');
          }}
          position={dropdownPosition}
          filterText={filterText}
        />
      )}
      <MemoizedAnalysisResultsCard analysis={localAnalysis} />
    </div>
  );
});

export default memo(AnalysisCard);
