// Import the dataset type from the API file
import { Dataset as DatasetType } from '../../../api/OasisBackendApi';
import React, { useState } from 'react';
import OptionsButton from '../OptionsButton';
import Modal from '../modal';
import { ContainerLoader } from '../loader';
import { useOasisBackend } from '../../../hooks/useOasisBackend';
import { FullRequestParams } from '../../../api/OasisBackendApi';
import DuplicateDataset from '../forms/DuplicateDataset';
import { useNavigate } from 'react-router-dom';
// Define the props for the component
interface DatasetLibraryProps {
    dataset: DatasetType;
    onCardClick: (datasetId: number) => void;
    onParentDatasetClick?: (datasetId: number) => void;
    // Callback function to be called when a dataset is duplicated else redirect to the new dataset
    onDatasetDuplicated?: (dataset: DatasetType) => void;
    className?: string;
}

const DatasetCard: React.FC<DatasetLibraryProps> = ({ dataset, onCardClick, onParentDatasetClick, onDatasetDuplicated, className }) => {
    const [isDatumModalOpen, setIsDatumModalOpen] = useState(false);
    const [isDuplicateDatasetModalOpen, setIsDuplicateDatasetModalOpen] = useState(false);
    const [currentDatum, setCurrentDatum] = useState<any>(null);
    const [currentDatumIndex, setCurrentDatumIndex] = useState(0);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const db = useOasisBackend();
    const navigate = useNavigate();

    const buttons = [
        { label: 'preview', onClick: () => { handlePreviewDataset(dataset.id); } },
        { label: 'duplicate', onClick: () => { handleDuplicateDataset(dataset); } }
    ];

    const handlePreviewDataset = async (datasetId: number) => {
        if (Number(dataset.data_count) === 0) {
            alert("This dataset has no data");
            return;
        }
        setIsDatumModalOpen(true);
        await fetchDatum(datasetId, 0);
    };

    const handleHasDuplicatedDataset = async (dataset: DatasetType) => {
        if (onDatasetDuplicated) {
            onDatasetDuplicated(dataset);
        } else {
            navigate(`/testsuite/dataset/${dataset.id}`);
        }
    };

    const handleDuplicateDataset = async (dataset: DatasetType) => {
        setIsDuplicateDatasetModalOpen(true);
    };

    const fetchDatum = async (datasetId: number, index: number) => {
        setCurrentDatum(null);
        try {
            const response = await db.endpoints.testsuite.testsuiteApiGetDatasetDatumRetrieve(datasetId.toString(), {
                path: `/testsuite/api/get-dataset-datum/${datasetId}/`,
            query: { index : index + 1 }
            } as FullRequestParams);
            setCurrentDatum(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleNextDatum = async () => {
        const nextIndex = currentDatumIndex + 1;
        setCurrentDatumIndex(nextIndex);
        await fetchDatum(dataset.id, nextIndex);
    };

    const truncateDescription = (description: string, maxLength: number) => {
        if (description.length <= maxLength) return description;
        return description.slice(0, maxLength) + '...';
    };

    return (
        <>
        <div className={`relative bg-white shadow-md rounded-lg p-6 cursor-pointer hover:shadow-xl transition-shadow duration-200 ${className}`} onClick={(e) => { e.stopPropagation(); onCardClick(dataset.id); }}>

            <h1 className="text-2xl font-bold mb-4">{dataset.name}</h1>
            {dataset.is_root ? (
                <p className="text-sm text-gray-500 rounded-full bg-gray-100 p-2 w-fit">root dataset</p>
            ) : (
                <p className={`text-sm text-gray-500 rounded-full bg-gray-100 p-2 w-fit ${onParentDatasetClick ? "hover:shadow-lg transition-shadow duration-200" : ""}`} onClick={(e) => { e.stopPropagation(); onParentDatasetClick && onParentDatasetClick(Number(dataset.parent_dataset.split('__')[1])); }}>subset of {dataset.parent_dataset.split('__')[0]}</p>
            )}
            <br></br>
            <p>{dataset.data_count} Data Points</p>
            <div className="mb-6 p-4 bg-slate-200 shadow-md rounded-lg">
                <p>
                    <strong>Description:</strong><br></br>
                    {showFullDescription
                        ? dataset.description.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                            </React.Fragment>
                        ))
                        : truncateDescription(dataset.description, 100)}
                    {dataset.description.length > 100 && (
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowFullDescription(!showFullDescription);
                            }}
                            className="ml-2 text-slate-400 hover:text-slate-800 hover:underline hover:cursor-pointer italic"
                        >
                            {showFullDescription ? 'show less' : 'show more'}
                        </button>
                    )}
                </p>
                <br></br>
                <p><strong>Data Type:</strong> {dataset.data_type}</p>
                <br></br>
                <p><strong>Data Source:</strong> {dataset.data_source}</p>
                <br></br>
                <p><strong>Created At:</strong> {new Date(dataset.created_at).toLocaleString()}</p>
            </div>
            <OptionsButton buttons={buttons} className="absolute top-2 right-2"/>
            <Modal isOpen={isDatumModalOpen} onClose={() => setIsDatumModalOpen(false)} size="large">
                {currentDatum ? (
                    <div>
                        <table className="w-full">
                            <tbody>
                                {Object.entries(currentDatum.content).map(([key, value]) => (
                                    <tr key={key}>
                                        <td className="font-semibold">{key}</td>
                                        <td>{String(value)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <button onClick={handleNextDatum} className="mt-4">Next</button>
                    </div>
                ) : (
                    <ContainerLoader />
                )}

            </Modal>
            <Modal isOpen={isDuplicateDatasetModalOpen} onClose={() => setIsDuplicateDatasetModalOpen(false)} size="large">
                
                <DuplicateDataset dataset={dataset} onDatasetDuplicated={handleHasDuplicatedDataset} onCancel={() => setIsDuplicateDatasetModalOpen(false)} />
            </Modal>
        </div>
        </>
    );
};

export default DatasetCard;
