import React, { createContext, useContext, useState, useCallback } from 'react';
import { FullOperation as FullOperationType } from '../../api/OasisBackendApi';

interface EditOperationContextType {
  editedOperation: FullOperationType | null;
  updateEditedOperation: (operation: FullOperationType) => void;
  resetEditedOperation: () => void;
}

const EditOperationContext = createContext<EditOperationContextType | undefined>(undefined);

export const EditOperationProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [editedOperation, setEditedOperation] = useState<FullOperationType | null>(null);

  const updateEditedOperation = useCallback((operation: FullOperationType) => {
    setEditedOperation(operation);
  }, []);

  const resetEditedOperation = useCallback(() => {
    setEditedOperation(null);
  }, []);

  return (
    <EditOperationContext.Provider value={{ editedOperation, updateEditedOperation, resetEditedOperation }}>
      {children}
    </EditOperationContext.Provider>
  );
};

export const useEditOperation = () => {
  const context = useContext(EditOperationContext);
  if (context === undefined) {
    throw new Error('useEditOperation must be used within an EditOperationProvider');
  }
  return context;
};
