import { useOasisBackend } from "../../hooks/useOasisBackend";
import { CardDescription, CardSubTitle, CardTitle } from "../../components/ui/card";
import styled from '@emotion/styled'
import ApplicationNavbar from "../../components/ApplicationNavbar";
import { AppCard } from "../../components/AppCard";
import Modal from 'react-modal';
import { useState } from "react";
import { cn } from "../../utils/cn";
import { IconX } from "@tabler/icons-react";


export const SellingPoints = styled.ul`
    list-style-type: disc;
    display: flex;
    flex-direction: column;
    gap: .5rem;

    a {
        color: #0ea5e9;
    }
`;



const SubscriptionModal = ({ modalIsOpen, setIsOpen }: { modalIsOpen: boolean, setIsOpen: (v: boolean) => void }) => {

    // TODO Make modal common component with x to close
    return (<>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => (setIsOpen(false))}
        style={{
          overlay: {
            background: 'transparent',
            zIndex: 100,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          content: {
            border: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'fit-content',
            height: 'auto',
            inset: 'auto',
            background: 'transparent',
            // Mobile media query
            // @ts-ignore
            '@media (max-width: 768px)': {
              padding: '1rem',
            },
            zIndex: 1000
          }
        }}
      >
        <div className="bg-white h-[81vh] rounded-2xl p-4 md:p-8 shadow-input">
            <div className="w-full flex justify-end"><div className="self-end py-2" onClick={() => setIsOpen(false)}><IconX className="h-6 w-6" /></div></div>
            <SubscriptionInfo />
        </div>
      </Modal>
    </>
    )
}



const SubscriptionInfo = () => {
    const db = useOasisBackend();

    const buy = async (slug: string) => {
        const response = await db.endpoints.stripeCheckout.stripeCheckoutCreate({
            slug: slug
        })
        // Redirect to stripe
        window.location.href = response.data.stripe_checkout_url;
    }

    return (
        <div className="max-w-md w-full mx-auto bg-white">
            <div className="w-full h-[70vh] flex flex-col gap-4">
                <h1 className="text-lg">Subscribe To Feisty Forms</h1>
                <div className="flex flex-col gap-6">
                    <div className="flex flex-col gap-2 rounded px-4 pb-4 md:px-8 md:pb-8 shadow-input bg-white">
                            <CardTitle className="text-md">Pro</CardTitle>
                            <CardSubTitle>50€</CardSubTitle>
                            <SellingPoints>
                                <li>Fully Editable AI Forms</li>
                                <li>10,000 Submissions Across All Forms</li>
                                <li>Prioritized Customer Support</li>
                            </SellingPoints>
                            <button onClick={() => buy("feisty-forms-pro")} className="mt-2 shadow-[0_0_0_3px_#000000_inset px-6 py-2 bg-transparent border border-black text-black rounded-lg font-bold transform hover:-translate-y-1 transition duration-400">
                                Continue with Pro
                            </button>
                        </div>
                        <div className="flex flex-col gap-2 rounded px-4 pb-4 md:px-8 md:pb-8 shadow-input bg-white">
                            <CardTitle className="text-md">Basic</CardTitle>
                            <CardSubTitle>20€</CardSubTitle>
                            <SellingPoints>
                                <li>Fully Editable AI Forms</li>
                                <li>100 Submissions Across All Forms</li>
                            </SellingPoints>
                            <button onClick={() => buy("feisty-forms-basic")} className="mt-2 shadow-[0_0_0_3px_#000000_inset px-6 py-2 bg-transparent border border-black text-black rounded-lg font-bold transform hover:-translate-y-1 transition duration-400">
                                Continue with Basic
                            </button>
                        </div>
                </div>
            </div>
            
        </div>
    );
};

const SubscriptionButton = ({ className }: { className?: string }) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    return (
        <>
            <SubscriptionModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
            <button onClick={() => { setIsOpen(true) }} className={cn("shadow-[0_0_0_3px_#000000_inset] px-6 py-2 bg-transparent border border-black text-black rounded-lg font-bold transform hover:-translate-y-1 transition duration-400", className)}>
                Upgrade
            </button>
        </>
    )
}

export default SubscriptionButton;