// Similar to a jupyter noteboook. show a single document flow with a Title and description (editable)
// Then show blocks of content, which can be text or analysis. text is rendered as markdown using react-markdown
// Analysis is rendered using the AnalysisCard Component
// The page should have a button to add a new block, which brings up a dropdown to select the type of block (text, analysis)
// The page should have buttons to delete, move up/down each block
// The page should have a button to save all changes

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { IconPlus, IconTrash, IconArrowUp, IconArrowDown, IconCheck, IconLoader2, IconPlayerPlay, IconRefresh, IconEye } from "@tabler/icons-react";
import { useOasisBackend } from '../../hooks/useOasisBackend';
import { FullStudy, FullStudyBlock as StudyBlock, BlockTypeEnum, Dataset, ModelEnum} from '../../api/OasisBackendApi';
import AnalysisCard from '../components/Cards/AnalysisCard';
import Modal from '../components/modal';
import { FullPageLoader } from '../components/loader';
import debounce from 'lodash/debounce';
import DatasetLibrary from '../components/Libraries/DatasetLibrary';

const ManageStudy: React.FC = () => {
  const { studyId } = useParams<{ studyId: string }>();
  const [study, setStudy] = useState<FullStudy | null>(null);
  const [blocks, setBlocks] = useState<StudyBlock[]>([]);
  const [isAddingBlock, setIsAddingBlock] = useState(false);
  const db = useOasisBackend();

  const textareaRefs = useRef<{ [key: number]: HTMLTextAreaElement | null }>({});
  const [saveStatus, setSaveStatus] = useState<'saved' | 'saving' | 'error'>('saved');
  const [focusedBlockId, setFocusedBlockId] = useState<number | null>(null);
  const [analysisClasses, setAnalysisClasses] = useState<string[]>([]);

  const [isBenchmarkModalOpen, setIsBenchmarkModalOpen] = useState(false);
  const [selectedBenchmarkType, setSelectedBenchmarkType] = useState<string>('');
  const [selectedBlockForBenchmark, setSelectedBlockForBenchmark] = useState<number | null>(null);

  const [selectingDatasetFor, setSelectingDatasetFor] = useState<'search' | 'config' | null>(null);
  const [selectedDatasets, setSelectedDatasets] = useState<{
    search_dataset?: Dataset;
    config_dataset?: Dataset;
  }>({});

  useEffect(() => {
    const fetchStudy = async () => {
      if (!studyId) return; // Add this line
      try {
        const response = await db.endpoints.testsuite.testsuiteApiGetStudyRetrieve(studyId.toString());
        setStudy(response.data);
        setBlocks(response.data.blocks || []);
      } catch (error) {
        console.error('Error fetching study:', error);
      }
    };

    const fetchAnalysisClasses = async () => {
      try {
        const response = await db.endpoints.testsuite.testsuiteApiGetAnalysisRegistryRetrieve();
        setAnalysisClasses(response.data);
      } catch (error) {
        console.error('Error fetching analysis classes:', error);
      }
    };

    fetchStudy();
    fetchAnalysisClasses();
  }, [db.endpoints.testsuite, studyId]);

  const debouncedSaveStudy = useCallback(
    debounce(async (updatedStudy: FullStudy) => {
      if (!studyId) return;
      setSaveStatus('saving');
      try {
        await db.endpoints.testsuite.testsuiteApiUpdateStudyUpdate(studyId.toString(), {
          name: updatedStudy.name,
          description: updatedStudy.description,
        });
        setSaveStatus('saved');
      } catch (error) {
        console.error('Error saving study changes:', error);
        setSaveStatus('error');
      }
    }, 500),
    [db.endpoints.testsuite, studyId]
  );

  const debouncedSaveBlock = useCallback(
    debounce(async (blockId: number, content: string) => {
      setSaveStatus('saving');
      try {
        await db.endpoints.testsuite.testsuiteApiUpdateStudyBlockUpdate(blockId.toString(), { content });
        setSaveStatus('saved');
      } catch (error) {
        console.error('Error saving block changes:', error);
        setSaveStatus('error');
      }
    }, 500),
    [db.endpoints.testsuite]
  );

  const handleStudyChange = (field: 'name' | 'description', value: string) => {
    if (!study) return;
    const updatedStudy = { ...study, [field]: value };
    setStudy(updatedStudy);
    debouncedSaveStudy(updatedStudy);
  };

  const handleBlockContentChange = (blockId: number, content: string) => {
    setBlocks(blocks.map(block => 
      block.id === blockId ? { ...block, content } : block
    ));
    debouncedSaveBlock(blockId, content);
  };

  const adjustTextareaHeight = useCallback((textarea: HTMLTextAreaElement) => {
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.target instanceof HTMLTextAreaElement) {
          adjustTextareaHeight(entry.target);
        }
      }
    });

    blocks.forEach(block => {
      const textarea = textareaRefs.current[block.id];
      if (textarea) {
        adjustTextareaHeight(textarea);
        resizeObserver.observe(textarea);
      }
    });

    return () => {
      resizeObserver.disconnect();
    };
  }, [blocks, adjustTextareaHeight]);

  const handleTextareaChange = (blockId: number, content: string) => {
    handleBlockContentChange(blockId, content);
    const textarea = textareaRefs.current[blockId];
    if (textarea) {
      adjustTextareaHeight(textarea);
    }
  };

  const handleAddBlock = async (type: BlockTypeEnum) => {
    try {
      const response = await db.endpoints.testsuite.testsuiteApiCreateStudyBlockCreate({
        study: Number(studyId),
        block_type: type,
        order: blocks.length
      });
      setBlocks([...blocks, response.data]);
    } catch (error) {
      console.error('Error adding block:', error);
    }
    setIsAddingBlock(false);
  };

  const handleDeleteBlock = async (blockId: number) => {
    try {
      await db.endpoints.testsuite.testsuiteApiDeleteStudyBlockDestroy(blockId.toString());
      setBlocks(blocks.filter(block => block.id !== blockId));
    } catch (error) {
      console.error('Error deleting block:', error);
    }
  };

  const handleMoveBlock = async (blockId: number, direction: 'up' | 'down') => {
    const currentIndex = blocks.findIndex(block => block.id === blockId);
    if ((direction === 'up' && currentIndex > 0) || (direction === 'down' && currentIndex < blocks.length - 1)) {
      const newBlocks = [...blocks];
      const [movedBlock] = newBlocks.splice(currentIndex, 1);
      newBlocks.splice(direction === 'up' ? currentIndex - 1 : currentIndex + 1, 0, movedBlock);
      setBlocks(newBlocks);
      
      // Update order in the backend
      try {
        await Promise.all(newBlocks.map((block, index) => 
          db.endpoints.testsuite.testsuiteApiUpdateStudyBlockUpdate(block.id.toString(), { order: index })
        ));
      } catch (error) {
        console.error('Error updating block order:', error);
      }
    }
  };

  const handleCreateAnalysis = async (blockId: number, analysisClass: string) => {
    try {
      const response = await db.endpoints.testsuite.testsuiteApiCreateAnalysisCreate({
        name: analysisClass,
        description: '',
        analysis_class: analysisClass,
      });
      const updatedBlockResponse = await db.endpoints.testsuite.testsuiteApiUpdateStudyBlockUpdate(blockId.toString(), { analysis: response.data.id });
      setBlocks(blocks.map(block => 
        block.id === blockId ? updatedBlockResponse.data : block
      ));
    } catch (error) {
      console.error('Error creating analysis:', error);
    }
  };

  const handleCreateBenchmark = async (blockId: number, benchmarkType: string, formData: any) => {
    try {
      const response = await db.endpoints.testsuite.testsuiteApiCreateExperimentFromTemplateCreate(
        benchmarkType,
        formData
      );
      

      // Create analysis for the benchmark
      let analysisResponse;
      if (response.data.optional?.search_results_dataset_id && response.data.optional?.benchmark_dataset_id) {
        // First create the analysis
        analysisResponse = await db.endpoints.testsuite.testsuiteApiCreateAnalysisCreate({
          name: 'OasisSearchBenchmark Analysis',
          description: 'Analysis of search benchmark results',
          analysis_class: 'OasisSearchBenchmark',
        });

        // Then update it with the input datasets
        if (analysisResponse?.data) {
          await db.endpoints.testsuite.testsuiteApiUpdateAnalysisUpdate(
            analysisResponse.data.id.toString(),
            {
              input_datasets: [
                response.data.optional.search_results_dataset_id,
                response.data.optional.benchmark_dataset_id
              ]
            }
          );
        }
      }

      // Update the block with both experiment and analysis
      const updatedBlockResponse = await db.endpoints.testsuite.testsuiteApiUpdateStudyBlockUpdate(
        blockId.toString(), 
        { 
          experiment: (response.data as any).experiment.id,
          analysis: analysisResponse?.data.id
        }
      );
      
      setBlocks(blocks.map(block => 
        block.id === blockId ? updatedBlockResponse.data : block
      ));
    } catch (error) {
      console.error('Error creating benchmark:', error);
    }
    setIsBenchmarkModalOpen(false);
  };

  const handleDatasetSelect = (dataset: Dataset) => {
    if (selectingDatasetFor) {
      setSelectedDatasets(prev => ({
        ...prev,
        [selectingDatasetFor === 'search' ? 'search_dataset' : 'config_dataset']: dataset
      }));
      setSelectingDatasetFor(null);
    }
  };

  const handleAddBenchmark = (blockId: number, benchmarkType: string) => {
    setSelectedBenchmarkType(benchmarkType);
    setSelectedBlockForBenchmark(blockId);
    setSelectingDatasetFor(null);
    setIsBenchmarkModalOpen(true);
  };

  const handleRunExperiment = async (experimentId: number) => {
    try {
      await db.endpoints.testsuite.testsuiteApiRunFullExperimentCreate(experimentId.toString(), {});
      // Refresh the block to show updated status
      const updatedBlocks = await Promise.all(blocks.map(async block => {
        if (block.experiment?.id === experimentId) {
          const response = await db.endpoints.testsuite.testsuiteApiGetStudyBlockRetrieve(block.id.toString());
          return response.data;
        }
        return block;
      }));
      setBlocks(updatedBlocks);
    } catch (error) {
      console.error('Error running experiment:', error);
    }
  };

  const handleRepairExperiment = async (experimentId: number) => {
    try {
      await db.endpoints.testsuite.testsuiteApiTryToRepairExperimentCreate(experimentId.toString(), {});
    } catch (error) {
      console.error('Error repairing experiment:', error);
    }
  };

  if (!study) {
    return <FullPageLoader />;
  }

  return (
    <div className="container mx-auto py-8">
      <div className="flex justify-end mb-4 sticky top-0 z-10">
        <div className="flex items-center bg-white p-2 rounded-lg shadow-md ">
          {saveStatus === 'saving' && (
            <>
              <IconLoader2 className="animate-spin mr-2" size={20} />
              <span>Saving...</span>
            </>
          )}
          {saveStatus === 'saved' && (
            <>
              <IconCheck className="text-green-500 mr-2" size={20} />
              <span>All changes saved</span>
            </>
          )}
          {saveStatus === 'error' && (
            <span className="text-red-500">Error saving changes</span>
          )}
        </div>
        <Link to={`/view-study/${study.id}`} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors">
          <IconEye size={20} className="inline mr-2" />
          View Study
        </Link>
      </div>

      <div className="mb-4">
        <input
          type="text"
          value={study.name}
          onChange={(e) => handleStudyChange('name', e.target.value)}
          className="text-3xl font-bold mb-2 w-full outline-none"
        />
        <textarea
          value={study.description}
          onChange={(e) => handleStudyChange('description', e.target.value)}
          className="focus:shadow italic resize-none text-gray-700 appearance-none rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>

      {blocks.map((block, index) => (
        <div key={block.id} className="mb-4 p-4 border rounded">
          {block.block_type === BlockTypeEnum.TEXT ? (
            <div className="relative">
              {focusedBlockId !== block.id ? (
                <div 
                  onClick={() => setFocusedBlockId(block.id)}
                  className="cursor-text min-h-[50px]"
                >
                  <ReactMarkdown className="prose">{block.content || ''}</ReactMarkdown>
                </div>
              ) : (
                <textarea
                  ref={el => {
                    textareaRefs.current[block.id] = el;
                    if (el) {
                      el.focus();
                      adjustTextareaHeight(el);
                    }
                  }}
                  value={block.content || ''}
                  onChange={(e) => handleTextareaChange(block.id, e.target.value)}
                  onBlur={() => setFocusedBlockId(null)}
                  className="focus:shadow font-mono resize-none text-gray-700 appearance-none rounded w-full py-2 px-3 leading-tight border-none outline-none focus:shadow-outline"
                  style={{
                    minHeight: '100px',
                    overflow: 'hidden',
                  }}
                />
              )}
            </div>
          ) : block.block_type === BlockTypeEnum.ANALYSIS ? (
            block.analysis ? (
              <>
                <AnalysisCard analysis={block.analysis} blockId={block.id} />
              </>
            ) : (
              <div>
                <select
                  onChange={(e) => handleCreateAnalysis(block.id, e.target.value)}
                  className="block w-full p-2 mb-2 bg-white border border-gray-300 rounded"
                >
                  <option value="">Select an analysis type</option>
                  {analysisClasses.map((analysisClass) => (
                    <option key={analysisClass} value={analysisClass}>
                      {analysisClass}
                    </option>
                  ))}
                </select>
              </div>
            )
          ) : block.block_type === BlockTypeEnum.BENCHMARK ? (
            <div className="p-4 bg-gray-100 rounded">
              {block.experiment ? (
                <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <h3 className="text-lg font-semibold">{block.experiment.name}</h3>
                    <div className="flex items-center gap-2">
                      <span className={`px-2 py-1 rounded text-sm ${
                        block.experiment.status === 'COMPLETED' ? 'bg-green-100 text-green-800' :
                        block.experiment.status === 'RUNNING' ? 'bg-blue-100 text-blue-800' :
                        block.experiment.status === 'FAILED' ? 'bg-red-100 text-red-800' :
                        'bg-gray-100 text-gray-800'
                      }`}>
                        {block.experiment.status}
                      </span>
                      {block.experiment.status === 'PENDING' && (
                        <button
                          onClick={() => handleRunExperiment(block.experiment!.id)}
                          className="p-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                          title="Run Experiment"
                        >
                          <IconPlayerPlay size={16} />
                        </button>
                      )}
                      {block.experiment.status === 'FAILED' && (
                        <button
                          onClick={() => handleRepairExperiment(block.experiment!.id)}
                          className="p-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                          title="Repair Experiment"
                        >
                          <IconRefresh size={16}/>
                        </button>
                      )}
                    </div>
                  </div>
                  {block.analysis && <AnalysisCard analysis={block.analysis} blockId={block.id} />}
                </div>
              ) : (
                <div>
                  <select
                    onChange={(e) => {
                      if (e.target.value) {
                        handleAddBenchmark(block.id, e.target.value);
                      }
                    }}
                    className="block w-full p-2 mb-2 bg-white border border-gray-300 rounded"
                  >
                    <option value="">Select a benchmark type</option>
                    <option value="OasisSearchBenchmark">Oasis Search Benchmark</option>
                  </select>
                </div>
              )}
            </div>
          ) : (
            <div className="p-4 bg-gray-100 rounded">
              <p className="text-gray-600">Unsupported block type: {block.block_type}</p>
            </div>
          )}
          <div className="mt-2 flex space-x-2">
            <button onClick={() => handleDeleteBlock(block.id)} className="text-red-500">
              <IconTrash size={20} />
            </button>
            <button onClick={() => handleMoveBlock(block.id, 'up')} disabled={index === 0}>
              <IconArrowUp size={20} />
            </button>
            <button onClick={() => handleMoveBlock(block.id, 'down')} disabled={index === blocks.length - 1}>
              <IconArrowDown size={20} />
            </button>
          </div>
        </div>
      ))}

      <button onClick={() => setIsAddingBlock(true)} className="mt-4 bg-slate-500 text-white px-4 py-2 rounded">
        <IconPlus size={20} className="inline mr-2" />
        Add Block
      </button>

      <Modal isOpen={isAddingBlock} onClose={() => setIsAddingBlock(false)}>
        <div className="p-4">
          <h2 className="text-xl font-bold mb-4">Add New Block</h2>
          <button onClick={() => handleAddBlock(BlockTypeEnum.TEXT)} className="block w-full p-2 mb-2 bg-gray-200 text-gray-700 rounded">
            Add Text Block
          </button>
          <button onClick={() => handleAddBlock(BlockTypeEnum.ANALYSIS)} className="block w-full p-2 mb-2 bg-slate-500 text-gray-100 rounded">
            Add Analysis Block
          </button>
          <button onClick={() => handleAddBlock(BlockTypeEnum.BENCHMARK)} className="block w-full p-2 bg-slate-500 text-gray-100 rounded">
            Add Benchmark Block
          </button>
        </div>
      </Modal>

      <Modal isOpen={isBenchmarkModalOpen} onClose={() => setIsBenchmarkModalOpen(false)}>
        <div className="p-4">
          <h2 className="text-xl font-bold mb-4">Configure {selectedBenchmarkType}</h2>
          {selectedBenchmarkType === 'OasisSearchBenchmark' && (
            selectingDatasetFor ? (
              <DatasetLibrary 
                onSelect={handleDatasetSelect}
                onCancel={() => setSelectingDatasetFor(null)}
              />
            ) : (
              <form onSubmit={(e) => {
                e.preventDefault();
                const formData = new FormData(e.currentTarget);
                handleCreateBenchmark(selectedBlockForBenchmark!, selectedBenchmarkType, {
                  search_dataset: selectedDatasets.search_dataset?.id,
                  config_dataset: selectedDatasets.config_dataset?.id,
                  model: formData.get('model'),
                  synthetic_dataset_size: Number(formData.get('synthetic_dataset_size'))
                });
              }}>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Search Dataset</label>
                    <input
                      type="hidden"
                      name="search_dataset"
                      value={selectedDatasets.search_dataset?.id || ''}
                    />
                    <button
                      type="button"
                      onClick={() => setSelectingDatasetFor('search')}
                      className="mt-1 w-full px-4 py-2 bg-slate-500 text-white rounded flex justify-between items-center"
                    >
                      <span>{selectedDatasets.search_dataset?.name || 'Select Search Dataset'}</span>
                      <span>→</span>
                    </button>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Config Dataset</label>
                    <input
                      type="hidden"
                      name="config_dataset"
                      value={selectedDatasets.config_dataset?.id || ''}
                    />
                    <button
                      type="button"
                      onClick={() => setSelectingDatasetFor('config')}
                      className="mt-1 w-full px-4 py-2 bg-slate-500 text-white rounded flex justify-between items-center"
                    >
                      <span>{selectedDatasets.config_dataset?.name || 'Select Config Dataset'}</span>
                      <span>→</span>
                    </button>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Model</label>
                    <select name="model" required 
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                    >
                      <option value="">Select a model</option>
                      {Object.values(ModelEnum).map((model) => (
                        <option key={model} value={model}>{model}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Synthetic Dataset Size</label>
                    <input type="number" name="synthetic_dataset_size" required 
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm" 
                      defaultValue={10}
                    />
                  </div>
                  <button type="submit" 
                    className="w-full bg-slate-500 text-white px-4 py-2 rounded hover:bg-slate-600 disabled:opacity-50 disabled:cursor-not-allowed"
                    disabled={!selectedDatasets.search_dataset || !selectedDatasets.config_dataset}
                  >
                    Create Benchmark
                  </button>
                </div>
              </form>
            )
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ManageStudy;