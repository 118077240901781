import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useOasisBackend } from '../../hooks/useOasisBackend';
import Modal from '../components/modal';
import BackButton from '../../components/ui/back_button';
import { IconEdit, IconPlus, IconEye, IconDownload, IconSearch } from "@tabler/icons-react";
import { Dataset as DatasetType, FullRequestParams } from '../../api/OasisBackendApi';
import { FullPageLoader, ContainerLoader } from '../components/loader';
import SubmitButton from '../components/SubmitButton';
import Searchbar from '../components/Searchbar';
import DatasetCard from '../components/Cards/DatasetCard';
import PythonSandbox from '../components/PythonSandbox';

interface NewSubsetType {
  dataset_id: number;
  name: string;
  description: string;
  size: number;
  sampling_strategy: string;
  query: string;
}

interface PythonSanboxModalProps {
  datasets: DatasetType[];
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

const PythonSanboxModal = ({datasets, isOpen, setIsOpen}: PythonSanboxModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <PythonSandbox datasets={datasets} />
    </Modal>
  );
}

const DatasetDetail: React.FC = () => {
  const { datasetId } = useParams<{ datasetId: string }>();
  const navigate = useNavigate();
  const db = useOasisBackend();

  const [dataset, setDataset] = useState<DatasetType | null>(null);
  const [subsets, setSubsets] = useState<DatasetType[]>([]);
  const [filteredSubsets, setFilteredSubsets] = useState<DatasetType[]>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isSubsetModalOpen, setIsSubsetModalOpen] = useState(false);
  const [isDatumModalOpen, setIsDatumModalOpen] = useState(false);
  const [pythonSandbox, setPythonSandbox] = useState(false);
  const [currentDatum, setCurrentDatum] = useState<any>(null);
  const [currentDatumIndex, setCurrentDatumIndex] = useState(0);
  const [editedDataset, setEditedDataset] = useState<DatasetType>({} as DatasetType);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [newSubset, setNewSubset] = useState<NewSubsetType>({
    dataset_id: 0,
    name: '',
    description: '',
    size: 0,
    sampling_strategy: 'random',
    query: ''
  });
  const [isCreatingSubset, setIsCreatingSubset] = useState(false);

  const searchableProps = useMemo(
    () => ['name', 'description', 'data_count'],
    []
  );

  const handleFilterChange = useCallback((filteredItems: DatasetType[]) => {
    setFilteredSubsets(filteredItems);
  }, []);

  useEffect(() => {
    if (datasetId) {
      setDataset(null);
      setSubsets([]);
      fetchDataset();
      fetchSubsets();
    }
  }, [datasetId]);

  const fetchDataset = async () => {
    const response = await db.endpoints.testsuite.testsuiteApiGetDatasetRetrieve(datasetId!);
    setDataset(response.data);
    setEditedDataset(response.data);
  };

  const fetchSubsets = async () => {
    const response = await db.endpoints.testsuite.testsuiteApiGetDatasetSubsetsRetrieve(datasetId!);
    setSubsets(response.data);
  };

  const handleEditSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSaving(true);
    await db.endpoints.testsuite.testsuiteApiUpdateDatasetInfoUpdate(datasetId!, editedDataset);
    fetchDataset();
    setIsEditModalOpen(false);
    setIsSaving(false);
  };

  const handleEditChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEditedDataset({ ...editedDataset, [e.target.name]: e.target.value });
  };
    
  const handleViewDatum = async () => {
    setIsDatumModalOpen(true);
    const response = await db.endpoints.testsuite.testsuiteApiGetDatasetDatumRetrieve(datasetId!, {
        path: `/testsuite/api/get-dataset-datum/${datasetId}/`,
        query: {
            index: currentDatumIndex
        }
    } as FullRequestParams);
    setCurrentDatum(response.data);
  };

  const handleNextDatum: () => Promise<void> = async () => {
    setCurrentDatumIndex(prev => prev + 1);
    setCurrentDatum(null);
    const response = await db.endpoints.testsuite.testsuiteApiGetDatasetDatumRetrieve(datasetId!, {
        path: `/testsuite/api/get-dataset-datum/${datasetId}/`,
        query: {
            index: currentDatumIndex + 1
        }
    } as FullRequestParams) 
    setCurrentDatum(response.data);
  };

  const handleSubsetChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setNewSubset({ ...newSubset, [e.target.name]: e.target.value });
  };

  const handleSubsetSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsCreatingSubset(true);
    try {
     const response = await db.endpoints.testsuite.testsuiteApiCreateSubsetCreate(datasetId!, newSubset);
      fetchSubsets();
      setIsSubsetModalOpen(false);
    } catch (error) {
      console.error('Error creating subset:', error);
    } finally {
      setIsCreatingSubset(false);
    }
  };

  const handleDeleteDataset = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Confirm deletion by typing the dataset name
    const datasetName = window.prompt('Enter the dataset name to confirm deletion of the dataset and all of its subsets');
    if (datasetName === dataset?.name) {
      setIsDeleting(true);
      await db.endpoints.testsuite.testsuiteApiDeleteDatasetDestroy(datasetId!);
      navigate('/testsuite/datasets');
    }
  };

  const handleDatasetCardClick = (datasetId: number) => {
    // open edit modal
    setIsEditModalOpen(true);
  };

  const handleParentDatasetClick = (datasetId: number) => {
    navigate(`/dataset/${datasetId}`);
  };

  const handleSubsetCardClick = (datasetId: number) => {
    navigate(`/dataset/${datasetId}`);
  };

  const exportDatum = async () => {
    const response = await db.endpoints.testsuite.testsuiteApiExportDatasetRetrieve(datasetId!);
  
    if (response.data) {
      // Convert the JSON object to a string for Blob compatibility
      const blob = new Blob([JSON.stringify(response.data)], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = url;
      link.download = `${dataset?.name || 'dataset'}.json`;
      link.click();
  
      // Clean up the URL object
      window.URL.revokeObjectURL(url);
    } else {
      console.error("No data returned from API");
    }
  };

  const indexWithWeaviate = async () => {
    const response = await db.endpoints.testsuite.testsuiteApiIndexWithWeaviateCreate({dataset_id: datasetId as any});
    console.log(response);
  };

  

  if (!dataset) return <FullPageLoader />;

  return (
    <div className="p-6">
      <BackButton />
        <PythonSanboxModal datasets={[dataset]} isOpen={pythonSandbox} setIsOpen={setPythonSandbox} />

        <DatasetCard dataset={dataset} onCardClick={handleDatasetCardClick} onParentDatasetClick={handleParentDatasetClick} />

        <div className="flex flex-row justify-start items-center m-4">
        <button onClick={handleViewDatum} className="flex items-center m-8 hover:bg-gray-200 rounded-md p-2">
            <IconEye className="mr-1" /> View Data
        </button>
        <button onClick={exportDatum} className="flex items-center m-8 hover:bg-gray-200 rounded-md p-2">
            <IconDownload className="mr-1" /> Export Data
        </button>
        <button onClick={() => setPythonSandbox(true)} className="flex items-center m-8 hover:bg-gray-200 rounded-md p-2">
            <IconEdit className="mr-1" /> Open Python Sandbox
        </button>
        <button onClick={indexWithWeaviate}  className="flex items-center m-8 hover:bg-gray-200 rounded-md p-2">
            <IconSearch className="mr-1" /> Load Into Weaviate
        </button>
        <form onSubmit={handleDeleteDataset}>
            <SubmitButton isSubmitting={isDeleting} submitText="Delete Dataset" loadingText="Deleting..." />
        </form>
      </div>

      <div className="mb-6 p-4 bg-slate-200 shadow-md rounded-lg">
        <h2 className="text-xl font-semibold mb-2">Subsets</h2>
        <Searchbar<DatasetType>
          items={subsets} // Pass original data here
          searchableProperties={searchableProps}
          onFilterChange={handleFilterChange}
        />


        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <div 
            className="bg-white shadow-md rounded-lg p-4 cursor-pointer flex items-center justify-center hover:shadow-xl transition-shadow duration-300"
            onClick={() => setIsSubsetModalOpen(true)}
          >
            <IconPlus size={24} />
          </div>
          {subsets.map(subset => (
            <DatasetCard dataset={subset} onCardClick={handleSubsetCardClick}/>
          ))}
        </div>
      </div>



      <Modal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
        {/* Add form to edit dataset */}
        <form onSubmit={handleEditSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Dataset Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={editedDataset.name}
              onChange={handleEditChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <textarea
              id="description"
              name="description"
              value={editedDataset.description}
              onChange={handleEditChange}
              rows={3}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            ></textarea>
          </div>
          <div>
            <label htmlFor="data_type" className="block text-sm font-medium text-gray-700">
              Data Type
            </label>
            <input
              type="text"
              id="data_type"
              name="data_type"
              value={editedDataset.data_type}
              onChange={handleEditChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div>
            <label htmlFor="data_source" className="block text-sm font-medium text-gray-700">
              Data Source
            </label>
            <input
              type="text"
              id="data_source"
              name="data_source"
              value={editedDataset.data_source}
              onChange={handleEditChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div>
            <SubmitButton isSubmitting={isSaving} submitText="Save" loadingText="Saving..." />
            <button onClick={() => setIsEditModalOpen(false)} className="ml-2">Cancel</button>
          </div>
        </form>
      </Modal>

      <Modal isOpen={isSubsetModalOpen} onClose={() => setIsSubsetModalOpen(false)}>
        {/* Add subset management UI */}
        <form onSubmit={handleSubsetSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Subset Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={newSubset.name}
              onChange={handleSubsetChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <textarea
              id="description"
              name="description"
              value={newSubset.description}
              onChange={handleSubsetChange}
              rows={3}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            ></textarea>
          </div>
          <div>
            <label htmlFor="size" className="block text-sm font-medium text-gray-700">
              Subset Size
            </label>
            <input
              type="number"
              id="size"
              name="size"
              value={newSubset.size}
              onChange={handleSubsetChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div>
            <label htmlFor="sampling_strategy" className="block text-sm font-medium text-gray-700">
              Sampling Strategy
            </label>
            <select
              id="sampling_strategy"
              name="sampling_strategy"
              value={newSubset.sampling_strategy}
              onChange={handleSubsetChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            >
              <option value="random">Random</option>
              <option value="keyword">Keyword Match</option>
            </select>
          </div>
          <div className={newSubset.sampling_strategy === 'keyword' ? '' : 'hidden'}>
            <label htmlFor="query" className="block text-sm font-medium text-gray-700">
              Query
            </label>
            <input
              type="text"
              id="query"
              name="query"
              value={newSubset.query}
              onChange={handleSubsetChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div>
            <label htmlFor="data_type" className="block text-sm font-medium text-gray-700">
              Data Type
            </label>
            <input
              type="text"
              id="data_type"
              name="data_type"
              value={dataset.data_type}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
              disabled
            />
          </div>
          <div>
            <label htmlFor="data_source" className="block text-sm font-medium text-gray-700">
              Data Source
            </label>
            <input
              type="text"
              id="data_source"
              name="data_source"
              value={dataset.data_source}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
              disabled
            />
          </div>
          <div>
            <SubmitButton isSubmitting={isCreatingSubset} submitText="Create Subset" loadingText="Creating..." />
            <button onClick={() => setIsSubsetModalOpen(false)} className="ml-2">Cancel</button>
          </div>
        </form>
      </Modal>

      <Modal size={"large"}  isOpen={isDatumModalOpen} onClose={() => setIsDatumModalOpen(false)}>
        {currentDatum ? (
           <div>
           <table className="w-full">
               <tbody>
                <pre>
                   {
                    JSON.stringify(currentDatum, null, 4)
                   }
                </pre>
               </tbody>
           </table>
           <button onClick={handleNextDatum} className="mt-4">Next</button>
          </div>
        ) : (
            <ContainerLoader />
        )}  
      </Modal>
    </div>
  );
};

export default DatasetDetail;