import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOasisBackend } from '../../../hooks/useOasisBackend';
import SubmitButton from '../SubmitButton';

const CreateStudy: React.FC = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const db = useOasisBackend();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await db.endpoints.testsuite.testsuiteApiCreateStudyCreate({
        name,
        description,
      });

      // Redirect to manage study page
      navigate(`/manage-study/${response.data.id}`);
    } catch (error) {
      console.error('Error creating study:', error);
      alert('Failed to create study. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="mx-10">
      <h2 className="text-2xl font-bold mb-5">Create New Study</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="name" className="block mb-1">Name</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div>
          <label htmlFor="description" className="block mb-1">Description</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <SubmitButton
          isSubmitting={isSubmitting}
          submitText="Create Study"
          loadingText="Creating..."
        />
      </form>
    </div>
  );
};

export default CreateStudy;

