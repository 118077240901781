import { useLogout } from '../hooks/authentication_helper';
import { cn } from '../utils/cn';



export const LogoutButton = ({className}: {className?: string}) => {
  const logout = useLogout();

  return(
    <button onClick={logout} className={cn("shadow-[0_0_0_3px_#000000_inset] px-6 py-2 bg-transparent border border-black text-black rounded-lg font-bold transform hover:-translate-y-1 transition duration-400", className)}>
        Logout
    </button>

  )
}

export const LogoutItem = () => {
  const logout = useLogout();

  return (
    <div className="cursor-pointer" onClick={logout}>
      Logout
    </div>
  )
}