import React from 'react';
import Plot from 'react-plotly.js';
import { BoxChartProps } from './ChartInterfaces';

const BoxChart: React.FC<BoxChartProps> = ({ 
  data, 
  title = 'Box Plot', 
  xAxisLabel, 
  yAxisLabel,
  boxColor = 'rgba(55, 83, 109, 0.7)',
  showPoints = true
}) => {
  // Create separate traces for each box
  const traces = data.labels.map((label, index) => ({
    type: 'box' as const,
    name: label,
    y: data.values[index],
    boxpoints: showPoints ? 'outliers' as const : false as const,
    marker: { color: boxColor },
  }));

  return (
    <Plot
      data={traces}
      layout={{
        title,
        xaxis: { title: xAxisLabel },
        yaxis: { title: yAxisLabel },
        margin: { t: 50, b: 50, l: 60, r: 10 },
        showlegend: false,
      }}
      config={{ responsive: true }}
      className="w-full h-full"
    />
  );
};

export default BoxChart;